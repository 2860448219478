import React, { useEffect, useState } from "react";
import Cards from "./Cards";
import CardContent from "./CardContent";
import img_1 from "../img/1.jpg";
import img_2 from "../img/2.jpg";
import Loader from "../Loader";
import Pagination from "./Pagination";
import "./pagination.css";
import "./case.css";

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const CaseManagement = () => {
  const [selectedCard, setSelectedCard] = useState(0);
  const [currentIndex, setcurrentIndex] = useState(0);
  const [cases, setCases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 9;
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (searchTerm) {
      searchCases();
    } else {
      fetchCases();
    }
  }, [currentPage, searchTerm]);

  const fetchCases = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${base_url}/user/list_case?page=${currentPage}&limit=${itemsPerPage}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const json = await response.json();
        setCases(json.data || []);
        setTotalPages(Math.ceil(json.totalRecord / itemsPerPage));
      } else {
        setCases([]);
      }
    } catch (error) {
      console.error("Failed to Fetch:", error);
      setCases([]);
    } finally {
      setLoading(false);
    }
  };

  const searchCases = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${base_url}/user/list_case?caseNumber=${encodeURIComponent(
          searchTerm
        )}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const json = await response.json();
        setCases(json.data || []);
        setTotalPages(Math.ceil(json.totalRecord / itemsPerPage));
      } else {
        setCases([]);
      }
    } catch (error) {
      console.error("Failed to Search:", error);
      setCases([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = (index) => {
    setSelectedCard(index);
    setcurrentIndex(index);
    console.log("current card is ", currentIndex, "index ", index);
  };

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  return (
    <div className="container res2">
      <div className="mb-3 w-25">
        <input
          type="text"
          className="form-control"
          placeholder="Search cases by case number..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-md-5 col-sm-8 col-lg-7 col-xl-8 col-xxl-9">
            <div className="row">
              {cases.map((item, index) => (
                <div
                  key={item._id}
                  className="col-xl-4 col-lg-6 col-md-12 col-sm-6 col-xxl-3 mb-3">
                  <Cards
                    currentIndex={currentIndex}
                    index={index}
                    handleCardClick={() => handleCardClick(index)}
                    item={item}
                    imgsrc={index % 2 === 0 ? img_1 : img_2}
                  />
                </div>
              ))}
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              handlePageClick={handlePageClick}
              hasNextPage={currentPage < totalPages}
              hasPrevPage={currentPage > 1}
            />
          </div>
          <div className="col-md-7 col-sm-4 col-lg-5 col-xl-4 col-xxl-3">
            {selectedCard !== null && cases[selectedCard] && (
              <CardContent data={cases[selectedCard]} />
            )}
          </div>
        </div>
      )}
      {cases.length === 0 && !loading && (
        <h2 className="text-white">No Cases Found</h2>
      )}
    </div>
  );
};

export default CaseManagement;
