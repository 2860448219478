import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setFilteredMapData } from '../../store/filteredMapDataSlice';

const FilterMap = () => {
    const dispatch = useDispatch();
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [isLoading, setLoading] = useState(false);
    const mapData = useSelector((state) => state.mapData);

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleSearch = async () => {
        const filteredData = mapData.filter((item) => {
            const itemStartDate = new Date(item.start_date);
            const itemEndDate = new Date(item.end_date);
            const from = new Date(fromDate);
            const to = new Date(toDate);
            return (
                (itemStartDate >= from && itemStartDate <= to) ||
                (itemEndDate >= from && itemEndDate <= to)
            );
        });
        dispatch(setFilteredMapData(filteredData));
    };
    

    const handleReset = () => {
        setFromDate('');
        setToDate('');
        dispatch(setFilteredMapData([])); 
    };

    return (
        <>
            {isLoading && (
                <div className="loading-backdrop" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 9999 }}>
                    <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                    </div>
                    <div className="text-white">loading...</div>
                </div>
            )}
            <div className="d-flex flex-column filter-cdr" style={{ width: 'fit-content', position: 'absolute', top: '0px', right: '0px', zIndex: '99', border: '0', background: 'linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)', borderRadius: '0' }}>
                <div className="d-flex p-2 flex-row pb-1 align-items-center">
                    <label style={{ width: '70px' }} htmlFor="fromDate">From</label>
                    <input
                        className="mx-2"
                        type="date"
                        id="fromDate"
                        name="fromDate"
                        value={fromDate}
                        onChange={handleFromDateChange}
                    />
                    <div>
                        <button className="btn-filter mt-1 px-2 py-1" style={{ width: '70px' }} onClick={handleSearch}>
                            Search
                        </button>
                    </div>
                </div>
                <div className="d-flex p-2 pt-0 flex-row align-items-center">
                    <label style={{ width: '70px' }} htmlFor="toDate">To</label>
                    <input
                        className="mx-2"
                        type="date"
                        id="toDate"
                        name="toDate"
                        value={toDate}
                        onChange={handleToDateChange}
                    />
                    <div>
                        <button className="btn-filter mt-1 px-2 py-1" style={{ width: '70px' }} onClick={handleReset}>
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FilterMap;
