import { createSlice } from '@reduxjs/toolkit';

const durationSlice = createSlice({
    name: 'durationSortedData',
    initialState: null,
    reducers: {
        setdurationSortedData: (state, action) => {
            return action.payload;
        },
    },
});

export const { setdurationSortedData } = durationSlice.actions;

export default durationSlice.reducer;
