import React from "react";
import './Card.css'

const Cards = ({ currentIndex, item, imgsrc, handleCardClick, index }) => {
  if (!item || !item.title || !item.description) {
    return null; // or handle this case accordingly
  }
  const slicedTitle = item.title.slice(0, 15);
  const slicedDescription = item.description.slice(0, 50);
  console.log(item);

  return (
    <>
      <div className={`card case-card ${currentIndex === index ? " border-5 border-primary" : ""} `} onClick={() => handleCardClick(index)}>
        <img className="card-img-top" src={imgsrc} alt="Card" />
        <div className="card-body">
          <span className="card-text case-no text-secondary">
            Case#{item.caseNumber}
          </span>
          <h5 className="card-title">
            {slicedTitle}
            {item.title.length > 15 ? "..." : ""}
          </h5>
          <p className="card-text case-des text-secondary">
            {slicedDescription}
            {item.description.length > 50 ? "..." : ""}
          </p>
        </div>
      </div>
    </>
  );
};

export default Cards;
