import React from "react";
import { useSelector } from "react-redux";
import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";

function VisChart5() {
  const tableData = useSelector((state) => state.data);

  const extractedData = tableData.map((item) => ({
    call_type_in: item.call_type_in,
    call_type_out: item.call_type_out,
  }));

  extractedData.sort((a, b) => b.call_type_in - a.call_type_in);

  const top5Call_type_inData = extractedData.slice(0, 20);

  return (
    <div
      className="text-white align-items-center p-3 px-0"
      style={{
        background:
          "linear-gradient(103.46deg, #080D2F 1.69%, rgba(8, 13, 47, 0.35) 99.32%)",
        borderRadius: "25px",
        textAlign: 'center',
        textTransform: "capitalize",
      }}
    >
      <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={top5Call_type_inData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="call_type_in" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="call_type_out" stroke="#82ca9d" />
      </LineChart>
      </ResponsiveContainer>
      <h2 className="mt-3" style={{ fontSize: '14px' }}>call_type_out vs call_type_in</h2>
    </div>
  );
}

export default VisChart5;
