import { createSlice } from '@reduxjs/toolkit';

const locationDataSlice = createSlice({
    name: 'locationData',
    initialState: null,
    reducers: {
        setLocationData: (state, action) => {
            return action.payload;
        },
    },
});

export const { setLocationData } = locationDataSlice.actions;

export default locationDataSlice.reducer;
