import React, { useEffect } from 'react';

const Pagination = ({ currentPage, handlePageClick, totalPages, hasNextPage, hasPrevPage }) => {
    const maxPageNumbersToShow = 3;
    const pageNumbers = [];

    // Calculate the range of page numbers to show
    let startPage = Math.max(currentPage - Math.floor(maxPageNumbersToShow / 2), 1);
    let endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

    if (endPage - startPage < maxPageNumbersToShow - 1) {
        startPage = Math.max(endPage - maxPageNumbersToShow + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const handleKeyboardNavigation = (e) => {
        if (e.key === 'ArrowLeft' && hasPrevPage) {
            handlePageClick(currentPage - 1);
        } else if (e.key === 'ArrowRight' && hasNextPage) {
            handlePageClick(currentPage + 1);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyboardNavigation);
        return () => {
            document.removeEventListener('keydown', handleKeyboardNavigation);
        };
    }, [currentPage, handlePageClick, hasPrevPage, hasNextPage]);

    return (
        <div className="center mb-2 mt-4" style={{ cursor: 'pointer' }}>
            <div className="pagination">
                <a
                    className={`prev ${!hasPrevPage ? 'disabled' : ''}`}
                    onClick={() => handlePageClick(currentPage - 1)}
                >
                    &laquo;
                </a>
                {pageNumbers.map((pageNumber) => (
                    <a
                        key={pageNumber}
                        className={`page ${currentPage === pageNumber ? 'active' : ''}`}
                        onClick={() => handlePageClick(pageNumber)}
                    >
                        {pageNumber}
                    </a>
                ))}
                <a
                    className={`next ${!hasNextPage ? 'disabled' : ''}`}
                    onClick={() => handlePageClick(currentPage + 1)}
                >
                    &raquo;
                </a>
            </div>
        </div>
    );
};

export default Pagination;
