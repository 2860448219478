import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import icon from './img/profile.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../store/userSlice';
import "../CSS/profileHeader.css";
const Profile = ({pagename}) => {
    const { data: user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUserData());
    }, []);

    return (
        <div className='profile-background' id='profile'>
        <div className="d-flex p-3 px-5 flex-wrap align-items-center profile" >
            <div className='img_icon'>
                <img src={icon} className="img-fluid" alt="Profile" />
            </div>
            <div className='mt-2 me-4'>
                <p className='fw-bold text-1' >{user.firstName} {user.lastName}</p>
                <p className='text-secondary text-2'>{user.email}</p>
            </div>
            {pagename === "Dashboard" ? (
                <div className='mt-3 ms-auto'>
                    <Link to='#' className="btn btn-primary me-3 text-white btnn rounded-3">Overview</Link>
                    {/* <Link to='#' className="btn me-3 text-white btnn rounded-3">Teams</Link>
                    <Link to='#' className="btn text-white btnn rounded-3">Projects</Link> */}
                </div>
            ) : pagename === "Profile" ? (
                <div class="form-check form-switch ms-auto mt-3">
                    <input class="form-check-input" type="checkbox" id="invisible" checked />
                    <label class="form-check-label" htmlFor="invisible">Switch to invisible</label>
                </div>
            ) : null}
            </div>
            </div>
    )
}

export default Profile
