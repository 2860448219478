import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Percentage from "../AnalysisCDR/Percentage";

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

// call based on SMS data

const Table_6 = ({ index }) => {
  const multiCompleteDataRedux = useSelector(
    (state) => state.multiCompleteDataRedux
  );
  const [smsData, setSmsData] = useState(
    multiCompleteDataRedux[index]?.record_based_on_sms
  );

  console.log("Data on Table_6 ", index, smsData);
  const [loading, setLoading] = useState(false);
  const highestSms = Math.max(...smsData.map((item) => item.sms), 0);

  const [selectedSms, setSelectedSms] = useState(null);
  const closeRef = useRef();

  const handleRowClick = async (rowData) => {
    console.log("Clicked row data:", rowData);
    setLoading(true);
    const apiResponse = await fetch(`${base_url}/user/sms_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": sessionStorage.getItem("token"),
      },
      body: JSON.stringify({ sms_name: rowData.b_party_number }),
    });
    setLoading(false);
    console.log(apiResponse);
    if (apiResponse.ok) {
      const smsDetails = await apiResponse.json();
      console.log(smsDetails);
      console.log(smsDetails.data);
      setSelectedSms(smsDetails.data);
    } else {
      console.error("Failed to fetch SMS details");

      setLoading(false);
    }

    smsRef.current.click();
  };
  const smsRef = useRef();
  return (
    <>
      {/* ---------------------------------------------------------Loading--------------------------------------------------------- */}
      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "99999999",
          }}
        >
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">loading...</div>
        </div>
      )}

      {/* -------------------------Modal------------------------- */}

      <button
        type="button"
        ref={smsRef}
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "99999" }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ background: "transparent" }}>
            <div className="modal-body" style={{ background: "transparent" }}>
              <i
                type="button"
                className="fa-regular fa-circle-xmark float-end text-white pt-3 fs-5 me-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
              ></i>
              <div className="CDR-row px-3 pb-4 p-3 pt-5">
                {selectedSms && (
                  <div
                    className="table-responsive"
                    style={{ maxHeight: "400px" }}
                  >
                    <table className="table table-bordered border-secondary align-middle">
                      <thead className="table_cdr sticky-top table-header-sticky">
                        <tr>
                          <th scope="col">S.NO</th>
                          <th scope="col">Title</th>
                          <th scope="col">Snippet</th>
                          <th scope="col">Link</th>
                        </tr>
                      </thead>
                      <tbody
                        className="text-white"
                        style={{ fontSize: "12px", background: "#080D2F" }}
                      >
                        {selectedSms.map((record, index) => (
                          <tr key={index}>
                            <th className="py-3" scope="row">
                              {index + 1}
                            </th>
                            <td className="py-3">{record.title}</td>
                            <td className="py-3">{record.snippet}</td>
                            <td className="py-3">
                              <a
                                href={record.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Click
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------table-------------------- */}

      <div className="row res3 px-3 pb-5 ">
        <div className="CDR-row p-4 ">
          <div className="table-responsive" style={{ maxHeight: "500px" }}>
            <table className="table mt-2 table-bordered border-secondary align-middle">
              <thead className="table_cdr sticky-top table-header-sticky">
                <tr>
                  <th scope="col">S.NO</th>
                  <th scope="col">B-Party No.</th>
                  <th scope="col">Total SMS</th>
                  <th scope="col">First SMS (DD/MM/YYYY)</th>
                  <th scope="col">Last SMS (DD/MM/YYYY)</th>
                  <th scope="col">More Details</th>
                </tr>
              </thead>
              <tbody
                className="text-white"
                style={{ fontSize: "12px", background: "#080D2F" }}
              >
                {smsData.map((item, index) => (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <th className="py-3" scope="row">
                      {index + 1}
                    </th>
                    <td className="py-3">{item.b_party_number}</td>
                    <td>
                      {<Percentage total={item.sms} highest={highestSms} />}
                    </td>
                    <td className="py-3">{item.first_call_date}</td>
                    <td className="py-3">{item.last_call_date}</td>
                    <td
                      className="py-3 table-row-hover table-text-hover"
                      onClick={() => handleRowClick(item)}
                    >
                      Get Details
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table_6;
