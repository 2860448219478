import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import Percentage from "../AnalysisCDR/Percentage";

// record based on no of calls reference from new_Table in single cdr

const Table_3 = ({ index }) => {
  const closeRef = useRef();
  // console.log("selected table 3 index ", index)

  const multiCompleteDataRedux = useSelector(
    (state) => state.multiCompleteDataRedux
  );
  const mobileNumber = multiCompleteDataRedux[index].records[0].target_number;
  // console.log("data to be on table 3 ", multiCompleteDataRedux[index].record_based_on_no_of_call)

  const [callData, setcallData] = useState(
    multiCompleteDataRedux[index].record_based_on_no_of_call
  );
  const [loading, setLoading] = useState(false);
  const highestTotalCall = Math.max(
    ...callData.map((item) => item.total_calls),
    0
  );
  const [targetNumber, settargetNumber] = useState(0);
  const [Search, setSearch] = useState(null);

  const handleClick = (number) => {
    settargetNumber(number);
    closeRef.current.click();
    console.log(number);
    setLoading(true);
    const newArr = multiCompleteDataRedux[index]?.records?.filter(
      (record) => record.calledPhoneNumber === number
    );
    setSearch(newArr);
    setLoading(false);
    console.log("search data ", newArr.length, newArr);
  };

  return (
    <div>
      {/* ---------------------------------------------------------Loading--------------------------------------------------------- */}
      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "99999999",
          }}
        >
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">loading...</div>
        </div>
      )}

      {/* ----------------------------------Modal--------------------------------------- */}
      <button
        type="button"
        ref={closeRef}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#table_3_row"
      ></button>
      <div
        className="modal fade"
        id="table_3_row"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content" style={{ background: "transparent" }}>
            <div
              className="modal-body"
              style={{ background: "transparent", minWidth: "300px" }}
            >
              {Search && (
                <div className="CDR-row px-3 py-4">
                  <i
                    type="button"
                    className="fa-regular fa-circle-xmark float-end text-white  fs-5"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></i>

                  <div
                    className="d-flex flex-row text-white "
                    style={{ fontSize: "12px" }}
                  >
                    <p className="p-1">Phone No. : {` ${mobileNumber} `}</p>
                    <p className="p-1">Target No. : {`${targetNumber}`}</p>
                  </div>

                  <div
                    className="table-responsive"
                    style={{ maxHeight: "400px" }}
                  >
                    <table className="table table-bordered border-secondary align-middle">
                      <thead className="table_cdr sticky-top table-header-sticky">
                        <tr>
                          <th scope="col">S.NO</th>
                          <th scope="col">Date of Call</th>
                          <th scope="col">Time of Call</th>
                          <th scope="col">Duration of Call</th>
                          <th scope="col">Address</th>
                          <th scope="col">IMEI </th>
                          <th scope="col">IMSI </th>
                        </tr>
                      </thead>
                      <tbody
                        className="text-white"
                        style={{ fontSize: "12px", background: "#080D2F" }}
                      >
                        {Search.map((item, index) => (
                          <tr key={index}>
                            <th className="py-3" scope="row">
                              {index + 1}
                            </th>
                            <td className="py-3">
                              {item.callDateTime.split(" ")[0]}
                            </td>
                            <td className="py-3">{item.callTime}</td>
                            <td className="py-3">{item.duration}</td>
                            <td className="py-3" style={{ width: "10%" }}>
                              {item.address || item.towerAddress}
                            </td>
                            <td className="py-3">{item.imei}</td>
                            <td className="py-3">{item.imsi}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="row res3 px-3 pb-5 ">
        <div className="CDR-row p-4">
          <div className="table-responsive" style={{ maxHeight: "700px" }}>
            <table className="table mt-2 table-bordered border-secondary align-middle">
              <thead className="table_cdr sticky-top table-header-sticky">
                <tr>
                  <th scope="col">S.NO</th>
                  <th scope="col">B-Party No.</th>
                  <th scope="col">No. Details</th>
                  <th scope="col">Total Calls</th>
                  <th scope="col">In Calls</th>
                  <th scope="col">Out Calls</th>
                  <th scope="col">Last Call (DD/MM/YYYY)</th>
                  <th scope="col">First Call (DD/MM/YYYY)</th>
                </tr>
              </thead>
              <tbody
                className="text-white"
                style={{ fontSize: "12px", background: "#080D2F" }}
              >
                {callData?.map((item, index) => (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <th className="py-3" scope="row">
                      {index + 1}
                    </th>
                    <td className="py-3">{item.b_party_number}</td>
                    <td
                      className="py-3"
                      onClick={() => {
                        handleClick(item.b_party_number);
                      }}
                    >
                      <b>More Details</b>
                    </td>
                    <td className="">
                      {
                        <Percentage
                          total={item.total_calls}
                          highest={highestTotalCall}
                        />
                      }
                    </td>
                    <td className="py-3">{item.call_type_in}</td>
                    <td className="py-3">{item.call_type_out}</td>
                    <td className="py-3">{item.last_call_date}</td>
                    <td className="py-3">{item.first_call_date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table_3;
