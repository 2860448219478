import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import { passwordUpdateSchema } from "../../schemas";
const base_url =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;



const Password = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState(null)
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const initialValues = {
    current_password: '',
    new_password: '',
    confirm_new_password: '',
    IPAddress: localStorage.getItem('IPAddress')
  }
  const { values, handleChange, handleSubmit, handleBlur ,errors,touched} = useFormik({
    initialValues: initialValues,
    validationSchema: passwordUpdateSchema,
    onSubmit: async (values,action) => {
      setLoading(true);
      try {
        const response = await fetch(
          `${base_url}/user/newinfo`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": sessionStorage.getItem("token"),
            },
            body: JSON.stringify({current_password: values.current_password, new_password: values.new_password}),
          }
        );
        const json = await response.json();
        if (json.status === 200) {
          setLoading(false);
          action.resetForm()
          await Swal.fire({
            title: 'You changed your password successfully, Please Login Again',
            width: 600,
            fontSize: '14px !important',
            padding: '1em',
            color: '#fff',
            background: 'linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 1) 100%)',
            backdrop: `
                linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)`
          });
          sessionStorage.removeItem("token");
          navigate("/signin");
        } else if(json.status === 404 && json.message === "Invalid credential.") {
          setError("Invalid Current Password")
        }
      } catch (error) {
        toast.error('Password not changed,Try Again')
      } finally {
        setLoading(false);
      }
    }
  })
  const handleInputChange = (event) => {
    handleChange(event); 
    setError(null)
  };
  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "current_password":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new_password":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm_new_password":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };


  return (
    <>
      <div
        className="row mt-3"
        id="password"
        style={{
          background: 'linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)'
          , borderRadius: "25px"
        }}>
        <form onSubmit={handleSubmit} className="row g-3 p-4 pb-0 mt-3 profileForm">
          <div className="col-md-12 mb-2">
            <label htmlFor="current_password" className="form-label mb-0">
              Current Password
            </label>
            <div className="d-flex flex-row align-items-center">
              <input
                type={showCurrentPassword ? "text" : "password"}
                id="current_password"
                name="current_password"
                placeholder="Current Password"
                value={values.current_password}
                onChange={handleInputChange}
                onBlur={handleBlur}
                autoComplete="false"
              /> <i
                className={`eye-icon text-secondary mt-1  ${showCurrentPassword ? "fas fa-eye" : "fas fa-eye-slash"
                  }`}
                onClick={() => togglePasswordVisibility("current_password")}
                style={{ marginLeft: '-20px', fontSize: '12px' }}
              ></i>        
            </div>
            <span className="text-danger" style={{fontSize: '11px', fontWeight: '900' }}>{error && !errors.current_password ? error : null}</span>
            <span className="text-danger" style={{fontSize: '11px', fontWeight: '900' }}>{errors.current_password && touched.current_password ? errors.current_password : null}</span>
          </div>
          <div className="col-md-12 mb-2 ">
            <label htmlFor="new_password" className="form-label mb-0">
              New Password
            </label>
            <div className="d-flex flex-row align-items-center">
              <input
                type={showNewPassword ? "text" : "password"}
                id="new_password"
                name="new_password"
                placeholder="New Password"
                value={values.new_password}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="false"
              /><i
                className={`eye-icon text-secondary mt-1  ${showNewPassword ? "fas fa-eye" : "fas fa-eye-slash"
                  }`}
                onClick={() => togglePasswordVisibility("new_password")}
                style={{ marginLeft: '-20px', fontSize: '12px' }}
              ></i>
            </div>
            <span className="text-danger" style={{ fontSize: '11px', fontWeight: '900' }}>{errors.new_password && touched.new_password ? errors.new_password : null}</span>
          </div>
          <div className="col-md-12 mb-3">
            <label htmlFor="confirm_new_password" className="form-label mb-0">
              Confirm New Password
            </label>
            <div className="d-flex flex-row align-items-center">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirm_new_password"
                placeholder="Confirm New Password"
                value={values.confirm_new_password}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="false"
              />
              <i
                className={`eye-icon text-secondary mt-1  ${showConfirmPassword ? "fas fa-eye" : "fas fa-eye-slash"
                  }`}
                onClick={() => togglePasswordVisibility("confirm_new_password")}
                style={{ marginLeft: '-20px', fontSize: '12px' }}
              ></i>
            </div>
            <span className="text-danger" style={{fontSize: '11px', fontWeight: '900' }}>{errors.confirm_new_password && touched.confirm_new_password ? errors.confirm_new_password : null}</span>
          </div>
          <div className="d-flex flex-wrap">
            <div className="me-auto">
              <div className="p-0 pb-0 pt-0 fw-normal text-white">
                Password Requirement
              </div>
              <div
                className="p-0 pt-3  pb-0"
                style={{ fontSize: "11px", color: "#8C8C8C" }}>
                Please follow this guide for a strong password
              </div>
              <div className="p-0 pt-0 fw-normal text-white">
                <ul style={{ fontSize: "11px", color: "#8C8C8C" }}>
                  <li className="mt-2">One special character</li>
                  <li className="mt-2">Minimum 6 characters</li>
                  <li className="mt-2">
                    At least one number (2 are recommended)
                  </li>
                  <li className="mt-2">Change it often</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="text-end pb-3">
            {loading ? (
              <div
                className="btn btn-primary float-end rounded-3"
                style={{ maxWidth: "fit-content", fontSize: "13px" }}
                type="submit"
                disabled>
                <span
                  className="spinner-grow me-2 spinner-grow-sm"
                  role="status"
                  aria-hidden="true"></span>
                Updating...
              </div>
            ) : (
              <div
                type="submit"
                onClick={handleSubmit}
                style={{ maxWidth: "fit-content", fontSize: "13px" }}
                className="btn btn-primary mt-1 float-end ms-auto rounded-3">
                Update Password
              </div>
            )}
          </div>
        </form>

      </div>
    </>
  );
};

export default Password;
