import React from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const Welcome = () => {
    const { data: user } = useSelector((state) => state.user);
    return (
            <div className="d-flex flex-column mb-3 text-white welcome">
                <div className="p-3 pb-0 fw-bold fs-5">Welcome Back !</div>
                <div className="p-3 pt-1" style={{ fontSize: '13px' }}>Nice to see you, <span className='fw-bold'>{user.firstName}</span><span className='ms-1 fw-bold'>{user.lastName}!</span></div>
                <Link className="mt-auto p-3 pb-4 text-white" style={{textDecoration: 'none'}} to='/CaseManagemet/cases'>Tap to record<i className="fa-solid fa-arrow-right ms-2"></i></Link>
            </div>
    )
}

export default Welcome
