import React from "react";
// import ReactApexChart from "react-apexcharts";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { useSelector } from "react-redux";

function VisChart1() {
  // Sample data for the bar chart

  const tableData = useSelector((state) => state.data);
  console.log("TableData");
  // console.log(tableData);

  const extractedData = tableData.map((item) => ({
    b_party_number: item.b_party_number,
    total_calls: item.total_calls,
  }));

  extractedData.sort((a, b) => b.total_calls - a.total_calls);

  const topFiveTotalCalls = extractedData.slice(0, 5);

  // console.log(topFiveTotalCalls);

  return (
    <div
      className="text-white align-items-center p-3 px-0"
      style={{
        background:
          "linear-gradient(103.46deg, #080D2F 1.69%, rgba(8, 13, 47, 0.35) 99.32%)",
        borderRadius: "25px",
        textTransform: "capitalize",
        textAlign: "center",
      }}>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={topFiveTotalCalls}>
          <XAxis dataKey="b_party_number" />
          <YAxis />
          <Tooltip></Tooltip>
          <Bar dataKey="total_calls" fill="red" barSize={30} />
        </BarChart>
      </ResponsiveContainer>
      <h2 className="mt-3" style={{ fontSize: "14px" }}>Total Calls vs B-Party Number</h2>
    </div>
  );
}

export default VisChart1;
