export const media = [
    { type: 'image', url: require('./img1.png') },
    { type: 'image', url: require('./img2.png') },
    { type: 'image', url: require('./img3.png') },
    { type: 'image', url: require('./img4.png') },
    { type: 'image', url: require('./img5.png') },
    { type: 'image', url: require('./img6.png') },
    { type: 'image', url: require('./img7.png') },
    { type: 'image', url: require('./img8.png') },
    { type: 'image', url: require('./img9.png') },
    { type: 'image', url: require('./img10.png') },
    { type: 'image', url: require('./img11.png') },
    { type: 'image', url: require('./img12.png') },
    { type: 'image', url: require('./img13.png') },
    { type: 'image', url: require('./img14.png') },
    { type: 'image', url: require('./img15.png') },
    { type: 'image', url: require('./img16.png') },
    { type: 'image', url: require('./img17.png') },
    { type: 'image', url: require('./img18.png') },
    { type: 'image', url: require('./img19.png') },
    { type: 'image', url: require('./img20.png') },


]