import React, { useState, useEffect } from "react";
import ImageSearchResult from "./ImageSearchResult";
import "./ImageUrlSearch.css"; // Make sure to create a corresponding CSS file
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const ImageUrlSearch = () => {

  const [imageUrl, setImageUrl] = useState("");
  const [imageSearchData, setImageSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const imageUri = location?.state?.imageUrl;
    setImageUrl(imageUri)
  }, []);

  const searchImageByUrl = async () => {
    if (!imageUrl) {
      toast.error("Please enter an image URL first!");
      return;
    }

    setLoading(true);
    setSearchPerformed(true);

    try {
      const response = await fetch(`${base_url}/user/image/url_search`, {
        method: "POST",
        headers: {
          "x-auth-token": sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ imageUri: imageUrl }),
      });

      if (response.ok) {
        const data = await response.json();
        setImageSearchData(data?.data?.data?.data || []);
        toast.success('successfully fetched results')
      } else {
        toast.error('failed to fetch results')
        console.error("Search failed:", response.statusText);
      }
    } catch (error) {

      toast.error('failed to fetch results')
      console.error("Error during search:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetSearch = () => {
    setImageUrl("");
    setImageSearchData([]);
    setLoading(false);
    setSearchPerformed(false);
  };

  return (
    <>
      <ToastContainer />

      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: '99999999',
          }}>
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">loading...</div>
        </div>
      )}

      <div className="res3 row p-2 justify-content-center text-white">
        <div className="mb-0 mx-auto">
          <div className="mx-auto col-xl-6 mb-3">
            <div
              className="col-lg-8 col-md-12 col-sm-12 mx-auto col-xl-8"
              style={{
                border: "1px solid white",
                borderRadius: "20px",
                backgroundColor: "#0F1535",
              }}>
              <div className="search-header text-white text-center py-2 mt-3">
                <div className="input-group mb-3 align-items-center d-flex flex-column">
                  <h2 className="fw-bold">Image URL Search</h2>
                  {!imageUrl && <input
                    type="text"
                    className="form-control"
                    placeholder="Enter image URL here"
                    value={imageUrl}
                    onChange={(e) => setImageUrl(e.target.value)}
                    style={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      maxWidth: "80%",
                      borderRadius: "5px",
                      border: "2px solid #ddd",
                      padding: "10px",
                      width: "90%",
                    }}
                  />}
                  {imageUrl && <img src={imageUrl} style={{ width: '60%' }} />}
                  <button
                    onClick={searchImageByUrl}
                    className="btn btn-primary"
                    style={{
                      maxWidth: "200px",
                      borderRadius: "15px",
                      fontSize: "13px",
                    }}>
                    Search
                  </button>
                  {imageUrl && (
                    <button
                      onClick={resetSearch}
                      className="btn btn-primary"
                      style={{
                        maxWidth: "200px",
                        borderRadius: "15px",
                        fontSize: "13px",
                      }}>
                      Reset
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap">
            {searchPerformed &&
              !loading &&
              (imageSearchData.length > 0
                ? imageSearchData.map((item, index) => (
                  <div class=" col-lg-5 mx-auto"
                  >
                    <ImageSearchResult data={item} key={index} />
                  </div>
                ))
                : searchPerformed &&
                !loading && (
                  <div
                    className="text-center text-danger mt-3 mx-auto "
                    style={{ fontSize: "35px", fontWeight: 'bold' }}>
                    <p>No results have been found.</p>
                  </div>
                ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageUrlSearch;
