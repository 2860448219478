import React, { useEffect } from "react";
import ProfileUpdate from "./ProfileUpdate";
import Password from "./Password";
import TFA from "./TFA";
import Delete from "./Delete";
import Sessions from "./Sessions";
import ProfileSidebar from "./ProfileSidebar";
import "./profile.css";
import ApiDetails from "./ApiDetails";

function Profile()
{
  return (
    <div className="container mt-1 res">
      <div className="row px-2">
        <div className="col-md-4 col-sm-4 col-lg-3 col-xl-3">
          <ProfileSidebar />
        </div>
        <div className="col-md-8 col-sm-8 col-lg-9 col-xl-9">
          <ApiDetails />
          <ProfileUpdate />
          <Password />
          <TFA />
          {/* <Sessions /> */}
          <Delete />
        </div>
      </div>
    </div>
  );
}

export default Profile;
