import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "../../store/userSlice";
import toast from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { profileUpdateSchema } from "../../schemas";
import { RiRefreshLine } from "react-icons/ri";
const base_url =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;


const ProfileUpdate = () =>
{
  const { data: user } = useSelector((state) => state.user);
  console.log("Profile update-----", user);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [otp, setOtp] = useState("");
  const [newNumber, setnewNumber] = useState();
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () =>
  {

    setShowModal(true);
  };

  const handleCloseModal = () =>
  {
    setShowModal(false);
  };

  const handleSubmitOTP = async (event) =>
  {
    event.preventDefault();
    console.log(otp)
    console.log("new ", values.phone)
    console.log("user ", user.phone)


    const response = await fetch(`${base_url}/user/verify_otp`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        "x-auth-token": sessionStorage.getItem("token"),
      },
      body: JSON.stringify({
        otp: otp,
        phone: newNumber
      })
    });

    console.log(user.phone)
    console.log(values.phone)
    const data = await response.json()
    console.log(data)
    console.log("otp response ", data.success)
    if (data.success)
    {
      handleCloseModal();
      dispatch(fetchUserData());
      toast.success("Number Updated Successfully");

    } else
    {
      toast.error("Invalid OTP Try again ");
    }
  };

  const handleResendOTP = async (e) =>
  {
    e.preventDefault()
    console.log("resend otp")
    setOtp("")
    const response = await fetch(`${base_url}/user/resend_otp`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        "x-auth-token": sessionStorage.getItem("token"),
      },
      body: JSON.stringify({
        phone: newNumber
      })
    });
    const data = await response.json()
    console.log("resend otp ", data)
    setTimeLeft(60); // Reset the timer
    setTrigger(prev => prev + 1);
    return;
  }

  const [timeLeft, setTimeLeft] = useState(60);
  const [trigger, setTrigger] = useState(0); // Trigger state
  useEffect(() =>
  {
    // Exit early when we reach 0
    if (!timeLeft) return;

    // Save intervalId to clear the interval when the
    // component unmounts
    const intervalId = setInterval(() =>
    {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft, trigger]);

  let initialValues = {
    firstName: user.firstName || "",
    lastName: user.lastName || "",
    bio: user.bio || "",
    // username: user.username || "",
    role: user.role || "",
    phone: user.phone || "",
    gender: user.gender || "",
    IPAddress: localStorage.getItem('IPAddress')
  };

  useEffect(() =>
  {
    dispatch(fetchUserData());
  }, [dispatch]);

  useEffect(() =>
  {
    if (user)
    {
      setValues({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        bio: user.bio || '',
        // username: user.username || '',
        role: user.role || '',
        phone: user.phone || '',
        gender: user.gender || ''
      });
    }
  }, [user]);

  const { values, handleBlur, handleChange, handleSubmit, errors, touched, setValues } = useFormik({
    initialValues: initialValues,
    validationSchema: profileUpdateSchema,
    onSubmit: async (values) =>
    {
      try
      {
        setnewNumber(values.phone)
        console.log("updated values", values.phone)
        setOtp("")
        const response = await fetch(
          `${base_url}/user/info`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": sessionStorage.getItem("token"),
            },
            body: JSON.stringify(values),
          }
        );
        const json = await response.json();
        console.log("json data", json)
        if (json.status === 200)
        {
          if (json.otp_page === true)
          {
            handleOpenModal()
            setTimeLeft(60);
          }

          if (json.otp_page !== true)
          {
            // dispatch(fetchUserData());
            toast.success("Profile Updated Successfully");
          }

          setIsEditClicked(false);
        } else
        {
          toast.error("Try Again");
        }
      } catch (error)
      {
        console.error("Error updating user data:", error);
      } finally
      {
        setIsLoading(false);
      }
    },
  });

  useEffect(() =>
  {
    dispatch(fetchUserData());
    setValues({
      firstName: user.firstName || "",
      lastName: user.lastName || "",
      bio: user.bio || "",
      username: user.username || "",
      role: user.role || "",
      phone: user.phone || "",
      gender: user.gender || "",
    });
  }, []);

  const editIconClick = () =>
  {
    setIsEditClicked(true);
  };


  return (
    <>
      <div
        className="row mt-3"
        id="profileUpdate"
        style={{
          background:
            "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)",
          borderRadius: "25px",
        }}>
        <div className="pt-4 pe-4  text-white">
          <i
            className="fa-solid fa-pen-to-square float-end"
            onClick={editIconClick}></i>
        </div>
        <form className="row g-3 p-4 pt-0 profileForm" onSubmit={handleSubmit}>
          <div className="col-md-6 col-sm-6">
            <label htmlFor="firstName" className="form-label">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="First Name"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEditClicked}
            />
            <span
              className="text-danger"
              style={{ fontSize: "12px", fontWeight: "900" }}>
              {errors.firstName && touched.firstName ? errors.firstName : null}
            </span>
          </div>
          <div className="col-md-6 col-sm-6">
            <label htmlFor="lastName" className="form-label">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Last Name"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEditClicked}
            />
            <span
              className="text-danger"
              style={{ fontSize: "12px", fontWeight: "900" }}>
              {errors.lastName && touched.lastName ? errors.lastName : null}
            </span>
          </div>
          <div className="col-sm-12">

          </div>
          <div className="col-sm-6">
            <label htmlFor="gender">Gender</label>
            <select
              className="select-form text-secondary mt-2"
              name="gender"
              value={values.gender}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEditClicked}>
              <option value="NOT_GIVEN">--select gender--</option>
              <option value="MALE">Male</option>
              <option value="FEMALE">Female</option>
            </select>
            <span
              className="text-danger"
              style={{ fontSize: "12px", fontWeight: "900" }}>
              {errors.gender && touched.gender ? errors.gender : null}
            </span>
          </div>
          <div className="col-sm-6">
            <label htmlFor="role">Role</label>
            <select
              className="select-form text-secondary mt-2"
              name="role"
              value={values.role}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEditClicked}>
              <option value="">--select role--</option>
              <option value="IO">IO</option>
            </select>
            <span
              className="text-danger"
              style={{ fontSize: "12px", fontWeight: "900" }}>
              {errors.role && touched.role ? errors.role : null}
            </span>
          </div>
          {/* <div className="col-sm-6">
            <label htmlFor="username" className="form-label">
              Username
            </label>
            <input
              type="text"
              name="username"
              id="username"
              placeholder="Enter Username"
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEditClicked}
            />
            <span
              className="text-danger"
              style={{ fontSize: "12px", fontWeight: "900" }}>
              {errors.username && touched.username ? errors.username : null}
            </span>
          </div> */}
          <div className="col-sm-6 position-relative">
            <label htmlFor="phone" className="form-label">
              Phone
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="Phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEditClicked}
            />
            <span
              className="text-danger position-absolute"
              style={{
                right: "5px",
                top: "102%",
                fontSize: "12px",
                fontWeight: "900",
              }}>
              {errors.phone && touched.phone ? errors.phone : null}
            </span>
          </div>
          <div className="col-sm-6">
            <label htmlFor="bio" className="form-label">
              Bio
            </label>
            <input
              type="text"
              name="bio"
              id="bio"
              placeholder="Your Bio"
              value={values.bio}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={!isEditClicked}
            />
            <span
              className="text-danger"
              style={{ fontSize: "12px", fontWeight: "900" }}>
              {errors.bio && touched.bio ? errors.bio : null}
            </span>
          </div>
          <div className="col-sm-12"></div>
          <div className="text-end pb-0">
            {isLoading ? (
              <button
                className="btn btn-primary float-end mt-2 rounded-3"
                type="submit"
                style={{ maxWidth: "fit-content", fontSize: "13px" }}
                disabled>
                <span
                  className="spinner-grow me-2 spinner-grow-sm"
                  role="status"
                  aria-hidden="true"></span>
                Updating...
              </button>
            ) : (
              isEditClicked && (
                <button
                  style={{ maxWidth: "fit-content", fontSize: "13px" }}
                  className="btn btn-primary mt-2 float-end ms-auto rounded-3"
                  type="submit"

                >
                  Update Profile
                </button>
              )
            )}


          </div>
        </form>

        {/* ------------------------otp modal------------------------ */}
        <div className={`modal  ${showModal ? 'show' : ''} `} tabIndex="-1" role="dialog" style={{ zIndex: '9999', display: showModal ? 'block' : 'none', marginTop: '15rem' }}>
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header d-flex flex-row " style={{ backgroundImage: 'linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 1) 100%) ' }}>
                <h5 className="modal-title text-white">OTP Verification</h5>
                <button type="btn btn -primary" className="close " data-dismiss="modal" aria-label="Close" onClick={handleCloseModal}
                  style={{ width: '2rem', height: '2rem', marginBottom: '1rem', fontSize: '20px', paddingBottom: '2.25rem' }}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmitOTP}>
                  <div className="form-group">
                    <label htmlFor="otpInput">Enter OTP</label>
                    <div class="d-flex flex-row">


                      <input
                        type="text"
                        className="form-control"
                        id="otpInput"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="OTP"
                      />
                      <RiRefreshLine style={{ color: 'white', fontSize: '35px', background: ' linear - gradient(123.35deg, #080D2F 3.3 %, rgba(17, 27, 90, 1) 100 %)', marginTop: '6px', marginLeft: '10px' }} onClick={handleResendOTP} />
                    </div>
                  </div>
                  <div class="d-flex flex-row justify-content-between">
                    <button type="submit" className="btn btn-primary " disabled={timeLeft > 0 ? false : true} style={{ width: '60%', borderRadius: '50px' }}>Verify OTP</button>
                    <div class="text-white mt-4 me-4">
                      {timeLeft > 0 ? (
                        <span>{timeLeft} sec</span>
                      ) : (
                        <span> OTP expired</span>
                      )}
                    </div>
                  </div>
                  {/* <button className="btn btn-primary" onClick={handleResendOTP}>Resend OTP</button> */}
                </form>
              </div>
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

export default ProfileUpdate;
