import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import "react-phone-number-input/style.css";
import { FaInfoCircle } from "react-icons/fa";

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const UpiSearch = () =>
{
  const [upiDetails, setupiDetails] = useState([]);
  const [chassisNumber, setChassisNumber] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [bankAddresses, setBankAddresses] = useState({});
  const [foundupi, setfoundupi] = useState(true);

  console.log("vehicle details ", chassisNumber);
  const handleChangeChassisNumber = (event) =>
  {
    setChassisNumber(event.target.value);
  };

  const searchChassis = async () =>
  {
    setLoading(true);
    console.log("searching license number", chassisNumber);
    setLoading(false);
    setopenModal(true);
    try
    {
      const apiResponse = await fetch(`${base_url}/user/upi_details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({
          mobileNumber: chassisNumber,
        }),
      });
      console.log("api response ", apiResponse);
      const details = await apiResponse?.json();
      if (apiResponse.ok)
      {
        console.log("true", details);
        toast.success("Successfully fetched Vehicle Details");
        setopenModal(true);
        setupiDetails(details.data.upiDetailsList);
      }

      else
      {
        setopenModal(false);
        toast.error(details.message)
        console.error(details.message);

        return;
      }
    } catch (error)
    {
      toast.error("Failed to fetch Vehicle Details");
      console.error("Error:", error);
    } finally
    {
      setLoading(false);
    }
  };

  const closeModal = () =>
  {
    setopenModal(false);
  };
  console.log("Aaaa", upiDetails);
  const fetchBankAddress = async (upiDetail, index) =>
  {
    try
    {
      setLoading(true);
      const response = await fetch(
        `https://ifsc.razorpay.com/${upiDetail.bankIfsc}`
      );
      const data = await response.json();
      console.log("API Response: ", data);

      const fullAddress = `${data.BRANCH}, ${data.CENTRE}, ${data.DISTRICT}, ${data.STATE}, ${data.ADDRESS}, ${data.CONTACT}, ${data.CITY}, ${data.MICR}, ${data.BANK}, ${data.BANKCODE}, ${data.IFSC}`;

      setBankAddresses((prev) => ({
        ...prev,
        [index]: fullAddress, // Accessing the correct field from the API response
      }));
      setLoading(false);
    } catch (error)
    {
      setLoading(false);
      console.error("Error fetching bank address:", error);
      toast.error("Failed to fetch bank address");
    }
  };

  return (
    <div className="res3 row p-2">
      {/* ----------------loading ---------------- */}
      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">loading...</div>
        </div>
      )}

      {/* vehicle number search  */}
      <div className="col-md-8 mx-auto ">
        <div
          className="col-lg-8 col-md-12 col-sm-8 mx-auto border"
          style={{ borderRadius: "19px", backgroundColor: "#0F1535" }}
        >
          <div className="search-header  text-white text-center py-2 mb-4 mt-3">
            <h2>Upi Search</h2>
          </div>

          <div
            className="input-group mb-3 align-items-center mx-auto "
            style={{ width: "80%" }}
          >
            <input
              className="form-control"
              type="text"
              placeholder="Phone Number "
              value={chassisNumber}
              onChange={handleChangeChassisNumber}
            />
          </div>

          <div className="d-flex justify-content-between flex-row-reverse mb-4">
            <button
              className="btn btn-primary mx-auto"
              onClick={searchChassis}
              style={{
                maxWidth: "200px",
                borderRadius: "15px",
                fontSize: "13px",
              }}
            >
              Search Number
            </button>
          </div>
        </div>
      </div>

      {/* Render vehicle details here */}
      {openModal && (
        <div className="license-details">
          {/* Display license details */}

          <div
            className="modal-content mx-auto mt-5"
            style={{
              backgroundColor: "rgba(0, 88, 191, 0.15)",
              boxShadow: "2px 2px 6px 0 rgba(0, 0, 0, 0.15)",
              border: "3px solid rgba(0, 88, 191, 1)",
              borderRadius: "12px",
              maxWidth: "650px",
            }}
          >
            <div
              className="modal-body text-center "
              style={{
                backgroundColor: "rgba(0, 88, 191, 0.15)",
                boxShadow: "2px 2px 6px 0 rgba(0, 0, 0, 0.15)",
              }}
            >
              <div
                class="text-white "
                style={{ float: "right" }}
                onClick={closeModal}
              >
                <IoCloseCircleOutline size={25} />
              </div>
              <div className="CDR-row px-3 pb-4 p-3 pt-2 ">
                <div
                  className="table-responsive "
                  style={{ maxHeight: "900px" }}
                >
                  <div className="d-flex flex-column align-items-start text-white p-2 ">
                    <div style={{ width: "100%" }}>
                      <strong
                        className="p-2  mx-auto "
                        style={{ color: "rgba(206, 206, 206, 1)" }}
                      >
                        {" "}
                        Upi Info :
                      </strong>

                      <div className=" d-flex align-items-center justify-content-between mt-3">
                        <div
                          className="d-flex flex-column align-items-start justify-content-center "
                          style={{ width: "100%" }}
                        >
                          {upiDetails?.length > 0 ? (
                            upiDetails.map((upiDetail, index) =>
                            {
                              console.log(index);
                              return (
                                <div
                                  id={`section${index}`}
                                  class="mt-5 text-start border upibox p-3"
                                  style={{ borderRadius: "10px" }}
                                  key={index}
                                >
                                  <p>Name: {upiDetail.name}</p>
                                  <p>UPI ID: {upiDetail.upiId}</p>
                                  <p>PSP Name: {upiDetail.pspName}</p>
                                  <p>
                                    Bank IFSC: {upiDetail.bankIfsc}
                                    <span
                                      onClick={() =>
                                        fetchBankAddress(upiDetail, index)
                                      }
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                        color: "blue",
                                      }}
                                    >
                                      <FaInfoCircle /> Get Details
                                    </span>
                                  </p>
                                  {/* Display bank address */}
                                  {bankAddresses[index] && (
                                    <p>Bank Address: {bankAddresses[index]}</p>
                                  )}

                                  <p>Code: {upiDetail.code}</p>
                                  <p>Payee Type: {upiDetail.payeeType}</p>
                                </div>
                              );
                            })
                          ) : foundupi ? (
                            <div class="mt-3 text-start ">
                              Click to find UPI details
                            </div>
                          ) : (
                            <div
                              class="mt-3 text-start text-danger "
                              style={{ fontSize: "20px", fontWeight: "bold" }}
                            >
                              UPI data not found{" "}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpiSearch;
