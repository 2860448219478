import React from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import CDRSignInPage from "./components/CDRSignInPage";
import CDRSignUpPage from "./components/CDRSignUpPage";
import Background from "./components/Background";
import Profile from "./components/Profile/Profile";
import Reports from "./components/Reports/Reports";
import { toast, ToastContainer } from "react-toastify";
import "react-phone-number-input/style.css";

// import AnalysisCDR from "./components/AnalysisCDR/AnalysisCDR";
import Activity from "./components/Activity/Activity";
import AiAnalysis from "./components/AI-Analysis/AiAnalysis";
import Help from "./components/Help/Help";
import CaseManagement from "./components/CaseManagement/CaseManagement";
import Dashboard from "./components/Dashboard/Dashboard";
import Protected from "./components/Protected";
import AddCase from "./components/CaseManagement/AddCase";
import Search from "./components/Search/Search";
import { Provider } from "react-redux";
import store from "./store/store";
import Map from "./components/AnalysisCDR/Map";
import Geo from "./components/AnalysisCDR/Geo";
import { Toaster } from "react-hot-toast";
import DetailedCase from "./components/CaseManagement/DetailedCase";
import Main from "./components/Multiple_analysis/MulAnalysis";
import Table_1 from "./components/Multiple_analysis/Table_1";
import Diary from "./components/Diary/Diary";
// import loadingComp from './components/loading_';
import Visualization from "./components/AnalysisCDR/Visualization";
import IPDRAnalysis from "./components/IpdrAnalysis/IPDRAnalysis";
import SingleNumSearcj from "./components/NumSearch/SingleNumSearch";
import SingleNumSearch from "./components/NumSearch/SingleNumSearch";
import MultiNumSearch from "./components/NumSearch/MultiNumSearch";
import DLSearch from "./components/NumSearch/DLSearch";
import ImageSearch from "./components/imageSearch/ImageSearch";
import ImageUrlSearch from "./components/imageSearch/ImageUrlSearch";
import ReverseRcSearch from "./components/NumSearch/ReverseRcSearch";
import UpiSearch from "./components/NumSearch/UpiSearch";
import PanCardSearch from "./components/NumSearch/PanCardSearch";
import FaceMatchSearch from "./components/NumSearch/FaceMatchSearch";
import VPNProxySearch from "./components/NumSearch/VPNProxySearch";
import IpGeolocationSearch from "./components/NumSearch/IpGeolocationSearch";
import APICountSearch from "./components/NumSearch/APICountSearch";
import OSINTSearch from "./components/NumSearch/OSINTSearch";
const LazyCDRAnalysis = React.lazy(() =>
  import("./components/AnalysisCDR/AnalysisCDR")
);
// const Main = React.lazy(()=> import("./components/Multiple_analysis/MulAnalysis"))
// const Visualization = React.lazy(()=> import("./components/AnalysisCDR/Visualization"))
// const Geo = React.lazy(()=> import("./components/AnalysisCDR/Geo"))

function MapPage()
{
  const { latitude, longitude } = useParams();
  const lat = parseFloat(latitude);
  const lon = parseFloat(longitude);
  return <Map latitude={lat} longitude={lon} />;
}
function Page_2()
{
  const { _id } = useParams();
  return <DetailedCase _id={_id} />;
}
function App()
{
  return (
    <>
      <ToastContainer />
      <Provider store={store}>
        <BrowserRouter>
          <Toaster
            reverseOrder={false}
            gutter={2}
            containerClassName="toast-hot"
            toastOptions={{
              duration: 5000,
              style: {
                background:
                  "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 1) 100%)",
                color: "#fff",
              },
              success: {
                duration: 3000,
                theme: {
                  primary: "green",
                  secondary: "black",
                },
              },
            }}
          />
          <Routes>
            <Route
              path="/"
              element={<Protected Component={Dashboard} pagename="Dashboard" />}
            />
            <Route
              path="/reminder"
              element={<Protected Component={Diary} pagename="Daily Diary" />}
            />
            <Route
              path="/profile"
              element={<Protected Component={Profile} pagename="Profile" />}
            />
            <Route
              path="/reports"
              element={<Protected Component={Reports} pagename="Reports" />}
            />

            <Route
              path="/CDR-analysis/analysis"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected
                    Component={LazyCDRAnalysis}
                    pagename="CDR Analysis"
                  />
                </React.Suspense>
              }
            />
            <Route
              path="/CDR-analysis/ipdr-analysis"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected
                    Component={IPDRAnalysis}
                    pagename="IPDR Analysis"
                  />
                </React.Suspense>
              }
            />
            <Route
              path="/CDR-analysis/multiple-analysis"
              element={
                <Protected Component={Main} pagename="Multiple Analysis" />
              }
            />
            <Route
              path="/CDR-analysis/visualization"
              element={
                <Protected
                  Component={Visualization}
                  pagename="CDR Analysis/ Visualization"
                />
              }
            />
            <Route
              path="/Search/single-search"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected
                    Component={SingleNumSearch}
                    pagename="Single Search"
                  />
                </React.Suspense>
              }
            />
            <Route
              path="/Search/multiple-search"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected
                    Component={MultiNumSearch}
                    pagename="Multiple Search"
                  />
                </React.Suspense>
              }
            />
            <Route
              path="/Search/VehicleSearch"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected
                    Component={DLSearch}
                    pagename="Vehicle License Search"
                  />
                </React.Suspense>
              }
            />
            <Route
              path="/Search/ReverseVehicleSearch"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected
                    Component={ReverseRcSearch}
                    pagename="Reverse RC Search"
                  />
                </React.Suspense>
              }
            />
            <Route
              path="/Search/UpiSearch"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected Component={UpiSearch} pagename="Upi Search" />
                </React.Suspense>
              }
            />
            <Route
              path="/Search/PanCardSearch"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected
                    Component={PanCardSearch}
                    pagename="Pan Card Search"
                  />
                </React.Suspense>
              }
            />
            <Route
              path="/Search/FaceMatchSearch"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected
                    Component={FaceMatchSearch}
                    pagename="Face Match Search"
                  />
                </React.Suspense>
              }
            />
            <Route
              path="/Search/VPNProxySearch"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected
                    Component={VPNProxySearch}
                    pagename="VPN Proxy Search"
                  />
                </React.Suspense>
              }
            />
            <Route
              path="/Search/IpGeolocationSearch"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected
                    Component={IpGeolocationSearch}
                    pagename="Ip Geolocation Search"
                  />
                </React.Suspense>
              }
            />
            <Route
              path="/Search/APICountSearch"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  {/* <Protected
                    Component={APICountSearch}
                    pagename="API Count Search"
                  /> */}
                </React.Suspense>
              }
            />
            <Route
              path="/Search/OSINTSearch"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected Component={OSINTSearch} pagename="OSINT Search" />
                </React.Suspense>
              }
            />

            <Route
              path="/Search/ImageUrlSearch"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected
                    Component={ImageUrlSearch}
                    pagename="Image Url Search"
                  />
                </React.Suspense>
              }
            />
            <Route
              path="/Search/ImageFileSearch"
              element={
                <React.Suspense
                  fallback={
                    <div
                      className="loading-backdrop"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100vh",
                        background: "rgba(0, 0, 0, 0.6)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <div className="spinner-border text-light" role="status">
                        <div className="visually-hidden">Loading...</div>
                      </div>
                      <div className="text-white">loading...</div>
                    </div>
                  }
                >
                  <Protected Component={ImageSearch} pagename="Image Search" />
                </React.Suspense>
              }
            />

            <Route
              path="/activity"
              element={<Protected Component={Activity} pagename="Activity" />}
            />
            <Route
              path="/ai-analysis"
              element={
                <Protected Component={AiAnalysis} pagename="Ai Analysis" />
              }
            />
            <Route
              path="/help"
              element={<Protected Component={Help} pagename="Help" />}
            />
            <Route
              path="/CaseManagemet/cases"
              element={
                <Protected
                  Component={CaseManagement}
                  pagename="Case Management"
                />
              }
            />
            <Route
              path="/CaseManagemet/addCase"
              element={
                <Protected
                  Component={AddCase}
                  pagename="Case Management/Add case"
                />
              }
            />
            <Route
              path="/CaseManagement/caseDetails/:_id"
              element={
                <Protected Component={Page_2} pagename="Case Management" />
              }
            />
            <Route
              path="/search"
              element={<Protected Component={Search} pagename="Search" />}
            />
            <Route
              path="/CDR-analysis/geo-analysis/:latitude/:longitude"
              element={
                <Protected Component={MapPage} pagename="Geo Analysis" />
              }
            />
            <Route
              path="/CDR-analysis/geo-analysis"
              element={<Protected Component={Geo} pagename="Geo Analysis" />}
            />
            <Route
              path="/test"
              element={<Protected Component={Table_1} pagename="Test" />}
            />
            <Route
              path="/signin"
              element={
                <div className="login-container">
                  <Background />
                  <CDRSignInPage />{" "}
                </div>
              }
            />

            <Route
              exact
              path="/signup"
              element={
                <div className="login-container">
                  <Background />
                  <CDRSignUpPage />{" "}
                </div>
              }
            />
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
