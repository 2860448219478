import React from "react";

const ImageSearchResult = ({ data }) => {
  return (
    <a
      href={data?.link}
      class="m-1"
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      <div className=" mt-3">
        <div
          className="card text-white  mb-3"
          style={{
            backgroundColor: "#0F1535",
          }}
        >
          {data?.image && (
            <div className="text-center mt-3">
              <img
                src={data?.image}
                alt="Related Visual Content"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  borderRadius: "10px",
                }}
              />
            </div>
          )}
          <div className="ms-3 mt-4">
            <strong>Platform: </strong> {data?.domain}{" "}
            <img src={data?.logo} alt="Logo" height="20" className="ms-2 " />
          </div>
          <div className="card-body">
            <p className="card-text">
              <strong>Date: </strong>
              {data?.date}
            </p>

            <p className="card-title" class="fs-6">
              {" "}
              <strong>Description: </strong> {data?.title}
            </p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default ImageSearchResult;
