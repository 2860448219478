import { createSlice } from "@reduxjs/toolkit";

const multiCompleteDataSlice = createSlice({
  name: "multiCompleteDataRedux",
  initialState: null,
  reducers: {
    setMultiCompleteDataRedux: (state, action) => {
      return [...action.payload];
    },
  },
});

export const { setMultiCompleteDataRedux } = multiCompleteDataSlice.actions;
export default multiCompleteDataSlice.reducer;
