// CircularProgressBar.js
import React from 'react';

const CircularProgressBar = ({ percentage }) => {
    const radius = 80;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (percentage / 100) * circumference;

    // Define the gradient colors
    const gradientId = 'progressGradient';
    const gradientColors = ['#06A581', '#6bd8bf']; // Change these to your desired colors

    return (
        <svg width="120" height="120" viewBox="0 0 200 200">
          
            <defs>
                <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: gradientColors[0] }} />
                    <stop offset="100%" style={{ stopColor: gradientColors[1] }} />
                </linearGradient>
            </defs>

           
            <circle
                cx="100"
                cy="100"
                r={radius}
                stroke="black" 
                fill="rgb(5, 9, 32)"
                strokeWidth="16"
            />
            <circle
                cx="100"
                cy="100"
                r={radius}
                fill="transparent"
                stroke={`url(#${gradientId})`} 
                strokeWidth="16"
                strokeDasharray={circumference}
                strokeDashoffset={offset}
            />
            <text x="50%" y="55%" textAnchor="middle" dy="-0.3em" fontSize="32" fontWeight="900" fill="#FFFFFF">
                {percentage}%
            </text>
            <text x="50%" y="65%" textAnchor="middle" fontSize="14" fill="#8C8C8C">
            Current
            </text>
            <text x="51%" y="75%" textAnchor="middle" fontSize="14" fill="#8C8C8C">
            efficiency
            </text>
        </svg>
    );
};

export default CircularProgressBar;
