import React, { useEffect, useState } from "react";
import CircularProgressBar from "./CircularProgress";
import { useSelector } from "react-redux";
import DynamicCounter from "./DynamicCounter";
const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const CaseInfo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [efficiency, setEfficiency] = useState(0);
  const { data: user } = useSelector((state) => state.user);

  const style = {
    width: "40px",
    height: "40px",
    background: "#0075FF",
    display: "flex",
    borderRadius: "8px",
    justifyContent: "center",
    alignItems: "center",
  };
  const [totalCases, setTotalCases] = useState(0);
  const [openCases, setOpenCases] = useState(0);
  const [closedCases, setClosedCases] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${base_url}/user/list_case?limit=10000000`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": sessionStorage.getItem("token"),
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const json = await response.json();
        if (json.status === 200) {
          const casesData = json.data;
          const openCount = casesData.filter(
            (caseItem) => caseItem.status === "OPEN"
          ).length;
          const closedCount = casesData.filter(
            (caseItem) => caseItem.status === "CLOSED"
          ).length;
          setTotalCases(json.totalRecord);
          setOpenCases(openCount);
          setClosedCases(closedCount);
          setIsLoading(false);

          // Calculate efficiency as the percentage of closed cases out of total cases
          const efficiencyPercentage =
            (parseInt(closedCount) / parseInt(json.totalRecord)) * 100;
          setEfficiency(efficiencyPercentage);
        } else {
          throw new Error("Request was not successful");
        }
      } catch (error) {
        console.error("Failed to Fetch:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div
      className="d-flex flex-column mb-3 caseInfo"
      style={{
        background:
          "linear-gradient(123.35deg, #080D2F 3.3%, rgba(8, 13, 47, 0.2) 100%)",
        borderRadius: "25px",
      }}
    >
      {/* {isLoading ? (
                <div style={{ position: 'absolute', top: '50%', left: '60%', transform: 'translate(-50%, -50%)' }}>
                    <RotatingLines
                        strokeColor="grey"
                        strokeWidth="5"
                        animationDuration="1"
                        width="50"
                        visible={true}
                    />
                    <div className="mt-2 text-white fw-bold">Loading...</div>
                </div>
            ) : ( */}
      <>
        <div className="p-3 pb-0 text-white fw-bold">Cases Informations</div>
        <div className="p-3 pt-2 text-secondary" style={{ fontSize: "13px" }}>
          Hello, <span className="fw-bold">{user.firstName}</span>
          <span className="ms-1 fw-bold">{user.lastName}!</span> Your case is
          ready.
        </div>
        <div className="p-3 pt-0 pb-1 text-center">
          <CircularProgressBar percentage={efficiency.toFixed(2)} />
        </div>
        <div className="p-3 pt-0 ms-2 text-white text-center fw-bold">
          <DynamicCounter startTime={user.createdAt} />
        </div>
        <div className="row p-3 pt-1 ">
          <div className="col-sm-6">
            <div
              className="d-flex flex-row p-3 mb-3"
              style={{ background: "rgb(5, 9, 32)", borderRadius: "15px" }}
            >
              <div>
                <div
                  className="text-secondary fw-normal"
                  style={{ fontSize: "12px" }}
                >
                  Total no. of cases
                </div>
                <div className="text-white mt-2 fs-5 fw-bold">{totalCases}</div>
              </div>
              <div style={style} className="ms-auto my-auto"></div>
            </div>
          </div>
          <div className="col-sm-6">
            <div
              className="d-flex flex-row p-3 mb-3"
              style={{ background: "rgb(5, 9, 32)", borderRadius: "15px" }}
            >
              <div>
                <div
                  className="text-secondary fw-normal"
                  style={{ fontSize: "12px" }}
                >
                  Open cases
                </div>
                <div className="text-white mt-2 fs-5 fw-bold">{openCases}</div>
              </div>
              <div style={style} className="ms-auto my-auto"></div>
            </div>
          </div>
          <div className="col-sm-6">
            <div
              className="d-flex flex-row p-3 mb-3"
              style={{ background: "rgb(5, 9, 32)", borderRadius: "15px" }}
            >
              <div>
                <div
                  className="text-secondary fw-normal"
                  style={{ fontSize: "12px" }}
                >
                  Closed cases
                </div>
                <div className="text-white mt-2 fs-5 fw-bold">
                  {closedCases}
                </div>
              </div>
              <div style={style} className="ms-auto my-auto"></div>
            </div>
          </div>
          <div className="col-sm-6">
            <div
              className="d-flex flex-row p-3 mb-0"
              style={{ background: "rgb(5, 9, 32)", borderRadius: "15px" }}
            >
              <div>
                <div
                  className="text-secondary fw-normal"
                  style={{ fontSize: "12px" }}
                >
                  CDR applied cases
                </div>
                <div className="text-white mt-2 fs-5 fw-bold">0</div>
              </div>
              <div style={style} className="ms-auto my-auto"></div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default CaseInfo;
