import { createSlice } from '@reduxjs/toolkit';

const targetNoDataSlice = createSlice({
    name: 'targetNoData',
    initialState: null,
    reducers: {
        setTargetNoData: (state, action) => {
            return action.payload;
        },
    },
});

export const { setTargetNoData } = targetNoDataSlice.actions;

export default targetNoDataSlice.reducer;
