import React, { useState, useEffect } from "react";
import "./analysis.css";
import
{
  useJsApiLoader,
  GoogleMap,
  MarkerF,
  InfoWindow,
  InfoWindowF,
} from "@react-google-maps/api";

const Map = ({ latitude, longitude }) =>
{
  const center = { lat: latitude, lng: longitude };
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [locationInfo, setLocationInfo] = useState(null);

  const toggleInfoWindow = () =>
  {
    setIsInfoWindowOpen(!isInfoWindowOpen);
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAfEYRdDPTAw2THWoyCwynliaTtnEMyex8",
  });

  useEffect(() =>
  {
    if (isLoaded)
    {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({ location: center }, (results, status) =>
      {
        if (status === "OK" && results[0])
        {
          setLocationInfo(results[0].formatted_address);
        } else
        {
          setLocationInfo("Location not found");
        }
      });
    }
  }, [isLoaded, center]);

  if (!isLoaded)
  {
    return <div style={{ color: "black" }}>Loading...</div>;
  }

  return (
    <>
      <div className="container map">
        <GoogleMap
          center={center}
          zoom={16}
          mapContainerStyle={{ width: "100%", height: "100vh" }}
          options={{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
        >
          <MarkerF
            position={center}
            size={30}
            className="custom-marker"
            label="1"
            onClick={toggleInfoWindow}
          />

          {isInfoWindowOpen && (
            <InfoWindowF position={center} onCloseClick={toggleInfoWindow}>
              <div>
                <p>{locationInfo || "Fetching location..."}</p>
              </div>
            </InfoWindowF>
          )}
        </GoogleMap>
      </div>
    </>
  );
};

export default Map;
