// filteredDataSlice.js
import { createSlice } from '@reduxjs/toolkit';

const filteredDataSlice = createSlice({
    name: 'filteredData',
    initialState: [],
    reducers: {
        setFilteredData: (state, action) => {
            return action.payload;
        },
    },
});

export const { setFilteredData } = filteredDataSlice.actions;

export default filteredDataSlice.reducer;
