import React from "react";
// import ReactApexChart from "react-apexcharts";
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer, Cell,Text } from 'recharts';
import { useSelector } from 'react-redux';

function VisChart2() {


  const tableData = useSelector((state) => state.data);
  console.log("TableData");
  console.log(tableData);

  const extractedData = tableData.map(item => ({ b_party_number: item.b_party_number, call_duration: item.call_duration  }));

  extractedData.sort((a, b) => b.call_duration - a.call_duration);

  const topFiveTotalCalls = extractedData.slice(0, 5);

  const pieData = topFiveTotalCalls.map(item => ({ b_party_number: item.b_party_number, call_duration: item.call_duration  }));

  const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF'];

  return (
    <div
      className="text-white align-items-center p-3"
      style={{
width: '100%',
        background:
          "linear-gradient(103.46deg, #080D2F 1.69%, rgba(8, 13, 47, 0.35) 99.32%)",
        borderRadius: "25px",
        textTransform: "capitalize",
        textAlign: 'center'
      }}>
    
      <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={pieData}
          dataKey="call_duration"
          nameKey="b_party_number"
          outerRadius={80}
          fill="#8884d8"
          label={(entry) => entry.b_party_number}
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
    <h2 className="mt-3" style={{ fontSize: '14px' }}>Call Duration vs B-Party Number</h2>
    </div>
  );
}

export default VisChart2;
