import React from 'react'
import { media } from "../img_slides/index"
import "./help.css";
// import { useRef } from 'react'

const Help = () =>
{

  const [file, setFile] = React.useState(null);

  // const videoRef = useRef();


  // const handlePlay = () =>
  // {

  //   videoRef.current.play();

  // }

  // const handlePause = () =>
  // {

  //   videoRef.current.pause();

  // }


  return (
    <div>

      <div className='head_name' style={{ marginTop: -40, marginBottom: 30 }}>Video Tutorial Of C.A.T.C.H</div>

      {/* <div>
        <button style={{ height: 30, width: 60, background: 'black', borderRadius: 10, color: 'white', marginLeft: 500, zIndex: 10, position: 'relative' }} onClick={handlePlay}>
          Play
        </button>

        <button style={{ height: 30, width: 60, background: 'black', borderRadius: 10, color: 'white', marginLeft: 100, zIndex: 10, position: 'relative' }} onClick={handlePause} >
          Pause
        </button>

      </div> */}

      {/* video player */}
      <div className='video-container'>
        <video controls >
          <source src='/video/HelpVideo.mkv' />

        </video>
      </div>



      <div className='head_name' style={{ marginTop: 40, marginBottom: 30 }}>
        How To Use C.A.T.C.H Step By Step
      </div>


      <div className='main-div'>

        <div className='media-container'>

          {

            media.map((file, index) => (

              <div className='media' key={index} onClick={() => setFile(file)}>
                {
                  file.type === 'image' ?
                    <img src={file.url} alt="" />
                    : null
                }

              </div>

            ))

          }


        </div>

      </div>

      <div className='popup' style={{ display: file ? 'block' : 'none' }}>
        <span onClick={() => setFile(null)}>&times;</span>
        {
          file?.type === 'image' ? <img src={file?.url} alt="" /> : null

        }
      </div>

    </div>
  )
}

export default Help
