import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const VPNProxySearch = () => {
  const [ipDetails, setIpDetails] = useState({});
  const [ipAddress, setIpAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleChangeIpAddress = (event) => {
    setIpAddress(event.target.value);
  };

  const searchIpAddress = async () => {
    setLoading(true);
    try {
      const apiResponse = await fetch(`${base_url}/user/get_vpn_proxy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({
          ipAddress: ipAddress,
        }),
      });

      if (apiResponse.ok) {
        const details = await apiResponse.json();
        console.log("details##############", details);
        toast.success("Successfully fetched IP Details");
        if (details?.data?.request_id) {
          setIpDetails(details?.data);
        } else {
          setIpDetails(details?.data?.data);
        }
        console.log("ipdetails", ipDetails);
        setOpenModal(true);
        // setIpDetails(details.data);
      } else {
        const errorData = await apiResponse.json();
        toast.error(errorData.message || "Failed to fetch IP details");
      }
    } catch (error) {
      toast.error("Failed to fetch IP details");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="res3 row p-2">
      <ToastContainer />
      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">loading...</div>
        </div>
      )}

      <div className="col-md-8 mx-auto ">
        <div
          className="col-lg-8 col-md-12 col-sm-8 mx-auto border"
          style={{ borderRadius: "19px", backgroundColor: "#0F1535" }}
        >
          <div className="search-header  text-white text-center py-2 mb-4 mt-3">
            <h2>VPN Proxy Search</h2>
          </div>

          <div
            className="input-group mb-3 align-items-center mx-auto "
            style={{ width: "80%" }}
          >
            <input
              className="form-control"
              type="text"
              placeholder="IP Address"
              value={ipAddress}
              onChange={handleChangeIpAddress}
            />
          </div>

          <div className="d-flex justify-content-between flex-row-reverse mb-4">
            <button
              className="btn btn-primary mx-auto"
              onClick={searchIpAddress}
              style={{
                maxWidth: "200px",
                borderRadius: "15px",
                fontSize: "13px",
              }}
            >
              Search IP
            </button>
          </div>
        </div>
      </div>

      {openModal && (
        <div className="license-details">
          <div
            className="modal-content mx-auto mt-5"
            style={{
              backgroundColor: "rgba(0, 88, 191, 0.15)",
              boxShadow: "2px 2px 6px 0 rgba(0, 0, 0, 0.15)",
              border: "3px solid rgba(0, 88, 191, 1)",
              borderRadius: "12px",
              maxWidth: "650px",
            }}
          >
            <div
              className="modal-body text-center "
              style={{
                backgroundColor: "rgba(0, 88, 191, 0.15)",
                boxShadow: "2px 2px 6px 0 rgba(0, 0, 0, 0.15)",
              }}
            >
              <div
                className="text-white "
                style={{ float: "right" }}
                onClick={closeModal}
              >
                <IoCloseCircleOutline size={25} />
              </div>
              <div className="CDR-row px-3 pb-4 p-3 pt-2 ">
                <div
                  className="table-responsive "
                  style={{ maxHeight: "900px" }}
                >
                  <div className="d-flex flex-column align-items-start text-white p-2 ">
                    <div style={{ width: "100%" }}>
                      <strong
                        className="p-2  mx-auto "
                        style={{ color: "rgba(206, 206, 206, 1)" }}
                      >
                        IP Details:
                      </strong>
                      <div className="d-flex flex-column align-items-start justify-content-center mt-3">
                        {Object.keys(ipDetails)?.length > 0 ? (
                          <>
                            <p>
                              Request ID:{" "}
                              <strong>{ipDetails?.request_id || "N/A"}</strong>
                            </p>
                            <p>
                              IP Address:{" "}
                              <strong>{ipDetails?.ip || "N/A"}</strong>
                            </p>
                            <p>
                              Hostname:{" "}
                              <strong>{ipDetails?.hostname || "N/A"}</strong>
                            </p>
                            <p>
                              Country:{" "}
                              <strong>
                                {ipDetails?.country_name || "N/A"}
                              </strong>
                            </p>
                            <p>
                              ASN: <strong>{ipDetails?.asn || "N/A"}</strong>
                            </p>
                            <p>
                              ISP: <strong>{ipDetails?.isp || "N/A"}</strong>
                            </p>
                            <p>
                              IP Blocked:{" "}
                              <strong>
                                {ipDetails?.ip_block ? "Yes" : "No"}
                              </strong>
                            </p>
                          </>
                        ) : (
                          <p>No IP details found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VPNProxySearch;
