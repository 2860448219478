import { createSlice } from '@reduxjs/toolkit';

const smsDataSlice = createSlice({
    name: 'smsData',
    initialState: null,
    reducers: {
        setsmsData: (state, action) => {
            return action.payload;
        },
    },
});

export const { setsmsData } = smsDataSlice.actions;

export default smsDataSlice.reducer;
