import React from 'react'

const Loader = () => {
  return (
    <>

                <span className='text-white text-center'>Loading...</span>
            
    </>
  )
}

export default Loader
