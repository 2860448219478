import React, { useState } from "react";
import { useSelector } from "react-redux";
// report based on imei data
const Table_7 = ({ index }) => {
  const multiCompleteDataRedux = useSelector(
    (state) => state.multiCompleteDataRedux
  );
  console.log(
    "redux imei data is ",
    multiCompleteDataRedux[index].record_based_on_imei_data
  );

  const [imeiData, setImeiData] = useState(
    multiCompleteDataRedux[index].record_based_on_imei_data
  );

  return (
    <>
      <div className="row res3 px-3 pb-5">
        <div className="CDR-row">
          <div className="table-responsive" style={{ maxHeight: "500px" }}>
            <table className="table mt-2 table-bordered border-secondary align-middle">
              <thead className="table_cdr sticky-top table-header-sticky">
                <tr>
                  <th scope="col">S.NO</th>
                  <th scope="col">IMEI</th>
                  <th scope="col">IMSI</th>
                </tr>
              </thead>
              <tbody
                className="text-white"
                style={{ fontSize: "12px", background: "#080D2F" }}
              >
                {imeiData?.map((item, index) => (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <th className="py-3" scope="row">
                      {index + 1}
                    </th>

                    <td className="py-3">{item.imei}</td>
                    <td className="py-3">{item.imsi}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table_7;
