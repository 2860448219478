import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaInfoCircle } from "react-icons/fa";
import { Link } from "react-scroll";
import CopyButton from "../AnalysisCDR/CopyButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//complete data

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const Table_2 = ({ index }) => {
  console.log("index ", index);
  const multiCompleteDataRedux = useSelector(
    (state) => state.multiCompleteDataRedux
  );
  console.log("multicomplete data on table 2  ", multiCompleteDataRedux);
  let records = multiCompleteDataRedux[`${index}`]?.records;
  const [loading, setLoading] = useState(false);
  const [upiDetails, setupiDetails] = useState();
  const [foundupi, setfoundupi] = useState(true);
  const [selectedNum, setSelectedNum] = useState(null);
  const [isBottom, setIsBottom] = useState(false);

  const navigate = useNavigate();

  const numRef = useRef();
  const closeRef = useRef();
  const modalRef = useRef(null);

  const handleButtonClick = () => {
    const modalElement = modalRef?.current;

    // If user is at the bottom, scroll up; otherwise, scroll down
    if (isBottom) {
      console.log("scrolled to top");
      modalElement.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      console.log("scrolled to bottom");
      modalElement.scrollTo({
        top: modalElement.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  const formatDate = (inputDate) => {
    // console.log(inputDate)
    const date = new Date(inputDate);
    // console.log(date)
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  // console.log("table 2 multi complete data ", records);

  const handleGetDetails = async (rowData) => {
    setfoundupi(true);
    setupiDetails(null);
    setLoading(true);
    console.log("number to send ", rowData.calledPhoneNumber);
    // console.log("Clicked row data:", rowData);
    const apiResponse = await fetch(`${base_url}/user/number_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": sessionStorage.getItem("token"),
      },
      body: JSON.stringify({ number: rowData.calledPhoneNumber }),
    });
    console.log(apiResponse);
    if (apiResponse.ok) {
      const numDetails = await apiResponse.json();
      setLoading(false);
      // console.log(numDetails?.data, "Number Details");
      if (numDetails?.data) {
        setSelectedNum(numDetails?.data);
        console.log("get details data ", selectedNum);
      } else {
        console.error("Number details data is not in the expected format");
        setSelectedNum(null);
      }
      console.log(numDetails, "1");
      console.log(numDetails.data, "2");
    } else {
      console.error("Failed to fetch Number details");
    }

    numRef.current.click();
  };

  const handleUpiSearch = async () => {
    console.log("upi search", selectedNum?.phones?.[0]?.e164Format?.slice(-10));
    try {
      const extractednumber = selectedNum?.phones?.[0]?.e164Format?.slice(-10);
      setLoading(true);
      const apiResponse = await fetch(`${base_url}/user/upi_details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({ phone: extractednumber }),
      });

      if (apiResponse.ok) {
        const numDetails = await apiResponse?.json();

        console.log("output from api ", numDetails?.data?.data?.upiDetailsList);
        setupiDetails(numDetails?.data?.data?.upiDetailsList);
        console.log("retrieved details  ", upiDetails);
        setLoading(false);
        handleButtonClick();
        console.log("scrolled ");
        toast.success("Successfully fetched UPI details");
      } else {
        setLoading(false);
        setfoundupi(false);
        toast.error("failed to fetch UPI details");
        console.error("failed to fetch UPI details");
      }
    } catch (error) {
      setLoading(false);
      setfoundupi(false);
      toast.error("failed to fetch UPI details");
      console.error("failed to fetch UPI details");
    } finally {
      setLoading(false);
    }
  };

  const [bankAddresses, setBankAddresses] = useState({});

  const fetchBankAddress = async (upiDetail, index) => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://ifsc.razorpay.com/${upiDetail.bankIfsc}`
      );
      const data = await response.json();
      console.log("API Response: ", data);

      const fullAddress = `${data.BRANCH}, ${data.CENTRE}, ${data.DISTRICT}, ${data.STATE}, ${data.ADDRESS}, ${data.CONTACT}, ${data.CITY}, ${data.MICR}, ${data.BANK}, ${data.BANKCODE}, ${data.IFSC}`;

      setBankAddresses((prev) => ({
        ...prev,
        [index]: fullAddress, // Accessing the correct field from the API response
      }));
      setLoading(false);
      toast.success("Successfully fetched bank address");
    } catch (error) {
      console.error("Error fetching bank address:", error);
      toast.error("Failed to fetch bank address");
      setLoading(false);
    }
  };

  const handleImageSearch = () => {
    console.log("image url is ", selectedNum?.image);
    navigate("/Search/ImageUrlSearch", {
      state: { imageUrl: selectedNum?.image },
    });
    closeRef.current.click();
  };

  return (
    <>
      {/* ---------------------------------------------------------Loading--------------------------------------------------------- */}
      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "99999999",
          }}
        >
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">loading...</div>
        </div>
      )}

      {/* --------------------------------------------------more_details_modal-------------------------------------------------- */}
      <button
        type="button"
        ref={numRef}
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#modaltable1"
      >
        Launch demo modal
      </button>
      <div
        ref={modalRef}
        // ref={upiRef}
        class="modal fade "
        id="modaltable1"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ zIndex: "99999" }}
      >
        <div className="modal-dialog modal-lg  ">
          <div
            className="modal-content mx-auto"
            style={{
              backgroundColor: "rgba(0, 88, 191, 0.15)",
              boxShadow: "2px 2px 6px 0 rgba(0, 0, 0, 0.15)",
              border: "3px solid rgba(0, 88, 191, 1)",
              borderRadius: "12px",
              maxWidth: "650px",
            }}
          >
            <div
              className="modal-body text-center "
              style={{
                backgroundColor: "rgba(0, 88, 191, 0.15)",
                boxShadow: "2px 2px 6px 0 rgba(0, 0, 0, 0.15)",
              }}
            >
              <i
                type="button"
                className="fa-regular fa-circle-xmark float-end text-white fa-lg"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeRef}
              ></i>
              <div className="CDR-row px-3 pb-4 p-3 pt-2 ">
                {selectedNum && (
                  <div
                    className="table-responsive "
                    style={{ maxHeight: "900px" }}
                  >
                    <div className="d-flex flex-column align-items-start text-white p-2 ">
                      <div className="w-100 d-flex align-center flex-column justify-content-center">
                        {selectedNum.image ? (
                          <div class="d-flex flex-column">
                            <img
                              className=""
                              src={selectedNum.image}
                              alt={selectedNum.name}
                              style={{
                                marginRight: "auto",
                                marginLeft: "auto",
                                width: "160px",
                                height: "160px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                border: "13px solid rgba(0, 88, 191, 1)",
                              }}
                            />
                            <button
                              onClick={handleImageSearch}
                              className="btn btn-primary mx-auto"
                              style={{ borderRadius: "10px", width: "60%" }}
                            >
                              Search Image
                            </button>
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "120px",
                              height: "120px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#ddd",
                              color: "#333",
                              border: "12px solid rgba(0, 88, 191, 1)",
                            }}
                          >
                            No image available
                          </div>
                        )}
                        <strong className="p-2 fs-3">
                          {selectedNum?.name}
                        </strong>
                      </div>
                      <div className="w-100 d-flex align-items-center justify-content-between ">
                        <div className="d-flex flex-column align-items-start justify-content-center p-2">
                          <div className="d-flex align-items-center gap-2  p-2">
                            <p
                              className="mb-0 mr-2"
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                              }}
                            >
                              Mobile No. :{" "}
                            </p>{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              {selectedNum?.phones?.[0]?.e164Format || "N/A"}
                            </span>
                          </div>
                          <div className="d-flex align-items-center gap-2  p-2">
                            <p
                              className="mb-0 mr-2"
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                              }}
                            >
                              Company. :{" "}
                            </p>{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              {selectedNum?.phones?.[0]?.carrier || "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-start justify-content-center ">
                          <div className="d-flex align-items-center gap-2  p-2">
                            <p
                              className="mb-0 mr-2"
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                              }}
                            >
                              Number Type :{" "}
                            </p>{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              {selectedNum?.phones?.[0]?.numberType || "N/A"}
                            </span>
                          </div>
                          <div className="d-flex align-items-center gap-2  p-2">
                            <p
                              className="mb-0 mr-2"
                              style={{
                                color: "rgba(206, 206, 206, 1)",
                                fontSize: "15px",
                              }}
                            >
                              Country Code :{" "}
                            </p>{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {" "}
                              {selectedNum?.addresses?.[0]?.countryCode ||
                                "N/A"}
                            </span>
                          </div>
                        </div>
                      </div>

                      {selectedNum.addresses &&
                        selectedNum.addresses.map((address, index) => (
                          <div
                            key={index}
                            className="d-flex flex-column w-100 "
                          >
                            <hr />
                            <strong
                              className="p-2"
                              style={{ color: "rgba(206, 206, 206, 1)" }}
                            >
                              {" "}
                              Address:{" "}
                            </strong>
                            <div className="w-100 d-flex align-items-center justify-content-between">
                              <div className="d-flex flex-column align-items-start justify-content-center p-2">
                                <div className="d-flex align-items-center gap-2  p-2">
                                  <p
                                    className="mb-0 mr-2"
                                    style={{
                                      color: "rgba(206, 206, 206, 1)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    City :{" "}
                                  </p>{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    <span>{address?.city || "N/A"}</span>
                                  </span>
                                </div>
                                <div className="d-flex align-items-center gap-2  p-2">
                                  <p
                                    className="mb-0 mr-2"
                                    style={{
                                      color: "rgba(206, 206, 206, 1)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Country :{" "}
                                  </p>{" "}
                                  <span>
                                    {" "}
                                    <span style={{ fontWeight: "bold" }}>
                                      {address?.countryCode || "N/A"}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex flex-column align-items-start justify-content-center ">
                                <div className="d-flex align-items-center gap-2  p-2">
                                  <p
                                    className="mb-0 mr-2"
                                    style={{
                                      color: "rgba(206, 206, 206, 1)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Time zone :{" "}
                                  </p>{" "}
                                  <span>
                                    {" "}
                                    <span style={{ fontWeight: "bold" }}>
                                      {address?.timeZone || "N/A"}
                                    </span>
                                  </span>
                                </div>
                                <div className="d-flex align-items-center gap-2  p-2">
                                  <p
                                    className="mb-0 mr-2"
                                    style={{
                                      color: "rgba(206, 206, 206, 1)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Access :
                                  </p>
                                  <span>
                                    <span style={{ fontWeight: "bold" }}>
                                      {selectedNum?.access || "N/A"}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                      {selectedNum.internetAddresses &&
                        selectedNum.internetAddresses.map((address, index) => (
                          <div key={index} style={{ width: "100%" }}>
                            <hr />
                            <strong
                              className="p-2  mx-auto"
                              style={{ color: "rgba(206, 206, 206, 1)" }}
                            >
                              {" "}
                              Contact Info {index + 1} :
                            </strong>

                            <div className=" d-flex align-items-center justify-content-between">
                              <div
                                className="d-flex flex-column align-items-start justify-content-center p-2"
                                style={{ width: "100%" }}
                              >
                                <div
                                  className="d-flex  flex-row align-items-center gap-2  p-2"
                                  style={{ width: "100%" }}
                                >
                                  <div class="d-flex flex-row">
                                    <p
                                      className="mb-0 mr-2"
                                      style={{
                                        color: "rgba(206, 206, 206, 1)",
                                        fontSize: "15px",
                                      }}
                                    >
                                      ID :{" "}
                                    </p>{" "}
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      {" "}
                                      <p>{address?.id || "N/A"} </p>{" "}
                                      <CopyButton
                                        textToCopy={address?.id || "N/A"}
                                      />
                                    </p>
                                  </div>
                                </div>
                                <div
                                  className="d-flex align-items-center gap-2  p-2"
                                  style={{ marginTop: "-2.25rem" }}
                                >
                                  <p
                                    className="mb-0 mr-2"
                                    style={{
                                      color: "rgba(206, 206, 206, 1)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Service :{" "}
                                  </p>{" "}
                                  <span>
                                    {" "}
                                    <span>
                                      <span style={{ fontWeight: "bold" }}>
                                        {address?.service || "N/A"}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                      <Link
                        class="mx-auto "
                        style={{ borderRadius: "10px", width: "60%" }}
                        to="section0" // Specify the ID of the target section to scroll to
                        spy={true}
                        smooth={true}
                        duration={500} // Duration of the scroll animation in milliseconds
                      >
                        <button
                          onClick={handleUpiSearch}
                          className="btn btn-primary mx-auto"
                          style={{ borderRadius: "10px" }}
                        >
                          UPI Search
                        </button>
                      </Link>

                      <style>
                        {`
                              .upibox{
                                  border:3px solid rgba(0, 88, 191, 1) !important;
                              }
                            `}
                      </style>
                      <div class=" row  mx-auto ">
                        {upiDetails?.length > 0 ? (
                          upiDetails.map((upiDetail, index) => {
                            console.log(index);
                            return (
                              <div
                                id="modalContent"
                                class="mt-5 text-start border upibox p-3"
                                style={{ borderRadius: "10px" }}
                                key={index}
                              >
                                <p>Name: {upiDetail.name}</p>
                                <p>UPI ID: {upiDetail.upiId}</p>
                                <p>PSP Name: {upiDetail.pspName}</p>
                                <p>
                                  Bank IFSC: {upiDetail.bankIfsc}
                                  <span
                                    onClick={() =>
                                      fetchBankAddress(upiDetail, index)
                                    }
                                    style={{
                                      cursor: "pointer",
                                      marginLeft: "10px",
                                      color: "blue",
                                    }}
                                  >
                                    <FaInfoCircle /> Get Details
                                  </span>
                                </p>
                                {/* Display bank address */}
                                {bankAddresses[index] && (
                                  <p>Bank Address: {bankAddresses[index]}</p>
                                )}

                                <p>Code: {upiDetail.code}</p>
                                <p>Payee Type: {upiDetail.payeeType}</p>
                              </div>
                            );
                          })
                        ) : foundupi ? (
                          <div class="mt-3 text-start ">
                            Click to find UPI details
                          </div>
                        ) : (
                          <div
                            class="mt-3 text-start text-danger "
                            style={{ fontSize: "20px", fontWeight: "bold" }}
                          >
                            UPI data not found{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ------------------------------------------table------------------------------------------ */}
      <div className="row res3 px-3 pb-5 ">
        <style>
          {`

                        .no-scrollbar::-webkit-scrollbar {
                            display: none !important;
                        }

                        .example::-webkit-scrollbar {
                        display: none !important;
                        }

                        .example {
                        -ms-overflow-style: none !important; 
                        scrollbar-width: none !important;  
                        }
                         `}
        </style>
        <div className="CDR-row ">
          <div
            className="table-responsive pb-4 p-4"
            style={{ maxHeight: "600px" }}
          >
            <table className="table mt-2 table-bordered border-secondary align-middle">
              <thead className="table_cdr sticky-top table-header-sticky">
                <tr>
                  <th scope="col">S.NO</th>
                  <th scope="col">B-Party No.</th> {/*calling no */}
                  <th scope="col">No. Details</th>
                  <th scope="col">Date (DD/MM/YYYY)</th>
                  <th scope="col">Time</th>
                  <th scope="col">Duration(s)</th>
                  <th scope="col">Call Type</th>
                  <th scope="col">IMEI </th>
                  <th scope="col">IMSI </th>
                  <th scope="col">Last CGI Lat/Long</th>
                  <th scope="col">Address</th>
                </tr>
              </thead>
              <tbody
                className="text-white"
                style={{ fontSize: "12px", background: "#080D2F" }}
              >
                {records?.length > 0
                  ? records?.map((item, index) => {
                      // console.log(item);
                      return (
                        <tr key={index} style={{ cursor: "pointer" }}>
                          <th className="py-3" scope="row">
                            {index + 1}
                          </th>
                          <td className="py-3">
                            {item?.calledPhoneNumber !== "internet"
                              ? item?.calledPhoneNumber
                              : item?.calledPhoneNumber +
                                ` (IP Address: ${item.ipAddress})`}
                          </td>
                          <td
                            className={`  py-3 table-row-hover table-text-hover`}
                            style={{
                              color:
                                /^\d{10}$/.test(item?.calledPhoneNumber) ===
                                true
                                  ? ""
                                  : "grey",
                            }}
                            onClick={
                              /^\d{10}$/.test(item?.calledPhoneNumber) === true
                                ? () => handleGetDetails(item)
                                : undefined
                            }
                          >
                            Get Details
                          </td>
                          <td>{formatDate(item?.callDateTime)}</td>
                          <td className="py-3">{item?.callTime}</td>
                          <td className="py-3">{item?.duration || "N/A"} </td>
                          <td className="py-3">{item?.typeOfCall}</td>
                          <td className="py-3">{item?.imei}</td>
                          <td className="py-3">{item?.imsi}</td>
                          <td className="py-3">
                            {item?.latitude && item?.longitude
                              ? `${item?.latitude}/${item?.longitude}`
                              : "NA"}
                          </td>
                          <td className="py-3">
                            {" "}
                            {item.address || item.towerAddress || "N/A"}
                          </td>
                        </tr>
                      );
                    })
                  : "error parsing CDR file"}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table_2;
