import { useState } from "react";
import "../CSS/signup_signin.css";
import { Link } from "react-router-dom";
const CDRSignUpPage = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleNameChange = (event) => {
        setName(event.target.value);
    };
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleRememberMeChange = () => {
        setRememberMe(!rememberMe);
    };
    async function handleSubmit(e) {
        e.preventDefault()
        // const response = await fetch('https://api.seiasecure.com/api/v1/user/login', {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json",
        //     },
        //     body: JSON.stringify({ email, password }),
        // });
        console.log('ok');
    }
    return (
            <div className="content-overlay">
                <h3 className="header">Signup</h3>
                <form onSubmit={handleSubmit} className="login-form">
                    <div className="formContent">
                    <label htmlFor="email">Name</label>
                        <input
                            type="text"
                            id="name"
                            value={name}
                            onChange={handleNameChange}
                            style={{ marginBottom: '30px' }}
                            required
                        />
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={handleEmailChange}
                            style={{ marginBottom: '30px' }}
                            required
                        />
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                        <div className="form-check form-switch mt-2">
                            <input className="form-check-input" type="checkbox" />
                            <label className="form-check-label">Remember me</label>
                        </div>
                        <button type="submit">Signup</button>
                        <p className="signup-link"><span>
                            Don't have an account? </span> <Link to="/signin">Sign In</Link>
                        </p>
                    </div>
                </form>
            </div>
    )
};

export default CDRSignUpPage;
