import React, { useState, useEffect } from "react";
import
{
  useJsApiLoader,
  GoogleMap,
  Polygon,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";
import Alert from "./Alert";
import FilterMap from "./FilterMap";

const Geo = () =>
{
  const mapData = useSelector((state) => state.mapData);
  const filteredMapData = useSelector((state) => state.filteredMapData);
  const [activeMarker, setActiveMarker] = useState(null);
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [locationDescription, setLocationDescription] = useState("");
  const [directions, setDirections] = useState(null);
  const [mapInstance, setMapInstance] = useState(null);
  const completeData = useSelector((state) => state.completeData);

  const data = filteredMapData.length > 0 ? filteredMapData : mapData;
  const markers = mapData
    ? completeData
      .map((item) =>
      {
        const latitude = parseFloat(item?.latitude);
        const longitude = parseFloat(item?.longitude);

        if (!isNaN(latitude) && !isNaN(longitude))
        {
          return {
            lat: latitude,
            lng: longitude,
            info: `Latitude: ${latitude}, Longitude: ${longitude}`,
            first_cgi: item?.first_cgi,
          };
        } else
        {
          return null;
        }
      })
      .filter((marker) => marker !== null)
    : [];

  const center = markers.reduce(
    (acc, current) =>
    {
      return {
        lat: acc.lat + current.lat,
        lng: acc.lng + current.lng,
      };
    },
    { lat: 0, lng: 0 }
  );

  const [mapCenter, setMapCenter] = useState(center);

  center.lat /= markers.length;
  center.lng /= markers.length;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAfEYRdDPTAw2THWoyCwynliaTtnEMyex8",
  });

  const reverseGeocode = async (lat, lng) =>
  {
    if (window.google && lat && lng)
    {
      const geocoder = new window.google.maps.Geocoder();
      const latLng = new window.google.maps.LatLng(lat, lng);

      try
      {
        const results = await new Promise((resolve, reject) =>
        {
          geocoder.geocode({ location: latLng }, (results, status) =>
          {
            if (status === "OK")
            {
              resolve(results);
            } else
            {
              reject(status);
            }
          });
        });

        if (results && results[0])
        {
          const formattedAddress = results[0].formatted_address;
          setLocationDescription(formattedAddress);
        } else
        {
          setLocationDescription("Location not found");
        }
      } catch (error)
      {
        console.error("Error reverse geocoding:", error);
        setLocationDescription("Error fetching location");
      }
    }
  };

  const getDirections = () =>
  {
    if (navigator.geolocation)
    {
      navigator.geolocation.getCurrentPosition((position) =>
      {
        const { latitude, longitude } = position.coords;
        const directionsService = new window.google.maps.DirectionsService();

        directionsService.route(
          {
            origin: { lat: latitude, lng: longitude },
            destination: { lat: activeMarker.lat, lng: activeMarker.lng },
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (response, status) =>
          {
            if (status === "OK")
            {
              setDirections(response);
              setActiveMarker(null);
              setIsInfoWindowOpen(false);
            } else
            {
              console.error("Error fetching directions:", status);
            }
          }
        );
      });
    } else
    {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  async function getLatLongFromCGI(first_cgi)
  {
    if (!first_cgi)
    {
      return {
        latitude: null,
        longitude: null,
      };
    }

    const cgiParts = first_cgi.split("-");
    if (cgiParts.length !== 4)
    {
      return {
        latitude: null,
        longitude: null,
      };
    }

    const [mcc, mnc, lac, cellId] = cgiParts;
    const carrier = getCarrierFromMCCMNC(mcc, mnc);

    const requestBody = {
      homeMobileCountryCode: parseInt(mcc),
      homeMobileNetworkCode: parseInt(mnc),
      radioType: "gsm",
      carrier: carrier,
      considerIp: false,
      cellTowers: [
        {
          cellId: parseInt(cellId),
          locationAreaCode: parseInt(lac),
          mobileCountryCode: parseInt(mcc),
          mobileNetworkCode: parseInt(mnc),
        },
      ],
    };

    try
    {
      const response = await fetch(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyAQ7aYm4OfWcFNZylvHJmEGMlY5lFcE7pE`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );
      const data = await response.json();
      console.log("latitude & longitude from the CGI------------", data.location.lat, data.location.lng)
      if (data.location)
      {
        return {
          latitude: data.location.lat,
          longitude: data.location.lng,
        };
      } else
      {
        return {
          latitude: null,
          longitude: null,
        };
      }
    } catch (error)
    {
      console.error("Error fetching lat/long from CGI:", error);
      return {
        latitude: null,
        longitude: null,
      };
    }
  }

  function getCarrierFromMCCMNC(mcc, mnc)
  {
    const carriers = {
      404: {
        10: "Airtel",
        45: "Airtel",
        90: "Airtel",
        11: "Vodafone",
        20: "Vodafone",
        22: "Vodafone",
        49: "BSNL",
        38: "BSNL",
        97: "BSNL",
      },
      405: {
        857: "Reliance Jio",
        854: "Reliance Jio",
        855: "Reliance Jio",
      },
    };

    return carriers[mcc]?.[mnc] || "Unknown";
  }

  function drawCircleOnMap(mapInstance, center, radius)
  {
    const circle = new window.google.maps.Circle({
      center: center,
      radius: radius, // Radius in meters
      strokeColor: "#00FF00",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#00FF00",
      fillOpacity: 0.35,
      zIndex: 10000,
    });

    circle.setMap(mapInstance);
  }

  const getAzimuth = async (latitude, longitude, first_cgi) =>
  {
    if (navigator.geolocation)
    {
      const response = await getLatLongFromCGI(first_cgi);

      if (mapInstance)
      {
        const sectorCenter = { lat: latitude, lng: longitude };
        const circleCenter = { lat: response.latitude, lng: response.longitude };
        const circleRadiusInMeters = 500;

        // Calculate the angle of the red line
        const angleRad = Math.atan2(circleCenter.lng - sectorCenter.lng, circleCenter.lat - sectorCenter.lat);
        const angleDeg = (angleRad * 180) / Math.PI;

        // Define the angles for the sector
        const startAngle = angleDeg - 60;
        const endAngle = angleDeg + 60;

        const radius = 0.04; // Approximate radius in degrees for the sector
        const sectorCoords = [sectorCenter];

        // Calculate points along the arc on both sides of the red line
        for (let angle = startAngle; angle <= endAngle; angle += 5)
        {
          const rad = (angle * Math.PI) / 180;
          const point = {
            lat: sectorCenter.lat + radius * Math.cos(rad),
            lng: sectorCenter.lng + radius * Math.sin(rad),
          };
          sectorCoords.push(point);
        }

        sectorCoords.push(sectorCenter); // Close the sector back to the center

        // Draw the sector
        const sector = new window.google.maps.Polygon({
          paths: sectorCoords,
          strokeColor: "#0000FF", // Blue stroke color
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#0000FF", // Blue fill color
          fillOpacity: 0.35,
          zIndex: 10000,
        });

        sector.setMap(mapInstance);

        // Extend the red line to touch the sector's arc
        const extendedLineEnd = {
          lat: sectorCenter.lat + radius * Math.cos(angleRad),
          lng: sectorCenter.lng + radius * Math.sin(angleRad),
        };

        const extendedLine = new window.google.maps.Polyline({
          path: [sectorCenter, extendedLineEnd],
          geodesic: true,
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 2,
          zIndex: 10000,
        });

        extendedLine.setMap(mapInstance);

        // Draw the green circle in the middle of the sector
        drawCircleOnMap(mapInstance, circleCenter, circleRadiusInMeters);
      } else
      {
        console.error("Map instance is not available.");
      }
    } else
    {
      console.error("Geolocation is not supported by this browser.");
    }
  };


  const onMapLoad = (map) =>
  {
    setMapInstance(map);
  };

  useEffect(() =>
  {
    if (isLoaded)
    {
      // setTimeout(() =>
      // {
      //   setPolylineCoords(markers);
      // }, 1000);
    }
  }, [isLoaded, markers]);

  useEffect(() =>
  {
    if (activeMarker)
    {
      reverseGeocode(activeMarker.lat, activeMarker.lng);
    }
  }, [activeMarker]);

  const onMapDragEnd = () =>
  {
    // Update the center state when the user stops dragging the map
    if (mapInstance)
    {
      setMapCenter(mapInstance.getCenter().toJSON());
    }
  };

  return (
    <div className="container map">
      {mapData ? <FilterMap mapData={mapData} /> : ""}
      {mapData &&
        (isLoaded ? (
          <GoogleMap
            zoom={14}
            mapContainerStyle={{ width: "100%", height: "100vh" }}
            options={{
              draggable: true,
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              tilt: 45,
            }}
            center={mapCenter}
            onDragEnd={onMapDragEnd}
            onLoad={onMapLoad} // Set the map instance on load
          >
            {directions
              ? null
              : markers.map((marker, index) =>
              {
                return (
                  <MarkerF
                    key={index}
                    position={marker}
                    title={`Marker ${index + 1}`}
                    label={`${index + 1}`}
                    onClick={() =>
                    {
                      setActiveMarker(marker);
                      setIsInfoWindowOpen(true);
                    }}
                  />
                );
              })}

            {activeMarker && isInfoWindowOpen && (
              <InfoWindowF
                position={activeMarker}
                onCloseClick={() =>
                {
                  setActiveMarker(null);
                  setIsInfoWindowOpen(false);
                  setDirections(null);
                }}
              >
                <div className="info-window-content">
                  <p>
                    Location: {locationDescription} {activeMarker.lat}
                    {activeMarker.lng}
                  </p>
                  <p>First CGI: {activeMarker.first_cgi}</p>
                  <div>
                    <button
                      style={{
                        background: "inherit",
                        border: "none",
                        color: "blue",
                        padding: "0",
                        marginTop: "0",
                        fontWeight: "600",
                      }}
                      onClick={() =>
                        getAzimuth(
                          activeMarker.lat,
                          activeMarker.lng,
                          activeMarker.first_cgi
                        )
                      }
                    >
                      Show Azimuth
                    </button>
                    <button
                      style={{
                        background: "inherit",
                        border: "none",
                        color: "blue",
                        padding: "0",
                        marginTop: "0",
                        fontWeight: "600",
                      }}
                      onClick={getDirections}
                    >
                      Get Directions
                    </button>
                  </div>
                </div>
              </InfoWindowF>
            )}
          </GoogleMap>
        ) : (
          <div style={{ color: "black" }}>Loading...</div>
        ))}

      {!mapData && <Alert message={"First Upload The File in CDR Analysis"} />}
    </div>
  );
};

export default Geo;
