import React, { useState, useEffect, Suspense } from "react";
import "./multiple.css";
import { useDispatch, useSelector } from "react-redux";
import { setCommonData } from "../../store/commonDataSlice";
import Table_1 from "./Table_1";
import Papa from "papaparse";
import { setMultiCompleteDataRedux } from "../../store/multiCompleteDataSlice";
import Table_2 from "./Table_2";
import moment from "moment";
import Table_3 from "./Table_3";
import Table_4 from "./Table_4";
import Table_5 from "./Table_5";
import Table_6 from "./Table_6";
import Table_7 from "./Table_7";

const Main = () => {
  const dispatch = useDispatch();
  const commonData = useSelector((state) => state.commonData);
  const multiCompleteDataRedux = useSelector(
    (state) => state.multiCompleteDataRedux
  );

  const [inputSets, setInputSets] = useState([{ id: 1 }]);
  const [activeButton, setActiveButton] = useState("Table_1");
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [multiCompleteData, setmultiCompleteData] = useState([]);
  const [selectedTable, setSelectedTable] = useState("Table_1");
  const [selectedTableIndex, setSelectedTableIndex] = useState(0);
  const [loading_1, setLoading_1] = useState(false);
  const [intermediateData, setintermediateData] = useState();

  const handleAddMore = () => {
    const newId = inputSets[inputSets.length - 1].id + 1;
    setInputSets([...inputSets, { id: newId }]);
  };

  const handleRemove = (id) => {
    const updatedInputSets = inputSets.filter((set) => set.id !== id);
    setInputSets(updatedInputSets);
    const { [id]: removedData, ...rest } = formData;
    setFormData(rest);
  };

  useEffect(() => {
    if (multiCompleteDataRedux?.length == 1) {
      setSelectedTable("Table_2");
      handleFileIndexClick(0);
      setActiveButton("Table_2");
    }
  }, [multiCompleteDataRedux]);

  // const handleFileInputChange = (id, files) => {
  //   setFormData({
  //     ...formData,
  //     [id]: {
  //       ...formData[id],
  //       files,
  //     },
  //   });
  // };

  const handleChange = (id, fieldName, value) => {
    setFormData({
      ...formData,
      [id]: {
        ...formData[id],
        [fieldName]: value,
      },
    });
  };
  const getLabelForFileInput = (id) => {
    if (formData[id]?.files) {
      return `${formData[id]?.files.length} files selected`;
    } else {
      return `Click to Upload File `;
    }
  };
  // const processFiles = async (files) => {
  //   for (const file of files) {
  //      scanFile(file);
  //   }
  // };

  //1
  const handleFileInputChange = (id, files) => {
    setFormData((prevFormData) => {
      const existingFiles = prevFormData[id]?.files || [];
      const mergedFiles = [...existingFiles, ...files];
      return {
        ...prevFormData,
        [id]: {
          ...prevFormData[id],
          files: mergedFiles,
        },
      };
    });
  };

  useEffect(() => {
    console.log("form data is ", formData);
    setmultiCompleteData([]);

    // Update multiCompleteData based on formData["1"].files
    for (let i = 0; i < formData["1"]?.files?.length; i++) {
      setmultiCompleteData((prevData) => [
        ...prevData,
        {
          name: formData["1"]?.files[i]?.name,
        },
      ]);
    }
  }, [formData]);

  //2
  const handleSubmit = () => {
    const formDataToSend = new FormData();
    for (const id in formData) {
      if (formData[id]?.files) {
        // Iterate over all files in the current input and append them
        for (let file of formData[id].files) {
          formDataToSend.append(`file-${id}`, file);
        }
      }
    }
    const filesArray = [];
    // Now formDataToSend contains all files, so iterate over its entries
    for (const pair of formDataToSend.entries()) {
      const [key, value] = pair;
      if (value instanceof File) {
        filesArray.push(value);
      }
    }
    console.log("array to process ", filesArray);
    processFiles(filesArray);
  };

  //3
  const processFiles = async (files) => {
    setLoading(true);
    const allRecords = [];

    for (const file of files) {
      const records = await scanFile(file);
      allRecords.push(records);
    }
    console.log("all records ", allRecords);
    for (let i = 0; i < multiCompleteData.length; i++) {
      multiCompleteData[i] = {
        ...multiCompleteData[i],
        records: allRecords[i],
      };
    }
    dispatch(setMultiCompleteDataRedux(multiCompleteData));
    console.log("all records  -------", allRecords);
    setintermediateData(allRecords);
    //for finding comman number
    await processAfterScan(allRecords);

    // for finding record based on no of calls
    const analyzedData = await data_analysis_from_db(multiCompleteData); //report based on number of calls
  };

  // Update multiCompleteData based on formData["1"].files

  console.log("multicompleteData", multiCompleteData);
  console.log("multicompleteData redux ", multiCompleteDataRedux);

  //4
  const scanFile = (file) => {
    return new Promise((resolve, reject) => {
      if (!file) {
        console.error("No file selected");
        reject("No file selected");
        return;
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const content = e.target.result;
        const lines = content?.split(/\r\n|\n|\r/);
        let startIndex = 0;

        for (let i = 0; i < lines.length; i++) {
          if (
            lines[i].includes("Target No") ||
            lines[i].includes("Calling Party Telephone Number") ||
            lines[i].includes("Target /A PARTY NUMBER") ||
            lines[i].includes("Calling No") ||
            lines[i].includes("A PARTY NUMBER") ||
            lines[i].includes("Calling (A) Party Telephone Number / MSISDN")
          ) {
            startIndex = i;
            break;
          }
        }

        const csvToParse = lines.slice(startIndex).join("\n");

        Papa.parse(csvToParse, {
          header: true,
          complete: async (results) => {
            const records = [];
            results.data.forEach((data) => {
              //csv2
              if (data["B Party No"]) {
                const dateStr = data["Date"];
                const cleanedDateStr = dateStr.replace(/'/g, "");
                const [day, month, year] = cleanedDateStr?.split("/");
                const new_date = `${year}-${month}-${day} ${data["Time"]}`;
                const lat_long = data["Last CGI Lat/Long"]
                  ? data["Last CGI Lat/Long"]?.split("/")
                  : [null, null];
                records.push({
                  target_number: data["Target No"],
                  calledPhoneNumber: data["B Party No"],
                  callDateTime: new_date,
                  callTime: data["Time"],
                  typeOfCall: data["Call Type"],
                  duration: data["Dur(s)"],
                  imei: data["IMEI"],
                  towerAddress: data[""],
                  latitude: data["Call Type"] === "SMT" ? null : lat_long[0],
                  longitude: data["Call Type"] === "SMT" ? null : lat_long[1],
                  imsi: data["IMSI"],
                });
              }

              if (data["Calling Party Telephone Number"]) {
                console.log(data["Called Party Telephone Number"]);
                if (
                  data["Called Party Telephone Number"] &&
                  data["Called Party Telephone Number"].trim() !== ""
                ) {
                  let dateString = data["Call Date"].replace(/-/g, "/");
                  const [day, month, year] = dateString?.split("/");
                  let new_date = `${year}/${month}/${day} ${data["Call Time"]}`;
                  let target_number;
                  if (
                    data["Call Type"] === "A2P_SMSIN" ||
                    data["Call Type"] === "a_in"
                  ) {
                    target_number = data[
                      "Called Party Telephone Number"
                    ].replace(/'/g, "");
                  } else {
                    target_number = data[
                      "Calling Party Telephone Number"
                    ].replace(/'/g, "");
                  }

                  records.push({
                    target_number: target_number,
                    calledPhoneNumber:
                      target_number ===
                      data["Called Party Telephone Number"].replace(/'/g, "")
                        ? data["Calling Party Telephone Number"].replace(
                            /'/g,
                            ""
                          ).length > 10
                          ? data["Calling Party Telephone Number"]
                              .replace(/'/g, "")
                              .slice(-10)
                          : data["Calling Party Telephone Number"].replace(
                              /'/g,
                              ""
                            )
                        : data["Called Party Telephone Number"].replace(
                            /'/g,
                            ""
                          ).length > 10
                        ? data["Called Party Telephone Number"]
                            .replace(/'/g, "")
                            .slice(-10)
                        : data["Called Party Telephone Number"].replace(
                            /'/g,
                            ""
                          ),

                    callDateTime: new_date,
                    callTime: data["Call Time"],
                    typeOfCall: data["Call Type"],
                    duration: data["Call Duration"],
                    imei: data["IMEI"],
                    imsi: data["IMSI"],
                    cell_id: data["First Cell ID"],
                  });
                }
              }
              // vodaidea
              if (
                data["Target /A PARTY NUMBER"] &&
                data["CALL_TYPE"] &&
                data["CALL_TYPE"] !== ""
              ) {
                const [day, month, year] = data["Call date"]?.split("-");
                let new_date = `${year}-${month}-${day}`;
                records.push({
                  target_number: data["Target /A PARTY NUMBER"],
                  calledPhoneNumber: data["B PARTY NUMBER"],
                  callDateTime: new_date,
                  callTime: data["Call Initiation Time"],
                  typeOfCall: data["CALL_TYPE"],
                  duration: data["Call Duration"],
                  imei: data["IMEI"],
                  towerAddress: data["First BTS Location"],
                  imsi: data["IMSI"],
                  cell_id: data["Last Cell Global Id"],
                });
              }
              // idea csv182
              if (
                data["Called (B) Party Telephone Number / Access Point Name"]
              ) {
                const [day, month, year] = data["Date"]?.split("-");
                let new_date = `${year}-${month}-${day}`;
                let ip = null;
                if (data["Call Type"]?.replace(/\u0000/g, "") === "GPD") {
                  ip = data["Last Cell ID / PDP Address"];
                }
                // console.log("ip ", ip)
                records.push({
                  ipAddress: ip,
                  target_number:
                    data["Call Type"]?.replace(/\u0000/g, "") === "MOC" ||
                    data["Call Type"]?.replace(/\u0000/g, "") === "GPD"
                      ? data[
                          "Calling (A) Party Telephone Number / MSISDN"
                        ]?.replace(/\u0000/g, "")
                      : data[
                          "Called (B) Party Telephone Number / Access Point Name"
                        ]?.replace(/\u0000/g, ""),
                  calledPhoneNumber:
                    data["Call Type"]?.replace(/\u0000/g, "") === "MOC" ||
                    data["Call Type"]?.replace(/\u0000/g, "") === "GPD"
                      ? data[
                          "Called (B) Party Telephone Number / Access Point Name"
                        ]?.replace(/\u0000/g, "")
                      : data[
                          "Calling (A) Party Telephone Number / MSISDN"
                        ]?.replace(/\u0000/g, ""),
                  smsc: data[
                    "SMS Centre Number / GGSN Address/ SGSN Address"
                  ]?.replace(/\u0000/g, ""),
                  roam_nw: data[
                    "First Roaming Network Cell ID/PLMN ID"
                  ]?.replace(/\u0000/g, ""),
                  typeOfCall: data["Call Type"]?.replace(/\u0000/g, ""),
                  callDateTime: new_date,
                  callTime: data["Time"]?.replace(/\u0000/g, ""),
                  duration: data["Duration In Secs"]?.replace(/\u0000/g, ""),
                  imei: data["IMEI"]?.replace(/\u0000/g, ""),
                  imsi: data["IMSI"]?.replace(/\u0000/g, ""),
                });
              }
              // vodafone csv183
              if (data[" B PARTY NUMBER"]) {
                records.push({
                  target_number:
                    data[" CALL_TYPE"]?.replace(/\u0000/g, "") === " SMS_MOC"
                      ? data[`A PARTY NUMBER`]?.replace(/\u0000/g, "")
                      : data[" B PARTY NUMBER"]?.replace(/\u0000/g, ""),
                  calledPhoneNumber:
                    data[" CALL_TYPE"]?.replace(/\u0000/g, "") === " SMS_MOC"
                      ? data[" B PARTY NUMBER"]?.replace(/\u0000/g, "").length >
                        10
                        ? data[" B PARTY NUMBER"]
                            ?.replace(/\u0000/g, "")
                            .slice(-10)
                        : data[" B PARTY NUMBER"]?.replace(/\u0000/g, "")
                      : data[`A PARTY NUMBER`]?.replace(/\u0000/g, "").length >
                        10
                      ? data[`A PARTY NUMBER`]
                          ?.replace(/\u0000/g, "")
                          .slice(-10)
                      : data[`A PARTY NUMBER`]?.replace(/\u0000/g, ""),

                  smsc: data["SMSC"]?.replace(/\u0000/g, ""),
                  roam_nw: data["Roam Nw"]?.replace(/\u0000/g, ""),
                  typeOfCall: data[" CALL_TYPE"]?.replace(/\u0000/g, ""),
                  callDateTime: data[" CALL_DATE"]?.replace(/\u0000/g, ""),
                  callTime: data[" CALL_TIME"]?.replace(/\u0000/g, ""),
                  duration: data[" DURATION"]?.replace(/\u0000/g, ""),
                  imei: data[" IMEI"]?.replace(/\u0000/g, ""),
                  imsi: data[" IMSI"]?.replace(/\u0000/g, ""),
                });
              }

              // csv181 airtel
              if (data["Calling No"]) {
                records.push({
                  target_number:
                    data["Call Type"] === "'OUT'"
                      ? data["Calling No"]?.replace(/'/g, "")
                      : data["Called No"]?.replace(/'/g, ""),
                  calledPhoneNumber:
                    data["Call Type"] === "'OUT'"
                      ? data["Called No"]?.replace(/'/g, "")
                      : data["Calling No"]?.replace(/'/g, ""),
                  cell1: data["Cell1"]?.replace(/'/g, ""),
                  cell2: data["Cell2"]?.replace(/'/g, ""),
                  smsc: data["SMSC"]?.replace(/'/g, ""),
                  roam_nw: data["Roam Nw"]?.replace(/'/g, ""),
                  typeOfCall: data["Call Type"]?.replace(/'/g, ""),
                  callDateTime: data["Date"]?.replace(/'/g, ""),
                  callTime: data["Time"]?.replace(/'/g, ""),
                  duration: data["Dur(s)"]?.replace(/'/g, ""),
                  imei: data["IMEI"]?.replace(/'/g, ""),
                  imsi: data["IMSI No"]?.replace(/'/g, ""),
                });
              }
            });
            resolve(records);
          },
          error: (error) => {
            console.error("Error parsing CSV:", error);
            setLoading(false);
            reject(error);
          },
        });
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        setLoading(false);
        reject(error);
      };

      reader.readAsText(file);
    });
  };
  //5
  const processAfterScan = async (records) => {
    const result = await find_comman_number(records);
    setLoading(false);
    if (result.length === 0) {
      dispatch(setCommonData(null));
    } else {
      dispatch(setCommonData(result.commanData));
    }
    console.log("result are ", result);
  };
  //6
  async function find_comman_number(records) {
    try {
      let r = [];
      for (const [key, value] of Object.entries(records)) {
        r.push(value);
      }
      let result = await findMatchingElements(...r);
      return result;
    } catch (error) {
      throw error;
    }
  }
  //7
  async function findMatchingElements(...arrays) {
    console.log(...arrays);
    try {
      const commonData = [];
      const commonNumbers = new Set();

      const [firstArray, ...restArrays] = arrays;

      for (let el of firstArray) {
        const phoneNumber = el.calledPhoneNumber;

        if (
          restArrays.every((arr) =>
            arr.some((item) => item.calledPhoneNumber === phoneNumber)
          )
        ) {
          commonNumbers.add(phoneNumber);
        }
      }

      for (let phoneNumber of commonNumbers) {
        const targetNumbers = [
          {
            number: firstArray.find(
              (item) => item.calledPhoneNumber === phoneNumber
            ).target_number,
            callDetails: firstArray.filter(
              (item) => item.calledPhoneNumber === phoneNumber
            ),
          },
        ];

        for (let array of restArrays) {
          const targetDetail = array.find(
            (item) => item.calledPhoneNumber === phoneNumber
          );
          if (targetDetail) {
            targetNumbers.push({
              number: targetDetail.target_number,
              callDetails: array.filter(
                (item) => item.calledPhoneNumber === phoneNumber
              ),
            });
          }
        }

        commonData.push({
          commanNumber: phoneNumber,
          targetNumber: targetNumbers,
        });
      }

      return {
        number: Array.from(commonNumbers),
        commanData: commonData,
      };
    } catch (error) {
      throw error;
    }
  }

  const handleTableButtonClick = async (tableName) => {
    if (tableName !== selectedTable) {
      setLoading(true);
      await new Promise((resolve) => {
        setTimeout(() => {
          setLoading(false);
          setSelectedTable(tableName);
          resolve();
        }, 1200);
      });
      setActiveButton(tableName);
    }
  };

  const handleFileIndexClick = (index) => {
    setSelectedTableIndex(index);
  };

  async function data_analysis_from_db(dataArray) {
    // console.log("recored based on no of calls ", dataArray);
    try {
      let i = 0;
      let a_data = [];

      for (let data of dataArray) {
        let records = data.records;
        console.log("records  ==========", records);

        for (let element of records) {
          if (
            element.typeOfCall === "a_in" ||
            element.typeOfCall === "a_out" ||
            element.typeOfCall === "A2P_SMSIN" ||
            element.typeOfCall === "P2P_SMSIN"
          ) {
            let index = a_data.findIndex((el) => {
              return (
                el.b_party_number.toString() ===
                element.calledPhoneNumber.toString()
              );
            });
            if (index === -1) {
              a_data.push({
                b_party_number: element.calledPhoneNumber,
                total_calls: 1,
                call_type_in: element.typeOfCall === "a_in" ? 1 : 0,
                call_type_out: element.typeOfCall === "a_out" ? 1 : 0,
                last_call_date: element.callDateTime,
                first_call_date: element.callDateTime,
                call_duration: parseInt(element.duration),
                sms:
                  element.typeOfCall === "A2P_SMSIN" ||
                  element.typeOfCall === "P2P_SMSIN"
                    ? 1
                    : 0,
              });
            } else {
              a_data[index].total_calls += 1;
              a_data[index].call_type_in +=
                element.typeOfCall === "a_in" ? 1 : 0;
              a_data[index].call_type_out +=
                element.typeOfCall === "a_out" ? 1 : 0;
              a_data[index].sms +=
                element.typeOfCall === "A2P_SMSIN" ||
                element.typeOfCall === "P2P_SMSIN"
                  ? 1
                  : 0;
              if (element.callDateTime > a_data[index].last_call_date) {
                a_data[index].last_call_date = element.callDateTime;
              }
              if (element.callDateTime < a_data[index].first_call_date) {
                a_data[index].first_call_date = element.callDateTime;
              }
              a_data[index].call_duration += parseInt(element.duration);
            }
            continue;
          }
          if (
            element.typeOfCall === "IN" ||
            element.typeOfCall === "OUT" ||
            element.typeOfCall === "SMT" ||
            element.typeOfCall === "MTC" ||
            element.typeOfCall === "MOC"
          ) {
            let index = a_data.findIndex((el) => {
              return (
                el.b_party_number.toString() ===
                element.calledPhoneNumber.toString()
              );
            });
            if (index === -1) {
              a_data.push({
                b_party_number: element.calledPhoneNumber,
                total_calls: 1,
                call_type_in: element.typeOfCall === "IN" ? 1 : 0,
                call_type_out: element.typeOfCall === "OUT" ? 1 : 0,
                last_call_date: element.callDateTime,
                first_call_date: element.callDateTime,
                call_duration: parseInt(element.duration),
                sms: element.typeOfCall === "SMT" ? 1 : 0,
              });
            } else {
              a_data[index].total_calls += 1;
              a_data[index].call_type_in += element.typeOfCall === "IN" ? 1 : 0;
              a_data[index].call_type_out +=
                element.typeOfCall === "OUT" ? 1 : 0;
              a_data[index].sms += element.typeOfCall === "SMT" ? 1 : 0;
              if (element.callDateTime > a_data[index].last_call_date) {
                a_data[index].last_call_date = element.callDateTime;
              }
              if (element.callDateTime < a_data[index].first_call_date) {
                a_data[index].first_call_date = element.callDateTime;
              }
              a_data[index].call_duration += parseInt(element.duration);
            }
            continue;
          }

          // for vodafone csv183
          if (
            element.typeOfCall === " INC" ||
            element.typeOfCall === " OUT" ||
            element.typeOfCall === " SMS_INC" ||
            element.typeOfCall === " SMS_MOC"
          ) {
            let index = a_data.findIndex((el) => {
              return (
                el.b_party_number.toString() ===
                element.calledPhoneNumber.toString()
              );
            });
            if (index === -1) {
              a_data.push({
                b_party_number: element.calledPhoneNumber,
                total_calls: 1,
                call_type_in: element.typeOfCall === " INC" ? 1 : 0,
                call_type_out: element.typeOfCall === " OUT" ? 1 : 0,
                last_call_date: element.callDateTime,
                first_call_date: element.callDateTime,
                call_duration: parseInt(element.duration),
                sms: element.typeOfCall === (" SMS_INC" || " SMS_MOC") ? 1 : 0,
              });
            } else {
              a_data[index].total_calls += 1;
              a_data[index].call_type_in +=
                element.typeOfCall === " INC" ? 1 : 0;
              a_data[index].call_type_out +=
                element.typeOfCall === " OUT" ? 1 : 0;
              a_data[index].sms +=
                element.typeOfCall === (" SMS_INC" || " SMS_MOC") ? 1 : 0;
              if (element.callDateTime > a_data[index].last_call_date) {
                a_data[index].last_call_date = element.callDateTime;
              }
              if (element.callDateTime < a_data[index].first_call_date) {
                a_data[index].first_call_date = element.callDateTime;
              }
              a_data[index].call_duration += parseInt(element.duration);
            }
            continue;
          }
          //idea csv182
          if (
            element.typeOfCall === "GPD" ||
            element.typeOfCall === "SMT" ||
            element.typeOfCall === "MTC" ||
            element.typeOfCall === "MOC"
          ) {
            let index = a_data.findIndex((el) => {
              return (
                el.b_party_number.toString() ===
                element.calledPhoneNumber.toString()
              );
            });
            if (index === -1) {
              a_data.push({
                b_party_number: element.calledPhoneNumber,
                total_calls: 1,
                call_type_in: element.typeOfCall === "MTC" ? 1 : 0,
                call_type_out: element.typeOfCall === "MOC" ? 1 : 0,
                last_call_date: element.callDateTime,
                first_call_date: element.callDateTime,
                call_duration: parseInt(element.duration),
                sms: element.typeOfCall === "SMT" ? 1 : 0,
              });
            } else {
              a_data[index].total_calls += 1;
              a_data[index].call_type_in +=
                element.typeOfCall === "MTC" ? 1 : 0;
              a_data[index].call_type_out +=
                element.typeOfCall === "MOC" ? 1 : 0;
              a_data[index].sms += element.typeOfCall === "SMT" ? 1 : 0;

              if (element.callDateTime > a_data[index].last_call_date) {
                a_data[index].last_call_date = element.callDateTime;
              }
              if (element.callDateTime < a_data[index].first_call_date) {
                a_data[index].first_call_date = element.callDateTime;
              }
              a_data[index].call_duration += parseInt(element.duration);
            }
            continue;
          }

          // vodaidea
          if (
            element.typeOfCall === "Incoming" ||
            element.typeOfCall === "Outgoing"
          ) {
            let index = a_data.findIndex((el) => {
              return (
                el.b_party_number.toString() ===
                element.calledPhoneNumber.toString()
              );
            });
            if (index === -1) {
              a_data.push({
                b_party_number: element.calledPhoneNumber,
                total_calls: 1,
                call_type_in: element.typeOfCall === "Incoming" ? 1 : 0,
                call_type_out: element.typeOfCall === "Outgoing" ? 1 : 0,
                last_call_date: element.callDateTime,
                first_call_date: element.callDateTime,
                call_duration: parseInt(element.duration),
                sms:
                  element.typeOfCall === "Incoming" &&
                  element.service_Type === "SMS"
                    ? 1
                    : 0,
              });
            } else {
              a_data[index].total_calls += 1;
              a_data[index].call_type_in +=
                element.typeOfCall === "Incoming" ? 1 : 0;
              a_data[index].call_type_out +=
                element.typeOfCall === "Outgoing" ? 1 : 0;
              a_data[index].sms +=
                element.typeOfCall === "Incoming" &&
                element.service_Type === "SMS"
                  ? 1
                  : 0;

              if (element.callDateTime > a_data[index].last_call_date) {
                a_data[index].last_call_date = element.callDateTime;
              }
              if (element.callDateTime < a_data[index].first_call_date) {
                a_data[index].first_call_date = element.callDateTime;
              }
              a_data[index].call_duration += parseInt(element.duration);
            }
            continue;
          }
        }

        multiCompleteData[`${i}`] = {
          ...multiCompleteData[`${i}`],
          record_based_on_no_of_call: a_data,
        };
        console.log("a_data ----------", i, a_data);
        i++;
        a_data = [];
      }

      return a_data;
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    if (
      multiCompleteDataRedux &&
      multiCompleteDataRedux[selectedTableIndex]?.record_based_on_no_of_call ==
        null
    ) {
      fetchLocationDetails();
    }
  }, [intermediateData]);

  const fetchLocationDetails = async () => {
    console.log(
      "intermidiate data is this -----------",
      intermediateData?.length
    );
    setLoading_1(true);

    let array_of_map = [];

    for (let i = 0; i < intermediateData?.length; i++) {
      const uniqueCoordinates = new Set(); // Move outside of the loop
      const coordinatesToFetch = [];
      // Filter for unique latitude and longitude combinations
      intermediateData[i]?.forEach((item) => {
        const { latitude, longitude } = item;
        const coordinateKey = `${latitude},${longitude}`;
        if (!uniqueCoordinates.has(coordinateKey) && latitude && longitude) {
          uniqueCoordinates.add(coordinateKey);
          coordinatesToFetch.push({ latitude, longitude });
        }
      });
      console.log("coordinate array ", i, coordinatesToFetch);
      // console.log("intermediate data ", item);

      const addressMap = new Map(); // Use a Map to store latitude, longitude as key and address as value

      for (let j = 0; j < coordinatesToFetch.length; j++) {
        const { latitude, longitude } = coordinatesToFetch[j];
        const coordinateKey = `${latitude},${longitude}`;

        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAfEYRdDPTAw2THWoyCwynliaTtnEMyex8`
          );
          const data = await response.json();

          if (data.status === "OK") {
            const address = data.results[0]?.formatted_address;
            addressMap.set(coordinateKey, address);
          } else {
            console.error(
              "Geocode error for",
              coordinateKey,
              ":",
              data.error_message
            );
            addressMap.set(coordinateKey, "Address not found");
          }
        } catch (error) {
          console.error("Fetch failed for", coordinateKey, ":", error);
          addressMap.set(coordinateKey, "Error fetching address");
        }
      }

      console.log("Address map ", i, addressMap);
      array_of_map.push(addressMap);
    }

    console.log("array of maps", array_of_map);
    completeDataMergeAddress(array_of_map);

    const locationData = data_according_to_location();
    console.log("location data output ", locationData);

    //cdr based on call duration
    const sortedData = sortByCallDuration();
    console.log("record based on call duration ", sortedData);
    const smsSortedData = sortBySMS();
    console.log("record based on sms ", smsSortedData);
    const imeiData = data_according_to_imei();
    console.log("record based on imei ", imeiData);
    for (let i = 0; i < multiCompleteDataRedux?.length; i++) {
      multiCompleteDataRedux[i] = {
        ...multiCompleteData[i],
        record_based_on_call_duration: sortedData[i],
        record_based_on_sms: smsSortedData[i],
        record_based_on_imei_data: imeiData[i],
        record_based_on_location_data: locationData[i],
      };
    }
  };

  function data_according_to_location() {
    try {
      const outputarr = [];
      for (let i = 0; i < multiCompleteDataRedux?.length; i++) {
        let data = [];
        const dataArray = multiCompleteDataRedux[i].records;
        // Loop through each row (records) of the matrix

        for (let record of dataArray) {
          if (record.typeOfCall === "SMT") {
            continue; // Skip SMS Text messages for location data
          }
          let index = data.findIndex(
            (el) =>
              el.location.latitude === record.latitude &&
              el.location.longitude === record.longitude
          );
          if (index === -1) {
            data.push({
              location: {
                latitude: record.latitude,
                longitude: record.longitude,
              },
              call_type_in: record.typeOfCall === "IN" ? 1 : 0,
              call_type_out: record.typeOfCall === "OUT" ? 1 : 0,
              total_number_of_call: 1,
              details: [
                {
                  calledPhoneNumber: record.calledPhoneNumber,
                  callDate: moment(record.callDateTime).format("DD/MM/YYYY"),
                  callTime: record.callTime,
                  callType: record.typeOfCall,
                },
              ],
            });
          } else {
            data[index].call_type_in += record.typeOfCall === "IN" ? 1 : 0;
            data[index].call_type_out += record.typeOfCall === "OUT" ? 1 : 0;
            data[index].total_number_of_call += 1;
            data[index].details.push({
              calledPhoneNumber: record.calledPhoneNumber,
              callDate: moment(record.callDateTime).format("DD/MM/YYYY"),
              callTime: record.callTime,
              callType: record.typeOfCall,
            });
          }
        }

        data?.forEach((item) => {
          item?.details?.sort((a, b) => {
            const dateA = moment(a?.callDate, "DD/MM/YYYY");
            const dateB = moment(b?.callDate, "DD/MM/YYYY");
            if (dateA?.isSame(dateB)) {
              return a?.callTime?.localeCompare(b?.callTime);
            }
            return dateA?.isBefore(dateB) ? -1 : 1;
          });
        });

        data.sort((a, b) => b?.total_number_of_call - a?.total_number_of_call);

        outputarr.push(data);
      }

      // console.log("location data output ", outputarr);
      return outputarr;
    } catch (error) {
      console.error("Error in data_according_to_location:", error);
      throw error;
    }
  }

  function data_according_to_imei() {
    const arr = [];

    for (let i = 0; i < multiCompleteDataRedux?.length; i++) {
      const uniqueIMEIs = new Set();

      // Filter the array
      const filteredData = multiCompleteDataRedux[i]?.records?.filter(
        (item) => {
          const imei = item?.imei?.trim(); // Trim the IMEI to remove any extra whitespace
          if (!uniqueIMEIs?.has(imei)) {
            uniqueIMEIs?.add(imei);
            return true; // Keep this item because its IMEI hasn't been seen yet
          }
          // Exclude this item because its IMEI is already in the set
          return false;
        }
      );
      arr?.push(filteredData);
    }
    console.log("----------------------IMEI ARRAY------------------", arr);
    return arr;
  }

  function sortByCallDuration() {
    const outputarr = [];
    for (let i = 0; i < multiCompleteDataRedux?.length; i++) {
      const sortedTableData = [
        ...multiCompleteDataRedux[i]?.record_based_on_no_of_call,
      ].sort((a, b) => b.call_duration - a.call_duration);
      // Make a shallow copy of the analyzedData array to avoid mutating the original array
      outputarr.push(sortedTableData);
    }
    console.log("cdr sorted by call duration ", outputarr);

    return outputarr;
  }

  function sortBySMS() {
    const outputarr = [];
    for (let i = 0; i < multiCompleteDataRedux?.length; i++) {
      const smsData = multiCompleteDataRedux[
        i
      ]?.record_based_on_no_of_call.filter((item) => item.sms > 0);
      smsData.sort((a, b) => b.sms - a.sms);

      outputarr.push(smsData);
    }
    console.log("cdr sorted by sms", outputarr);
    return outputarr;
  }

  const completeDataMergeAddress = (array_of_map) => {
    for (let i = 0; i < multiCompleteDataRedux?.length; i++) {
      const entry = multiCompleteDataRedux[i];
      const updatedRecords = [];
      for (let j = 0; j < entry?.records?.length; j++) {
        const item = entry?.records[j];
        const { latitude, longitude } = item;
        const coordinateKey = `${latitude},${longitude}`;
        // Access the map at the i'th index of array_of_map
        const mapAtIndex = array_of_map[i];
        // Find the item with the specific key
        const matchingItem = mapAtIndex?.get(coordinateKey);
        // Retrieve the address from the matching item or set a default value
        const address = matchingItem ? matchingItem : "Address not available";
        updatedRecords.push({ ...item, address }); // Push a new object with the address included
      }
      // Update the entry with the updated records
      console.log("updated records ", updatedRecords);

      multiCompleteDataRedux[i] = {
        ...multiCompleteData[i],
        records: updatedRecords,
      };
    }

    console.log(
      "Updated multi complete data with address ",
      multiCompleteDataRedux
    );

    setLoading_1(false);
  };

  return (
    <>
      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "99999999",
          }}
        >
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">loading...</div>
        </div>
      )}
      {!commonData && (
        <div className="res3 row">
          {inputSets.map((inputSet) => (
            <div key={inputSet.id} className="row mb-3">
              <div
                className="col-sm-11  mx-auto p-4"
                style={{
                  borderRadius: "20px",
                  background:
                    "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)",
                }}
              >
                <label
                  htmlFor={`fileInput-${inputSet.id}`}
                  className="custom-label-name"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "185px",
                  }}
                >
                  <div style={{ marginBottom: "-20px" }}>
                    <svg
                      width="100"
                      height="100"
                      viewBox="0 0 100 100"
                      xmlns="http://www.w3.org/2000/svg"
                      className="circle-icon"
                    >
                      <circle
                        cx="50"
                        cy="50"
                        r="40"
                        stroke="rgba(142, 165, 201, 0.15)"
                        strokeWidth="3"
                        fill="transparent"
                      />
                    </svg>
                    <i
                      className="fa-solid fa-file-export fs-4"
                      style={{
                        position: "relative",
                        right: "58px",
                        top: "6px",
                      }}
                    ></i>
                  </div>
                  {getLabelForFileInput(inputSet.id)}
                </label>
                <input
                  type="file"
                  id={`fileInput-${inputSet.id}`}
                  name={`fileInput-${inputSet.id}`}
                  className="custom-input-name"
                  onChange={(e) =>
                    handleFileInputChange(inputSet.id, e.target.files)
                  }
                  multiple // Add the multiple attribute
                />
              </div>
            </div>
          ))}
          <div className=" d-flex flex-row justify-content-end px-4">
            <div
              style={{ fontSize: "14px" }}
              className={`btn mt-3 mb-5 text-white btn-primary ${
                loading ? "disabled" : ""
              }`}
              onClick={handleSubmit}
            >
              {loading ? "Analysing..." : "Analyse"}
            </div>
          </div>
        </div>
      )}
      {commonData && (
        <div
          class="res3  d-flex flex-row mb-4 pb-3  "
          style={{ marginBottom: "40px", overflowX: "scroll" }}
        >
          {commonData && multiCompleteDataRedux.length != 1 && (
            <div
              className={` btn me-2 px-4 py-3 text-white   ${
                activeButton === "Table_1" ? "activeButton" : ""
              }`}
              style={{
                background: "#0B0F2A",
                fontWeight: "500",
                fontSize: "15px",
                marginLeft: "30px",
                minWidth: "170px",
              }}
              onClick={() => {
                console.log("clicked", selectedTable);
                handleTableButtonClick("Table_1");
              }}
            >
              Common Number
            </div>
          )}

          <div className="d-flex flex-row ">
            {multiCompleteDataRedux &&
              multiCompleteDataRedux?.map((data, index) => {
                console.log(
                  "selected table index ",
                  selectedTable,
                  activeButton
                );
                return (
                  <div
                    className={` btn me-2 px-4 py-3  text-white mt-0 ${
                      activeButton !== "Table_1" &&
                      activeButton[activeButton.length - 1] === `${index}`
                        ? "activeButton"
                        : ""
                    }`}
                    style={{
                      background: "#0B0F2A",
                      fontWeight: "500",
                      fontSize: "15px",
                      marginLeft: "30px",
                      minWidth: "170px",
                    }}
                    key={index}
                    onClick={() => {
                      handleTableButtonClick(`Table_2 ${index}`);
                      handleFileIndexClick(index);
                    }}
                  >
                    {data.name}
                  </div>
                );
              })}
          </div>
        </div>
      )}

      {activeButton != "Table_1" && (
        <div
          class="res3  d-flex flex-row mb-4 pb-3  "
          style={{ marginBottom: "40px", overflowX: "scroll" }}
        >
          <div className="d-flex flex-row ">
            {multiCompleteDataRedux &&
              multiCompleteDataRedux[selectedTableIndex]?.records && (
                <div
                  className={` btn me-2 px-4 py-3 text-white   ${
                    selectedTable.slice(0, 7) === `Table_2`
                      ? "activeButton"
                      : ""
                  }`}
                  style={{
                    background: "#0B0F2A",
                    fontWeight: "500",
                    fontSize: "15px",
                    marginLeft: "30px",
                    minWidth: "170px",
                  }}
                  onClick={() => {
                    console.log("clicked", selectedTable);
                    handleTableButtonClick(`Table_2 ${selectedTableIndex}`);
                  }}
                >
                  Complete Data
                </div>
              )}
            {multiCompleteDataRedux &&
              multiCompleteDataRedux[selectedTableIndex]
                ?.record_based_on_no_of_call && (
                <div
                  className={` btn me-2 px-4 py-3 text-white   ${
                    selectedTable.slice(0, 7) === `Table_3`
                      ? "activeButton"
                      : ""
                  }  `}
                  style={{
                    background: "#0B0F2A",
                    fontWeight: "500",
                    fontSize: "15px",
                    marginLeft: "30px",
                    minWidth: "170px",
                  }}
                  onClick={() => {
                    console.log("clicked", selectedTableIndex);
                    handleTableButtonClick(`Table_3 ${selectedTableIndex}`);
                  }}
                >
                  Report Based on No of Calls
                </div>
              )}
            {multiCompleteDataRedux &&
              multiCompleteDataRedux[selectedTableIndex]
                ?.record_based_on_no_of_call &&
              multiCompleteDataRedux[selectedTableIndex]
                ?.record_based_on_call_duration && (
                <div
                  className={` btn me-2 px-4 py-3 text-white   ${
                    selectedTable.slice(0, 7) === `Table_4`
                      ? "activeButton"
                      : ""
                  }  `}
                  style={{
                    background: "#0B0F2A",
                    fontWeight: "500",
                    fontSize: "15px",
                    marginLeft: "30px",
                    minWidth: "170px",
                  }}
                  onClick={() => {
                    console.log("clicked", selectedTableIndex);
                    handleTableButtonClick(`Table_4 ${selectedTableIndex}`);
                  }}
                >
                  Report Based on Call Duration
                </div>
              )}

            {multiCompleteDataRedux &&
              multiCompleteDataRedux[selectedTableIndex]
                ?.record_based_on_location_data && (
                <div
                  className={` btn me-2 px-4 py-3 text-white   ${
                    selectedTable.slice(0, 7) === `Table_5`
                      ? "activeButton"
                      : ""
                  }  `}
                  style={{
                    background: "#0B0F2A",
                    fontWeight: "500",
                    fontSize: "15px",
                    marginLeft: "30px",
                    minWidth: "170px",
                  }}
                  onClick={() => {
                    console.log("clicked", selectedTableIndex);
                    handleTableButtonClick(`Table_5 ${selectedTableIndex}`);
                  }}
                >
                  Report Based on Call location
                </div>
              )}

            {multiCompleteDataRedux &&
              multiCompleteDataRedux[selectedTableIndex]
                ?.record_based_on_no_of_call &&
              multiCompleteDataRedux[selectedTableIndex]
                ?.record_based_on_sms && (
                <div
                  className={` btn me-2 px-4 py-3 text-white   ${
                    selectedTable.slice(0, 7) === `Table_6`
                      ? "activeButton"
                      : ""
                  }  `}
                  style={{
                    background: "#0B0F2A",
                    fontWeight: "500",
                    fontSize: "15px",
                    marginLeft: "30px",
                    minWidth: "170px",
                  }}
                  onClick={() => {
                    console.log("clicked", selectedTableIndex);
                    handleTableButtonClick(`Table_6 ${selectedTableIndex}`);
                  }}
                >
                  Report Based on SMS
                </div>
              )}
            {multiCompleteDataRedux &&
              multiCompleteDataRedux[selectedTableIndex]
                ?.record_based_on_imei_data && (
                <div
                  className={` btn me-2 px-4 py-3 text-white   ${
                    selectedTable.slice(0, 7) === `Table_7`
                      ? "activeButton"
                      : ""
                  }  `}
                  style={{
                    background: "#0B0F2A",
                    fontWeight: "500",
                    fontSize: "15px",
                    marginLeft: "30px",
                    minWidth: "170px",
                  }}
                  onClick={() => {
                    console.log("clicked", selectedTableIndex);
                    handleTableButtonClick(`Table_7 ${selectedTableIndex}`);
                  }}
                >
                  Report Based on IMEI Data
                </div>
              )}
          </div>
        </div>
      )}

      <Suspense
        fallback={
          <div
            className="loading-backdrop"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
              background: "rgba(0, 0, 0, 0.6)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 999,
            }}
          >
            <div className="spinner-border text-light" role="status">
              <div className="visually-hidden">Loading...</div>
            </div>
            <div className="text-white">loading...</div>
          </div>
        }
      >
        {(!loading || selectedTable !== null) && (
          <div>
            {(selectedTable === null || selectedTable === "Table_1") &&
              commonData && <Table_1 commonData={commonData} />}

            {selectedTable.slice(0, 7) === `Table_2` &&
              multiCompleteDataRedux &&
              multiCompleteDataRedux?.length > 0 && (
                // eslint-disable-next-line react/jsx-pascal-case
                <Table_2 index={selectedTableIndex} />
              )}
            {selectedTable.slice(0, 7) === `Table_3` &&
              multiCompleteDataRedux &&
              multiCompleteDataRedux?.length > 0 && (
                <Table_3 index={selectedTableIndex} />
              )}
            {selectedTable.slice(0, 7) === `Table_4` &&
              multiCompleteDataRedux &&
              multiCompleteDataRedux?.length > 0 && (
                <Table_4 index={selectedTableIndex} />
              )}
            {selectedTable.slice(0, 7) === `Table_5` &&
              multiCompleteDataRedux &&
              multiCompleteDataRedux?.length > 0 && (
                <Table_5 index={selectedTableIndex} />
              )}
            {selectedTable.slice(0, 7) === `Table_6` &&
              multiCompleteDataRedux &&
              multiCompleteDataRedux?.length > 0 && (
                <Table_6 index={selectedTableIndex} />
              )}
            {selectedTable.slice(0, 7) === `Table_7` &&
              multiCompleteDataRedux &&
              multiCompleteDataRedux?.length > 0 && (
                <Table_7 index={selectedTableIndex} />
              )}
          </div>
        )}
      </Suspense>
    </>
  );
};

export default Main;
