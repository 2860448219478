import React from 'react'

const Alert = ({message}) => {
    return (
        <>
        <div className='alert-map'>
            <div className="alert d-flex align-items-center text-white fw-bold" style={{background: 'rgb(255, 0, 0,0.3)'}} role="alert">
                <div>
                    {message}
                </div>
            </div>
            </div>
        </>
    )
}

export default Alert
