import React from "react";
import VisChart1 from "./VisChart1";
import VisChart3 from "./VisChart3";
import VisChart6 from "./VisChart6";
import VisChart5 from "./VisChart5";
import VisChart9 from "./VisChart9";
import VisChart10 from "./VisChart10";
import VisChart11 from "./VisChart11";
import VisChart12 from "./VisChart12";
import Alert from "./Alert";
import { useSelector } from "react-redux";
import VisChart2 from "./VisChart2";
import VisChart7 from "./VisChart7";

const Visualization = () => {
  const locationData = useSelector((state) => state.locationData);

  return (
    <>
      <div className="container mt-2 res">
        {locationData ? (
          <div className="row mt-3">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12   mb-4">
              <p class="text-white ms-3">Top 5 Numbers based on Total no of calls</p>
              <VisChart1 />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12    mb-4">
              <p class="text-white ms-3">Top 5 Numbers based on call Duration</p>
              <VisChart2 />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12   mb-4">
              <p class="text-white ms-3"> Calls based on total sms </p>
              <VisChart3 />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12   mb-4">
              <p class="text-white ms-3">Call in v/s Call out</p>
              <VisChart5 />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12   mb-4">
              <p class="text-white ms-3">Top 5 Calls based on call in v/s call out </p>
              <VisChart6 />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12    mb-4">
              <p class="text-white ms-3">Top 5 Numbers by Total Calls and First/Last Call Date </p>
              <VisChart7 />
            </div>
            {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  mb-4">
              <VisChart1 />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  mb-4">
              <VisChart1 />
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-4">
              <VisChart1 />
            </div> */}
          </div>
        ) : (
          <Alert
            className="w-100"
            message={"First Upload The File in CDR Analysis"}
          />
        )}
      </div>
    </>
  );
};

export default Visualization;
