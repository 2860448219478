import React, { useEffect, useState } from "react";
const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const ApiDetails = () =>
{

  const [apiPointsBalance, setAPIPointsBalance] = useState(0);

  useEffect(() =>
  {
    const fetchUser = async () =>
    {
      try
      {
        const response = await fetch(
          `${base_url}/user/info`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": sessionStorage.getItem("token"),
            },
          }
        );
        const json = await response.json();
        console.log("USER INFO FROM GET---------------", json?.subscription?.ApiPointsBalance)
        setAPIPointsBalance(json?.subscription?.ApiPointsBalance)

      } catch (error)
      {
        console.error("Error updating user data:", error);
      }
    }
    fetchUser()
  }, [])

  return (
    <>
      <div
        className="modal fade"
        id="qr-code"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
      </div>
      <div className="row mt-3" id="TFA">
        <div
          className="d-flex p-3 ps-4 flex-column"
          style={{
            background:
              "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)",
            borderRadius: "25px",
            color: "#8C8C8C",
          }}
        >
          <div className="p-1 fw-bold text-white">
            API Points Balance: {apiPointsBalance}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiDetails;
