import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
const base_url =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

export const STATUSES = Object.freeze({
    IDLE: 'idle',
    ERROR: 'error',
    LOADING: 'loading',
});
const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: [],
        status: STATUSES.IDLE,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserData.pending, (state) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(fetchUserData.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = STATUSES.IDLE;
            })
            .addCase(fetchUserData.rejected, (state) => {
                state.status = STATUSES.ERROR;
            });
    },
});

export const fetchUserData = createAsyncThunk('user/fetchUserData', async () => {
    try {
        const response = await fetch(
            `${base_url}/user/info`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-auth-token": sessionStorage.getItem("token"),
                },
            }
        );
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
});

export default userSlice.reducer;
