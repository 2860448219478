import React from 'react';
import { CopyFilled } from '@ant-design/icons';

const CopyButton = ({ textToCopy }) => {
    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(textToCopy);
        } catch (err) {
            console.error('Failed to copy ID to clipboard', err);
        }
    };

    return (

        <button onClick={handleCopyClick} class="btn  " style={{ backgroundColor: '#0074ff', marginLeft: '2rem', marginTop: '-0.25rem', height: '40px', width: '40px' }}>
            <CopyFilled style={{ fontSize: '15px' }} />
        </button>
    );
};

export default CopyButton;
