import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast';
import { updateCaseSchema } from '../../schemas';
const base_url =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
        : process.env.REACT_APP_BASE_URL_PRODUCTION;

const CaseUpdate = ({ data, _id, fetchData }) => {
    const closeRef = useRef(null)
    const submitRef = useRef();
    const handleSubmitClick = () => {
        console.log("clicked")
        submitRef.current.click()
    }
    const [sharedOfficer, setSharedOfficer] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [officerID, setOfficerID] = useState('')
    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            setDebouncedSearch(sharedOfficer);
        }, 500);

        return () => clearTimeout(debounceTimeout);
    }, [sharedOfficer]);
    useEffect(() => {
        const fetchSearchResults = async () => {
            try {
                const response = await fetch(
                    `${base_url}/user/get_all_user?firstName=${debouncedSearch}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "x-auth-token": sessionStorage.getItem("token"),
                        },
                    }
                );
                const json = await response.json();
                if (json.status === 200) {
                    setSearchResults(json);
                }
            } catch (error) {
                console.error("Failed to fetch search results:", error);
            }
        };

        if (debouncedSearch) {
            fetchSearchResults();
        } else {
            setSearchResults([]);
        }
    }, [debouncedSearch]);

    const handleSharedOfficerSelect = (selectedOfficer1, selectedOfficer2, id) => {
        const sharedOfficer = `${selectedOfficer1} ${selectedOfficer2}`;
        console.log(id);
        setSharedOfficer(sharedOfficer);
        setOfficerID(id)
        setSearchResults([]);
    };
    const [loading, setLoading] = useState(false);
    const initialValues = {
        title: data.title || "",
        chargeSheet: data.chargeSheet || "",
        description: data.description || "",
        verdict: data.verdict || "",
        status: data.status || "",
        crimeSections: data.crimeSections || ["", ""],
        accused: data.accused || ["", ""],
        sharedWithOfficer: [""],

        updateDesc: ''
    };

    const { values, handleBlur, handleChange, handleSubmit, errors, touched, resetForm, } =
        useFormik({
            initialValues: initialValues,
            validationSchema: updateCaseSchema,
            onSubmit: async (values) => {
                setLoading(true);
                const { updateDesc, ...valuesWithoutUpdateDesc } = values;
                const cleanedValues = {
                    ...valuesWithoutUpdateDesc,
                    crimeSections: valuesWithoutUpdateDesc.crimeSections.filter((section) => section.trim() !== ""),
                    accused: valuesWithoutUpdateDesc.accused.filter((accusedName) => accusedName.trim() !== ""),
                    sharedWithOfficer: valuesWithoutUpdateDesc.sharedWithOfficer.filter((officer) => officer.trim() !== ""),
                    sharedWithOfficer: officerID ? [officerID] : [],
                    // updateHistory: {
                    //     description: values.updateDesc,
                    // },
                };
                try {
                    const response = await fetch(
                        `${base_url}/user/update_case/${_id}`,
                        {
                            method: "PUT",
                            headers: {
                                "Content-Type": "application/json",
                                "x-auth-token": sessionStorage.getItem("token"),
                            },
                            body: JSON.stringify(cleanedValues),
                        }
                    );
                    const data = await response.json();
                    if (data.status === 200) {
                        toast.success("Case Updated Successfully");
                        resetForm()
                        await fetchData();
                        closeRef.current.click();
                    }
                } catch (error) {
                    toast.error("Failed To Add, Try Again");
                } finally {
                    setLoading(false);
                }
            },
        });

    const addCrimeSection = () => {
        const updatedCrimeSections = [...values.crimeSections, ""];
        handleChange({ target: { name: "crimeSections", value: updatedCrimeSections } });
    };

    const removeCrimeSection = (index) => {
        const updatedCrimeSections = [...values.crimeSections];
        updatedCrimeSections.splice(index, 1);
        handleChange({ target: { name: "crimeSections", value: updatedCrimeSections } });
    };

    const handleChangeCrimeSection = (e, index) => {
        const { value } = e.target;
        const updatedCrimeSections = [...values.crimeSections];
        updatedCrimeSections[index] = value;
        handleChange({ target: { name: "crimeSections", value: updatedCrimeSections } });
    };

    const addAccusedName = () => {
        const updatedAccusedNames = [...values.accused, ""];
        handleChange({ target: { name: "accused", value: updatedAccusedNames } });
    };

    const removeAccusedName = (index) => {
        const updatedAccusedNames = [...values.accused];
        updatedAccusedNames.splice(index, 1);
        handleChange({ target: { name: "accused", value: updatedAccusedNames } });
    };

    const handleChangeAccusedName = (e, index) => {
        const { value } = e.target;
        const updatedAccusedNames = [...values.accused];
        updatedAccusedNames[index] = value;
        handleChange({ target: { name: "accused", value: updatedAccusedNames } });
    };

    const applyErrorStyles = (fieldName) => {
        if (errors[fieldName] && touched[fieldName]) {
            return "error-input";
        }
        return "";
    };
    console.log(errors, 'wd');
    return (
        <>
            <div className="modal-header text-white">
                <h5 className="modal-title">Update Case</h5>
                <div>
                    <div type="button" ref={closeRef} className="fa-regular fa-circle-xmark text-white float-end fs-5" data-bs-dismiss="modal" aria-label="Close"></div>
                </div>
            </div>
            <div className="modal-body">
                <form className="row g-3 px-2 pt-0 profileForm" onSubmit={handleSubmit}>
                    <div className="col-sm-6 mb-2">
                        <label htmlFor="title" className="form-label mb-0">
                            Case Title
                        </label>
                        <input
                            className={`${applyErrorStyles('title')}`}
                            type="text"
                            id="title"
                            placeholder="Case title"
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <span className="text-danger" style={{ fontSize: '12px', fontWeight: '900' }}>{errors.title && touched.title ? errors.title : null}</span>
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label htmlFor="chargeSheet" className="form-label mb-0">
                            Charge Sheet
                        </label>
                        <input
                            className={`${applyErrorStyles('chargeSheet')}`}
                            type="text"
                            id="chargeSheet"
                            placeholder="Charge Sheet"
                            name="chargeSheet"
                            value={values.chargeSheet}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <span className="text-danger" style={{ fontSize: '12px', fontWeight: '900' }}>{errors.chargeSheet && touched.chargeSheet ? errors.chargeSheet : null}</span>
                    </div>
                    <div className="col-sm-12 mb-2">
                        <label htmlFor="description" className="form-label mb-1">
                            Description
                        </label>
                        <textarea
                            className={`${applyErrorStyles('description')}`}
                            id="description"
                            placeholder="Description"
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            rows="4"
                        />
                        <span className="text-danger" style={{ fontSize: '12px', fontWeight: '900' }}>
                            {errors.description && touched.description ? errors.description : null}
                        </span>
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label htmlFor="verdict" className="form-label mb-0">
                            Verdict
                        </label>
                        <input
                            className={`${applyErrorStyles('verdict')}`}
                            type="text"
                            id="verdict"
                            placeholder="Verdict"
                            name="verdict"
                            value={values.verdict}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <span className="text-danger" style={{ fontSize: '12px', fontWeight: '900' }}>{errors.verdict && touched.verdict ? errors.verdict : null}</span>
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label htmlFor="status">Status</label>
                        <select
                            style={{ height: '40px' }}
                            className="select-form text-secondary mt-1 px-2"
                            name="status"
                            value={values.status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        >
                            <option value="">--select--</option>
                            <option value="OPEN">Open</option>
                            <option value="CLOSED">Closed</option>
                            <option value="IN_PROGRESS">In Progress</option>
                        </select>
                        <span
                            className="text-danger"
                            style={{ fontSize: "12px", fontWeight: "900" }}>
                            {errors.status && touched.status ? errors.status : null}
                        </span>
                    </div>
                    <div className="col-sm-6 mb-0">
                        <div className="p-2 ps-0 pb-0 pt-0 text-white">Accused Names</div>
                        {values.accused.map((accusedName, index) => (
                            <div key={index} className="input-group py-2" style={{ position: "relative" }}>
                                <input
                                    className="mb-0 mt-0"
                                    type="text"
                                    id={`accusedName_${index}`}
                                    placeholder={`Accused Name ${index + 1}`}
                                    value={accusedName}
                                    onChange={(e) => handleChangeAccusedName(e, index)}
                                    onBlur={handleBlur}
                                />

                                <span key={index} className="text-danger ms-0" style={{ fontSize: '12px', fontWeight: '900' }}>
                                    {errors.accused && errors.accused[index] ? errors.accused[index] : null}
                                </span>
                                <div style={{ position: "absolute", top: "25%", right: "8px" }}>
                                    <i
                                        className="fa-solid fa-xmark text-white"
                                        style={{
                                            cursor: "pointer",
                                            fontWeight: "800",
                                        }}
                                        onClick={() => removeAccusedName(index)}
                                    ></i>
                                </div>
                            </div>
                        ))}
                        <div
                            style={{
                                width: "fit-content",
                                fontWeight: "800",
                                fontSize: "13px",
                            }}
                            className="btn text-white btn-primary mt-2"
                            onClick={addAccusedName}
                        >
                            Add More Accused Name
                        </div>
                    </div>

                    <div className="col-sm-6">
                        <div className="p-2 ps-0 pb-0 pt-0 text-white">Crime Sections</div>
                        {values.crimeSections.map((crimeSection, index) => (
                            <div key={index} className="input-group py-2" style={{ position: "relative" }}>
                                <input
                                    className="mt-0 mb-0"
                                    type="text"
                                    id={`crimeSection_${index}`}
                                    placeholder={`Crime Section ${index + 1}`}
                                    value={crimeSection}
                                    onChange={(e) => handleChangeCrimeSection(e, index)}
                                    onBlur={handleBlur}
                                />
                                <span key={index} className="text-danger ms-0" style={{ fontSize: '12px', fontWeight: '900' }}>
                                    {errors.crimeSections && errors.crimeSections[index] && touched.crimeSections && touched.crimeSections[index] ? errors.crimeSections[index] : null}
                                </span>
                                <div style={{ position: "absolute", top: "25%", right: "8px" }}>
                                    <i
                                        className="fa-solid fa-xmark text-white"
                                        style={{
                                            cursor: "pointer",
                                            fontWeight: "800",
                                        }}
                                        onClick={() => removeCrimeSection(index)}
                                    ></i>
                                </div>
                            </div>
                        ))}
                        <div
                            style={{
                                width: "fit-content",
                                fontWeight: "800",
                                fontSize: "13px",
                            }}
                            className="btn text-white btn-primary mt-2"
                            onClick={addCrimeSection}
                        >
                            Add More Crime Section
                        </div>
                    </div>
                    <div className="col-sm-6 mb-2">
                        <label htmlFor="sharedWithOfficer" className="form-label mb-0">
                            Share with Another Officer
                        </label>
                        <input
                            type="text"
                            id="sharedWithOfficer"
                            placeholder="Search for shared officer"
                            value={sharedOfficer}
                            onChange={(e) => setSharedOfficer(e.target.value)}
                            autoComplete="false"
                        />
                        <div className="search-results text-white">
                            {searchResults.totalRecord > 0 ? (
                                searchResults.data.map((officer, index) => (
                                    <div
                                        key={index}
                                        className="search-result px-2"
                                        onClick={() => handleSharedOfficerSelect(officer.firstName, officer.lastName, officer._id,)}
                                    >
                                        {officer.firstName} {officer.lastName} ({officer.username})
                                    </div>
                                ))
                            ) : (
                                null
                            )}
                        </div>

                    </div>
                    {/* <div className="col-sm-12 mt-4">
                        <label htmlFor="updateDesc" className="form-label mb-1">
                            Update History Description
                        </label>
                        <input
                            className={`${applyErrorStyles('updateDesc')}`}
                            type="text"
                            id="updateDesc"
                            placeholder="Update History Description"
                            name="updateDesc"
                            value={values.updateDesc}
                            onChange={
                                handleChange
                            }
                            onBlur={handleBlur}
                        />

                        <span className="text-danger" style={{ fontSize: '12px', fontWeight: '900' }}>

                            {errors && touched.updateDesc  &&  errors.updateDesc ? errors.updateDesc : null}
                        </span>
                    </div> */}
                    <button type="submit" ref={submitRef} className="d-none">
                        Submit
                    </button>
                </form>
            </div>
            <div className="modal-footer">
                <div type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</div>
                {loading ? (<div className="btn btn-primary">Saving...</div>) : (
                    <div type="submit" className="btn btn-primary" onClick={handleSubmitClick}>Save changes</div>
                )}
            </div>
        </>
    )
}

export default CaseUpdate
