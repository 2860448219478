import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Percentage from "../AnalysisCDR/Percentage";

// call based on location

const Table_5 = ({ index }) => {
  const [loading, setLoading] = useState(false);
  const [locationError, setlocationError] = useState(false);
  const navigate = useNavigate();
  const locationRef = useRef(null);
  const closeRef = useRef(null);
  const multiCompleteDataRedux = useSelector(
    (state) => state.multiCompleteDataRedux
  );
  const [locationData, setlocationData] = useState(
    multiCompleteDataRedux[index]?.record_based_on_location_data
  );
  console.log("data on Table_5 is ", locationData);
  const highestTotalCall = Math.max(
    ...locationData.map((item) => item.total_number_of_call),
    0
  );
  const [selectedLoc, setSelectedLoc] = useState(null);
  const [locationDetails, setLocationDetails] = useState(null);

  const openMap = () => {
    closeRef.current.click();
    navigate(
      `/CDR-analysis/geo-analysis/${selectedLoc.location.latitude}/${selectedLoc.location.longitude}`
    );
  };
  useEffect(() => {
    if (locationData.length < 2) {
      setlocationError(true);
    }
    const fetchLocationDetails = async () => {
      if (selectedLoc) {
        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${selectedLoc.location.latitude},${selectedLoc.location.longitude}&key=AIzaSyAfEYRdDPTAw2THWoyCwynliaTtnEMyex8`
          );
          const data = await response.json();
          if (data.results && data.results.length > 0) {
            const formattedAddress = data.results[0].formatted_address;
            setLocationDetails(formattedAddress);
          } else {
            setLocationDetails("Location not found");
          }
        } catch (error) {
          setLocationDetails("Location not found");
        }
      }
    };

    fetchLocationDetails();
  }, [selectedLoc]);

  const handleLocClick = (item) => {
    console.log("Location Details:", item);
    setSelectedLoc(item);
    locationRef?.current?.click();
  };
  return (
    <div>
      {/* ---------------------------------------------------------Loading--------------------------------------------------------- */}
      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "99999999",
          }}
        >
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">loading...</div>
        </div>
      )}
      {/* -----------------------------Modal----------------------------- */}
      <button
        type="button"
        ref={locationRef}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#table_3_row"
      ></button>
      <div
        className="modal fade"
        id="table_3_row"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content" style={{ background: "transparent" }}>
            <div className="modal-body" style={{ background: "transparent" }}>
              {selectedLoc && (
                <div className="CDR-row px-3 pb-4">
                  <i
                    type="button"
                    className="fa-regular fa-circle-xmark float-end text-white pt-3 fs-5"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    ref={closeRef}
                  ></i>
                  <div className="location-details text-white">
                    <p className="pt-4 mb-0" style={{ fontSize: "20px" }}>
                      <strong>Location:</strong>{" "}
                      {locationDetails || "Loading..."}
                    </p>
                  </div>
                  <div
                    className="btn ps-0 pb-2 pt-1 mt-0 text-center"
                    onClick={openMap}
                    style={{ fontSize: "15px", color: "#0075FF" }}
                  >
                    View Location on Map
                    <i className="fa-solid ms-2 fa-location-dot"></i>
                  </div>

                  <div
                    className="table-responsive"
                    style={{ maxHeight: "400px" }}
                  >
                    <table className="table table-bordered border-secondary align-middle">
                      <thead className="table_cdr sticky-top table-header-sticky">
                        <tr>
                          <th scope="col">S.NO</th>
                          <th scope="col">Phone No.</th>
                          <th scope="col">Date of Call</th>
                          <th scope="col">Time of Call</th>
                          <th scope="col">Call Type</th>
                        </tr>
                      </thead>
                      <tbody
                        className="text-white"
                        style={{ fontSize: "12px", background: "#080D2F" }}
                      >
                        {selectedLoc.details.map((call, index) => {
                          // console.log(index);
                          return (
                            <tr key={index}>
                              <th className="py-3" scope="row">
                                {index + 1}
                              </th>
                              <td className="py-3">{call.calledPhoneNumber}</td>
                              <td className="py-3">{call.callDate}</td>
                              <td className="py-3">{call.callTime}</td>
                              <td className="py-3">{call.callType}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* ------------------table-------------------- */}

      {!locationError && (
        <div className="row res3 px-3 pb-5">
          <div className="CDR-row p-4">
            <div className="table-responsive" style={{ maxHeight: "500px" }}>
              <table className="table mt-2 table-bordered border-secondary align-middle">
                <thead className="table_cdr sticky-top table-header-sticky">
                  <tr>
                    <th scope="col">S.NO</th>
                    <th scope="col">Longitude</th>
                    <th scope="col">Latitude</th>
                    <th scope="col">Incoming Calls</th>
                    <th scope="col">Outgoing Calls</th>
                    <th scope="col">Total Calls</th>
                  </tr>
                </thead>
                <tbody
                  className="text-white"
                  style={{ fontSize: "12px", background: "#080D2F" }}
                >
                  {locationData.map((item, index) => {
                    // console.log(item, index);
                    return (
                      <tr
                        className="table-row-hover"
                        key={index}
                        onClick={() => handleLocClick(item)}
                        style={{ cursor: "pointer" }}
                      >
                        <th className="py-3" scope="row">
                          {index + 1}
                        </th>
                        <td className="py-3">
                          {item.location.longitude
                            ? item.location.longitude
                            : "NA"}
                        </td>
                        <td className="py-3">
                          {item.location.latitude
                            ? item.location.latitude
                            : "NA"}
                        </td>
                        <td className="py-3">{item.call_type_in || "NA"}</td>
                        <td className="py-3">{item.call_type_out || "NA"}</td>
                        <td>
                          {
                            <Percentage
                              total={item.total_number_of_call}
                              highest={highestTotalCall}
                            />
                          }
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {locationError && (
        <div className="text-center p-3 text-white fs-5 notfound fs-2">
          Location Data is not available!
          <style>
            {`
                    .notfound{
                        color:orange !important;
                    }
                    `}
          </style>
        </div>
      )}
    </div>
  );
};

export default Table_5;
