import React from "react";
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  Radar,
  Legend,
  ResponsiveContainer,
  PolarRadiusAxis,
  Tooltip,
} from "recharts";
import { useSelector } from "react-redux";

function VisChart3() {
  // Sample data for the bar chart

  const tableData = useSelector((state) => state.data);

  const extractedData = tableData.map((item) => ({
    b_party_number: item.b_party_number,
    sms: item.sms,
  }));
  extractedData.sort((a, b) => b.sms - a.sms);

  const top5SmsData = extractedData.slice(0, 10);

  return (
    <div
      className="text-white align-items-center p-3"
      style={{
        background:
          "linear-gradient(103.46deg, #080D2F 1.69%, rgba(8, 13, 47, 0.35) 99.32%)",
        borderRadius: "25px",
        textTransform: "capitalize",
        textAlign: "center",
      }}>
      <ResponsiveContainer width="100%" height={400}>
        <RadarChart
          outerRadius={90}
          width={730}
          height={250}
          data={top5SmsData}>
          <PolarGrid />
          <PolarAngleAxis dataKey="b_party_number" />
          <PolarRadiusAxis angle={30} domain={[0, 150]} />
          <Radar
            name="sms"
            dataKey="sms"
            stroke="red"
            fill="red"
            fillOpacity={0.6}
          />
          <Tooltip></Tooltip>
          <Legend />
        </RadarChart>
      </ResponsiveContainer>
      <h2 className="mt-3" style={{ fontSize: "14px" }}>sms vs B-Party Number</h2>
    </div>
  );
}

export default VisChart3;
