import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchUserData } from "../../store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import './Diary.css'

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

export default function Diary() {
  const { data: user } = useSelector((state) => state.user);
  console.log(user);
  const dispatch = useDispatch();
  const IPAddress = localStorage.getItem("IPAddress");
  const [note, setNote] = useState("");
  const [date, setDate] = useState(new Date());
  const [todayrecord, setTodayrecord] = useState([]);
  const [editingReminderId, setEditingReminderId] = useState(null);
  const [editNote, setEditNote] = useState("");
  const [getDate, setGetDate] = useState(new Date());
  const [sendNotification, setSendNotification] = useState(false);

  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);

  const handleEdit = (item) => {
    setEditingReminderId(item._id);
    setEditNote(item.subject);
  };

  const handleDatePickerChange = (selectedDate) => {
    setDate(selectedDate);
  };

  const handleGetDateChange = (selectedDate) => {
    setGetDate(selectedDate);
    fetchReminder(selectedDate);
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate;
  }

  const handleEditSubmit = async (id) => {
    try {
      const response = await fetch(`${base_url}/user/update_reminder/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({ subject: editNote, IPAddress: IPAddress }),
      });
      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          toast.success("Updated Successfully");
          fetchReminder(formatDate(getDate));
        }
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to update reminder:", error);
    }
  };

  const fetchReminder = async (newDate) => {
    try {
      const response = await fetch(
        `${base_url}/user/get_reminder?date_of_reminder=${formatDate(newDate)}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setTodayrecord(data.data || []);
        setEditingReminderId(null);
      } else {
        throw new Error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error("Failed to fetch reminder:", error);
    }
  };

  useEffect(() => {
    const formattedDate = formatDate(new Date());
    fetchReminder(formattedDate);
  }, []);

  const handleSubmit = async () => {
    const reminderData = {
      subject: note,
      date_of_reminder: formatDate(date),
      IPAddress: IPAddress,
      set_reminder: sendNotification, // This will be true if the checkbox is checked
    };

    try {
      const response = await fetch(`${base_url}/user/create_reminder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(reminderData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        toast.success("Reminder Created");
        setNote("");
        fetchReminder(date);
        setGetDate(date);
      }
    } catch (error) {
      console.error("Error creating the reminder:", error);
      toast.error(error.message);
    }
  };

  const NotificationOption = () => {
    if (user && user.phone) {
      return (
        <div className="form-check pt-2">
          <input
            className="form-check-input"
            type="checkbox"
            name="sendNotification"
            id="sendNotification"
            checked={sendNotification}
            onChange={(e) => setSendNotification(e.target.checked)}
          />
          <label
            className="form-check-label ms-2 fw-bold"
            htmlFor="sendNotification">
            Want to send notification on your mobile
          </label>
        </div>
      );
    } else {
      return (
        <div className="alert alert-warning mt-4" role="alert">
          Please register your mobile number in the profile section to get
          reminders on your mobile phone.
        </div>
      );
    }
  };

  return (
    <div className=" row res3">
      <div className="create-reminder p-4 d-flex flex-column">
        <label htmlFor="rem" className="fw-bold">
          Create Reminder
        </label>
        <DatePicker
          selected={date}
          onChange={handleDatePickerChange}
          placeholderText="Select a date"
          dateFormat="yyyy-MM-dd"
          className="text-white fw-bold mb-3"
          popperPlacement="auto"
          popperModifiers={{
            offset: {
              enabled: true,
              offset: "5px, 10px", // adjust as needed
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "viewport",
            },
          }}
          customInput={
            <input
              className="text-white fw-bold mb-3"
              style={{
                borderRadius: "5px",
                background: "#0B0F2A",
                width: "100%",
              }}
            />
          }
        />
        <textarea
          className="p-2 text-white fw-bold"
          style={{
            background: "#0B0F2A",
            width: "100%",
          }}
          placeholder="Leave a Note here..."
          id="floatingTextarea"
          rows={5}
          value={note}
          onChange={(e) => setNote(e.target.value)}></textarea>
        <NotificationOption />
        <button
          type="button"
          onClick={handleSubmit}
          className="btn btn-primary">
          Create Reminder
        </button>
      </div>
      <div className="get-reminder p-3 me-1 ms-1 d-flex flex-column pt-3"
        style={{ background: "#0B0F2A", paddingBottom: '100px', borderRadius: "25px" }}>
        <label htmlFor="rem" className="fw-bold">
          Get Your Reminders
        </label>
        <DatePicker
          selected={getDate}
          onChange={handleGetDateChange}
          placeholderText="Select a date"
          dateFormat="yyyy-MM-dd"
          className="text-white fw-bold mb-3"
          popperPlacement="auto"
          popperModifiers={{
            offset: {
              enabled: true,
              offset: "5px, 10px", // adjust as needed
            },
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "viewport",
            },
          }}
          customInput={
            <input
              className="text-white fw-bold mb-3"
              style={{
                borderRadius: "5px",
                background: "#0B0F2A",
                width: "100%",
              }}
            />
          }
        />
        <h4 className="text-white fs-5 mb-0">Your Reminders</h4>
        <div className="accordion accordion-flush  overflow-auto " style={{ height: '30rem' }} id="accordionFlushExample">
          {todayrecord &&
            todayrecord.map((item, index) => (
              <div
                className="accordion-item text-white"
                style={{
                  background:
                    "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.5) 100%)",
                }}
                key={item._id}>
                <h2 className="accordion-header text-white">
                  <button
                    style={{
                      fontWeight: "600",
                      background:
                        "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 1) 100%)",
                    }}
                    className="accordion-button collapsed text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse-${index}`}
                    aria-expanded="false"
                    aria-controls={`flush-collapse-${index}`}>
                    <div className="d-flex flex-row w-100 justify-content-between  align-items-center">
                      <div className="">{item.subject.slice(0, 10)}</div>
                      <div
                        className="text-secondary"
                        style={{ fontSize: "13px" }}>
                        {formatDate(item.createdAt)}
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id={`flush-collapse-${index}`}
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">
                    <div className="d-flex flex-column justify-content-between align-items-center ">
                      {editingReminderId === item._id ? (
                        <div class="me-auto text-white" style={{ width: '100%' }} >
                          <textarea
                            style={{
                              color: 'white',
                              width: '100%',
                              border: "1px solid transparent",
                              outline: "none",
                              minHeight: "1em",
                              cursor: "text",
                              boxShadow: "none",
                              background:
                                "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 1) 100%)",
                            }}
                            className="rounded-0"
                            value={editNote}
                            onChange={(e) => setEditNote(e.target.value)}
                            placeholder="Subject"
                          />
                        </div>
                      ) : (
                        <div class="me-auto">{item.subject}</div>
                      )}
                      <div className="d-flex flex-row align-items-center ms-auto mt-3">
                        {/* edit button */}
                        <div
                          className="fa-regular btn btn-primary fs-4 fa-pen-to-square"
                          onClick={() => handleEdit(item)}></div>
                        {/* save button */}
                        {editingReminderId === item._id && (
                          <div
                            className="btn btn-success ms-2"
                            onClick={() => handleEditSubmit(item._id)}>
                            Save
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {todayrecord.length === 0 && (
            <div className="text-white">No Remainder</div>
          )}
        </div>
      </div>
    </div>
  );
}
