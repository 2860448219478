import React from "react";
import "../CSS/signup_signin.css";
import backgroundVideo from "./video.mp4";

const Background = () => {
  return (
    <video className="video-bg" autoPlay muted loop>
      <source src={backgroundVideo} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

export default Background;
