import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
const base_url =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
        : process.env.REACT_APP_BASE_URL_PRODUCTION;


function Chart1() {



    const [chartData, setChartData] = useState({
        options: {
            chart: {
                id: 'basic-bar',
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                title: {
                    text: 'Accused Count', // Y-axis label
                },
            },
            plotOptions: {
                bar: {
                    colors: {
                        ranges: [
                            {
                                from: 1, // You can specify the value range and the color for the bars
                                to: 2,
                                color: '#000B3B' // Specify your desired color here
                            }, {
                                from: 3, // You can specify the value range and the color for the bars
                                to: 5,
                                color: '#001F70' // Specify your desired color here
                            }, {
                                from: 6,
                                to: 10,
                                color: '#0075FF' // Another color for a different range
                            }]
                    },
                    columnWidth: '80%', // Adjust the width of the bars
                }
            },

        },
        series: [
            {
                data: [],
            },
        ],
        textColor: 'black',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${base_url}/user/list_case?limit=10000000`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-auth-token': sessionStorage.getItem('token'),
                        },
                    }
                );
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const json = await response.json();
                if (json.status === 200) {
                    const casesData = json.data;
                    const sortedCasesData = casesData.slice().sort((a, b) => {
                        return b.accused.length - a.accused.length; // Sort cases by the number of accused in descending order
                    });
                    const top10Cases = sortedCasesData.slice(0, 10);

                    // Extract data for the chart
                    const top10Titles = top10Cases.map((caseItem) => caseItem.caseNumber);
                    const top10AccusedCount = top10Cases.map((caseItem) => caseItem.accused.length);

                    setChartData({
                        ...chartData,
                        options: {
                            ...chartData.options,
                            xaxis: {
                                categories: top10Titles,
                                title: {
                                    text: 'Case Number',
                                },
                            },
                        },
                        series: [
                            {
                                name: 'Accused Count',
                                data: top10AccusedCount,
                            },
                        ],
                    });
                } else {
                    throw new Error('Request was not successful');
                }
            } catch (error) {
                console.error('Failed to Fetch:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className='text-white align-items-center' style={{
            background:
                'linear-gradient(103.46deg, #080D2F 1.69%, rgba(8, 13, 47, 0.35) 99.32%)',
            borderRadius: '25px',
            textTransform: 'capitalize',
        }}>
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
        </div>
    );
}

export default Chart1;
