import React from 'react'

const Delete = () => {
    return (
        <>
            <div className="row mt-3" id='delete'>
                <div className="d-flex p-3 ps-4 flex-column" style={{ background: 'linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)', borderRadius: '25px', color: '#8C8C8C' }}>
                    <div className="p-1 fw-bold text-white">Delete Account</div>
                    <div className="p-1" style={{ fontSize: '0.6rem' }}>Once you delete your account, there is no going back. Please be certain.</div>
                    <div className="d-flex flex-row mt-3 align-items-center">
                    <div className="form-check ps-3 mt-1 ms-4 form-switch me-auto">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
                        <label className="form-check-label" style={{ marginTop: '2px',fontSize: '0.6rem' }} for="flexSwitchCheckDefault">Confirm</label>
                    </div>
                        <div className="btn p-1 ps-3 pe-3 me-2  text-white border border-light" style={{ width: 'content-fit', height: 'content-fit', color: '#06A581', fontSize: '0.6rem' }}>DEACTIVATE</div>
                        <div className="btn p-1 ps-3 pe-3 text-white" style={{ width: 'content-fit', height: 'content-fit', fontSize: '0.6rem', background: '#E31A1A' }}>DELETE ACCOUNT</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Delete
