import React from 'react'

const Reports = () => {
  return (
    <div>
      
    </div>
  )
}

export default Reports

// import React from 'react';
// import { Link, NavLink, useLocation } from 'react-router-dom';
// import contact from './img/contact.png';

// function Sidebar() {
//     const location = useLocation();

//     const renderLink = (text, to) => {
//         const isActive = location.pathname === to;

//         return (
//             <li className="nav-item" key={text}>
//                 <NavLink
//                     to={to}
//                     style={{
//                         display: 'flex',
//                         alignItems: 'center',
//                         color: '#FFFFFF',
//                         padding: '10px',
//                         textDecoration: 'none',
//                         borderRadius: '5px',
//                         marginBottom: '5px',
//                     }}
//                 >
//                     <div
//                         style={{
//                             width: '25px',
//                             height: '25px',
//                             background: isActive ? '#0075FF' : '#1E2133',
//                             display: 'flex',
//                             borderRadius: '8px',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                         }}
//                         className="me-3"
//                     />
//                     {text}
//                 </NavLink>
//             </li>
//         );
//     };

//     return (
//          <div class="collapse collapse-horizontal" id="himu">
//             <div className="d-flex sticky-top flex-column flex-shrink-0 p-3 text-white bg-dark overflow-auto" style={{width: '303px', background: 'linear-gradient(180deg, #0f0b1d, rgba(15, 11, 29, 0.5))' }} id='himu'>
//                 <Link to="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
//                     <svg className="bi me-2" width="25" height="25"></svg>
//                     <span className="fs-5 fw-normallh-1-3">SEIA CDR-Analysis</span>
//                 </Link>
//                 <hr />
//                 <ul className="nav nav-pills fs-6 flex-column mb-auto">
//                     {[
//                         { text: 'Dashboard', to: '/dashboard' },
//                         { text: 'Profile', to: '/profile' },
//                         { text: 'Analysis CDR', to: '/analysis' },
//                         { text: 'Case Management', to: '/case' },
//                         { text: 'Activity', to: '/activity' },
//                         { text: 'Reports', to: '/reports' },
//                         { text: 'Options', to: '/options' },
//                         { text: 'Search', to: '/search' },
//                         { text: 'Help', to: '/help' },
//                         { text: 'Logout', to: '/logout' },
//                     ].map((link) => renderLink(link.text, link.to))}
//                 </ul>
//                 <div className="container my-4">
//                     <div className="row">
//                         <div className="col-12" style={{ width: '100%', height: '210px', background: `url(${contact})`, backgroundSize: 'cover', marginBottom: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
//                             <p style={{ color: 'white', fontSize: '18px', marginBottom: '10px' }}>Need help! Please contact us</p>
//                             <button style={{ padding: '10px 20px', fontSize: '16px', backgroundColor: '#0075FF', color: 'white', border: 'none', borderRadius: '5px' }}>3498934934</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default Sidebar;
