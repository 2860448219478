import React, { useState, useEffect } from "react";
import { FaEdit, FaTrash, FaSave, FaTimes } from "react-icons/fa";
import toast from "react-hot-toast";
import "./Casediary.css";

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const UpdateHistory = ({ case_id, initialEntries }) => {
  const [newEntry, setNewEntry] = useState("");
  const [entries, setEntries] = useState([]);
  const [editingIndex, setEditingIndex] = useState(-1);
  const [editText, setEditText] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (initialEntries) {
      setEntries(initialEntries);
    }
  }, [initialEntries]);

  // console.log(entries);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(
        new Date().toLocaleString(undefined, {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleEntrySubmit = async (event) => {
    event.preventDefault();
    if (newEntry.trim() !== "") {
      try {
        setIsLoading(true);
        const apiUrl = `${base_url}/user/add_case_history/${case_id}`;
        const requestBody = {
          timestamp: new Date().toISOString(),
          description: newEntry,
        };

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
          },
          body: JSON.stringify(requestBody),
        });

        if (response.ok) {
          const result = await response.json();
          console.log(result);
          setEntries(result.data.updateHistory);
          setNewEntry("");
          toast.success("Entry created successfully.");
        } else {
          toast.error("Failed to create entry.");
        }
      } catch (error) {
        toast.error("Error creating entry.");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const startEditing = (index) => {
    // If the same index is clicked again, toggle the editing state
    if (index === editingIndex) {
      cancelEditing();
    } else {
      setEditingIndex(index);
      setEditText(entries[index].description);
    }
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setEditText(entries[index].description); // Also updated here
  };

  const cancelEditing = () => {
    setEditingIndex(-1);
    setEditText("");
  };

  const saveEdit = async (index) => {
    const entryId = entries[index]._id;
    const apiUrl = `${base_url}/user/update_case_history/${case_id}/${entryId}`;

    const updatedEntry = {
      timestamp: new Date(entries[index].timestamp).toISOString(),
      description: editText,
    };

    try {
      const response = await fetch(apiUrl, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": sessionStorage.getItem("token"),
        },
        body: JSON.stringify(updatedEntry),
      });

      if (response.ok) {
        const result = await response.json();
        setEntries(result.data.updateHistory);
        cancelEditing();
        toast.success("Entry updated successfully.");
      } else {
        toast.error("Failed to update entry.");
      }
    } catch (error) {
      toast.error("Error updating entry.");
    }
  };

  const deleteEntry = async (index) => {
    const entryId = entries[index]._id;
    const apiUrl = `${base_url}/user/delete_case_history/${case_id}/${entryId}`;

    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": sessionStorage.getItem("token"),
        },
      });

      if (response.ok) {
        const result = await response.json();
        setEntries(result.data.updateHistory);
        toast.success("Entry deleted successfully.");
      } else {
        toast.error("Failed to delete entry.");
      }
    } catch (error) {
      toast.error("Error deleting entry.");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <p className="text-center text-white fs-3 fw-bold">Case Diary</p>
      <div className="main-container d-flex text-white gap-3">
        {/* left  */}
        <div
          className="boxa me-3   p-3"
          style={{ backgroundColor: "rgb(10 31 94)", borderRadius: "15px" }}>
          <form onSubmit={handleEntrySubmit}>
            <div>
              <p className="text-white fs-4 ms-1 mb-4">Make Entry</p>
              <p className="text-white fs-6">Current Time: {currentTime}</p>
            </div>
            <div style={{ width: "100%" }}>
              <textarea
                className="mt-3 border-white text-white rounded-3"
                style={{
                  width: "100%",
                  padding: "10px 20px",
                  height: "200px",
                  backgroundColor: "rgb(11, 15, 42)",
                  borderColor: "#8ea5c9",
                }}
                placeholder="Leave a note here"
                value={newEntry}
                onChange={(e) => setNewEntry(e.target.value)}
              />
            </div>
            <div>
              <button type="submit" className="fw-bold fs-5">
                Save
              </button>
            </div>
          </form>
        </div>
        {/* right */}
        <div
          className="boxb me-1   p-3 "
          style={{ backgroundColor: "rgb(10 31 94)", borderRadius: "15px" }}>
          <p className="text-white fs-4 ms-3 mb-4">Your Entries</p>
          <div
            className="accordion overflow-auto"
            id="accordionExample"
            style={{ height: "30rem" }}>
            {entries.length > 0 ? (
              entries.map((item, index) => (
                <div
                  className="accordion-item text-white"
                  style={{
                    background:
                      "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.5) 100%)",
                  }}
                  key={index}>
                  <h2 className="accordion-header">
                    <button
                      style={{
                        background:
                          "linear-gradient(123.35deg, rgb(8, 13, 47) 3.3%, rgb(17, 27, 90) 100%)",
                      }}
                      className="accordion-button text-primary collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="false"
                      aria-controls={`collapse${index}`}>
                      <div
                        className="text-start text-white"
                        style={{ width: "75%" }}>
                        {item?.description
                          ? item?.description?.slice(0, 10)
                          : "No Data"}
                        ...
                      </div>
                      <div
                        className="text-end me-1"
                        style={{
                          width: "25%",
                          color: "#6c757d",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}>
                        {formatDate(item?.timestamp)}
                      </div>
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionExample">
                    <div className="accordion-body d-flex justify-content-between">
                      <div className="flex-grow-1">
                        {index === editingIndex ? (
                          <textarea
                            type="text"
                            style={{
                              height: "5rem",
                              background:
                                "linear-gradient(123.35deg, rgb(8, 13, 47,0) 3.3%, rgb(17, 27, 90,0) 100%)",
                              outline: "none",
                              minHeight: "1em",
                              cursor: "text",
                              boxShadow: "none",
                            }}
                            className="edit-input-expanded border-0 text-white"
                            value={editText}
                            onChange={(e) => setEditText(e.target.value)}
                          />
                        ) : (
                          <span>{item?.description}</span>
                        )}
                      </div>
                      <div>
                        {index === editingIndex ? (
                          <>
                            <FaSave
                              onClick={() => saveEdit(index)}
                              className="mx-2"
                            />
                            <FaTimes onClick={cancelEditing} className="mx-2" />
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}>
                            <FaEdit
                              onClick={() => startEditing(index)}
                              className="mx-2"
                            />
                            <FaTrash
                              style={{ color: "#ff7f7f" }}
                              onClick={() => deleteEntry(index)}
                              className="mx-2"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-white fs-5 text-center">No Diary Entry</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateHistory;
