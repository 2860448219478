import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import { TFASchema } from "../../schemas";
import { json } from "react-router";
import { RotatingLines } from "react-loader-spinner";
const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const TFA = () => {
  const openRef = useRef(null);
  const closeRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const initialValues = {
    TWOfaToken: "",
  };

  const {
    errors,
    values,
    handleBlur,
    handleSubmit,
    handleChange,
    handleReset,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: TFASchema,
    onSubmit: async (values) => {
      try {
        const response = await fetch(
          "https://api.seiasecure.com/api/v1/user/2fa/verify-setup",
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": sessionStorage.getItem("token"),
            },
            body: JSON.stringify(values),
          }
        );
        const data = await response.json();
        if (data.status === 200) {
          closeRef.current.click();
          Swal.fire({
            icon: "success",
            title: "Verified",
            text: "Your two-factor verification was successful.",
            background:
              "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 1) 100%)",
          });
        } else if (!json.success) {
          errors.TWOfaToken = "Incorrect Verification Code.";
          console.error("Verification failed:", data);
        }
      } catch (error) {
        toast("Something went wrong");
        console.error("An error occurred:", error);
      }
    },
  });

  const handleCancel = () => {
    closeRef.current.click();
    handleReset();
  };

  const handleClick = async () => {
    try {
      const response = await fetch(`${base_url}/user/2fa/enable`, {
        method: "PUT",
        headers: {
          "x-auth-token": sessionStorage.getItem("token"),
        },
      });
      const data = await response.json();
      console.log(data.qrCode);
      if (data.status === 200) {
        setQrCode(data.qrCode);
      } else {
        await closeRef.current.click();
        toast.error("Falied to Generate QR Code");
      }
    } catch (error) {
      closeRef.current.click();
      toast.error(error);
    }
  };
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length > 6) {
      const truncatedValue = inputValue.slice(0, 6);
      handleChange({ target: { name: "TWOfaToken", value: truncatedValue } });
    } else {
      handleChange(event);
    }
  };
  return (
    <>
      <button
        type="button"
        ref={openRef}
        className="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#qr-code"
      ></button>
      <div
        className="modal fade"
        id="qr-code"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content" style={{ background: "transparent" }}>
            <div className="modal-body QrCode-row">
              <i
                type="button"
                className="fa-regular fa-circle-xmark float-end text-white fs-5"
                data-bs-dismiss="modal"
                onClick={handleReset}
                aria-label="Close"
                ref={closeRef}
              ></i>
              <div className="text-center pt-3 pb-4">
                {qrCode && (
                  <img src={qrCode} className="img-thumbnail" alt="fetching" />
                )}
                {!qrCode && (
                  <div className="pt-2">
                    <p className="text-white fw-bold">Fetching...</p>
                    <RotatingLines
                      strokeColor="grey"
                      strokeWidth="5"
                      animationDuration="1"
                      width="50"
                      visible={true}
                    />
                  </div>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="col-sm-12 p-3">
                    <input
                      className="rounded-1 fw-bold"
                      type="number"
                      placeholder="Enter Verification Code"
                      name="TWOfaToken"
                      value={values.TWOfaToken}
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                    />
                    {errors.TWOfaToken && touched.TWOfaToken ? (
                      <span
                        className="text-danger float-start"
                        style={{ fontSize: "12px", fontWeight: "900" }}
                      >
                        {errors.TWOfaToken}
                      </span>
                    ) : null}
                  </div>
                  <div className="d-flex flex-row float-end pb-2">
                    <button
                      type="button"
                      className="btn btn-danger me-3"
                      onClick={handleCancel}
                      style={{ width: "fit-content" }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success"
                      style={{ width: "fit-content" }}
                    >
                      Verify
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3" id="TFA">
        <div
          className="d-flex p-3 ps-4 flex-column"
          style={{
            background:
              "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)",
            borderRadius: "25px",
            color: "#8C8C8C",
          }}
        >
          <div className="p-1 fw-bold text-white">
            Two-factor authentication
          </div>
          <div
            className="d-flex flex-row  mt-1 align-items-center"
            style={{ fontSize: "13px" }}
          >
            <div className="me-auto">Security keys</div>
            <div className="me-4">No Security keys</div>
            <div
              className="btn btn-primary p-1 ps-3 pe-3"
              onClick={() => {
                openRef.current.click();
                handleClick();
              }}
              style={{ width: "content-fit", height: "content-fit" }}
            >
              Enable
            </div>
          </div>
          <hr />
          <div
            className="d-flex flex-row mt-3 align-items-center"
            style={{ fontSize: "13px" }}
          >
            <div className="me-auto">Security keys</div>
            <div className="me-4">No Security keys</div>
            <div
              className="btn btn-primary p-1 ps-3 pe-3"
              style={{ width: "content-fit", height: "content-fit" }}
            >
              Add
            </div>
          </div>
          <hr />
          <div
            className="d-flex flex-row  mt-3 align-items-center"
            style={{ fontSize: "13px" }}
          >
            <div className="me-auto">Security keys</div>
            <div className="me-4">No Security keys</div>
            <div
              className="btn btn-primary p-1 ps-3 pe-3"
              style={{ width: "content-fit", height: "content-fit" }}
            >
              Add
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TFA;
