// CircularProgressBar.js
import React from 'react';

const Percentage = ({ total, highest }) => {
    const percentage = (parseFloat(total) / parseFloat(highest)) * 100;
    const integerPercentage = Math.floor(percentage);
    const radius = 80;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (integerPercentage / 100) * circumference;

    const gradientId = 'progressGradient';
    const gradientColors = ['#06A581', '#6bd8bf']; // Change these to your desired colors

    return (
        <svg width="40" height="40" viewBox="0 0 200 200">
          
            <defs>
                <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" style={{ stopColor: gradientColors[0] }} />
                    <stop offset="100%" style={{ stopColor: gradientColors[1] }} />
                </linearGradient>
            </defs>

           
            <circle
                cx="100"
                cy="100"
                r={radius}
                fill= 'transparent'
                stroke='white'
                strokeWidth="20"
            />
            <circle
                cx="100"
                cy="100"
                r={radius}
                fill="transparent"
                stroke={`url(#${gradientId})`} 
                strokeWidth="20"
                strokeDasharray={circumference}
                strokeDashoffset={offset}
            />
            <text x="50%" y="50%" textAnchor="middle" dy="0.3em" fontSize="40" fontWeight="600" fill="#FFFFFF">
                {total}
            </text>
        </svg>
    );
};

export default Percentage;
