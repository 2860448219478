import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// common data

const Table_1 = ({ commonData }) => {
  console.log(commonData);
  return (
    <>
      <div className="row res3 px-3">
        <div
          className="table-responsive-xxl scrollable-table   "
          style={{ overflowX: "scroll" }}
        >
          <style>
            {`

                        .no-scrollbar::-webkit-scrollbar {
                            display: none !important;
                        }

                        .example::-webkit-scrollbar {
                        display: none !important;
                        }

                        .example {
                        -ms-overflow-style: none !important; 
                        scrollbar-width: none !important;  
                        }
                         `}
          </style>
          {commonData && commonData.length !== 0 ? (
            <table className="table table-bordered table-top border">
              <thead className="sticky-top-1">
                <tr>
                  <th scope="col" class="text-white text-center py-3 px-4">
                    Common Number
                  </th>
                  <th scope="col" className="text-white text-center py-3">
                    Target Number
                  </th>
                </tr>
              </thead>
              <tbody>
                {commonData.map((commonItem, i) => (
                  <tr key={i}>
                    <td style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          textAlign: "center",
                        }}
                      >
                        <div className="text-primary fw-bold">
                          {commonItem.commanNumber}
                        </div>
                      </div>
                    </td>
                    <td style={{ padding: "0", border: "0" }}>
                      <div className="d-flex flex-row">
                        {commonItem.targetNumber.map((data, ind) => (
                          <div
                            className="table-responsive"
                            style={{ maxHeight: "400px" }}
                          >
                            <table
                              key={ind}
                              className="table table-bordered align-middle"
                              style={{ marginBottom: "0", width: "500px" }}
                            >
                              <thead style={{ background: "#0B0F2A" }}>
                                <tr>
                                  <th
                                    colSpan="6"
                                    className="text-white text-center fs-6"
                                  >
                                    {data.number}
                                  </th>
                                </tr>
                              </thead>
                              <thead
                                className=" text-primary sticky-top"
                                style={{
                                  fontSize: "13px",
                                  background: "#0B0F2A",
                                }}
                              >
                                <tr>
                                  <th
                                    scope="col"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    B_party No.
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Date&Time
                                  </th>

                                  <th
                                    scope="col"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Call Type
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Duration
                                  </th>
                                  <th
                                    scope="col"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    Location
                                  </th>
                                </tr>
                              </thead>
                              <tbody
                                className="text-white"
                                style={{ fontSize: "12px" }}
                              >
                                {data.callDetails.map((detail, indexx) => (
                                  <tr
                                    key={indexx}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <td
                                      className="py-3"
                                      scope="row"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      {detail.calledPhoneNumber || "NA"}
                                    </td>
                                    <td
                                      className="py-3"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      {detail.callDateTime || "NA"}
                                    </td>
                                    <td
                                      className="py-3"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      {detail.typeOfCall || "NA"}
                                    </td>
                                    <td
                                      className="py-3"
                                      style={{ whiteSpace: "nowrap" }}
                                    >
                                      {detail.duration || "NA"}
                                    </td>
                                    <td
                                      className="py-3"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip>
                                            {detail.towerAddress
                                              ? detail.towerAddress
                                              : "Not available"}
                                          </Tooltip>
                                        }
                                      >
                                        <i className="fa-solid fa-location-dot fs-5 text-primary"></i>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ))}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="text-center p-3 text-white fs-5 notfound fs-2">
              No Common Number Found
              <style>
                {`
                            .notfound{
                                color:red !important;
                            }
                            `}
              </style>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Table_1;
