import React from "react";
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";

import { useSelector } from "react-redux";
import moment from "moment"; // Import the moment library

function VisChart7() {
  const tableData = useSelector((state) => state.data);
  const extractedData = tableData.map((item) => ({
    b_party_number: item.b_party_number,
    first_call_date: moment(item.first_call_date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm"), // Convert moment object to string
    last_call_date: moment(item.last_call_date, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY HH:mm"), // Convert moment object to string
  }));

  const topFiveDates = extractedData.slice(0, 5);

  return (
    <div
      className="text-white align-items-center"
      style={{
        background:
          "linear-gradient(103.46deg, #080D2F 1.69%, rgba(8, 13, 47, 0.35) 99.32%)",
        borderRadius: "25px",
        textTransform: "capitalize",
      }}
    >
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={topFiveDates}>
          <XAxis dataKey="b_party_number" />
          <YAxis
            type="category" // Set Y-axis type to 'category'
            dataKey="first_call_date" // Use 'first_call_date' as Y-axis data
          />
          <Tooltip />
          <Legend />
          <Line dataKey="first_call_date" stroke="#8884d8" />
          <Line dataKey="last_call_date" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default VisChart7;
