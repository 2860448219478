import React, { useState, useRef } from "react";
import "./MultiSearch.css";
import "react-phone-number-input/style.css";
import { FaInfoCircle } from "react-icons/fa";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import
{
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";
import { toast, ToastContainer } from "react-toastify";
import "react-phone-number-input/style.css";

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const MultiNumSearch = () =>
{
  const [loading, setloading] = useState(false);
  const [numbers, setNumbers] = useState([""]);
  const [numberDetailsList, setNumberDetailsList] = useState([]);
  const [errorState, seterrorState] = useState(false);
  const handleChangeNumber = (event) =>
  {
    const inputValue = event.target.value;
    const splitNumbers = inputValue.split(",").map((num) =>
    {
      if (num.length >= 10)
      {
        return num.slice(-10).trim();
      }
    });
    setNumbers(splitNumbers);
    console.log(numbers);
  };

  // const removeNumber = (index) => {
  //   const newNumbers = numbers.filter((_, i) => i !== index);
  //   setNumbers(newNumbers);
  // };

  const handleRemoveNumber = (index) =>
  {
    const newNumbers = [...numbers];
    newNumbers.splice(index, 1); // Remove the number at the specified index
    setNumbers(newNumbers);
  };

  const searchNumbers = async () =>
  {
    console.log("number to send", numbers);

    const last10DigitsArray = numbers.map((number) =>
    {
      const numberString = number?.toString();
      return numberString?.slice(-10);
    });
    const numbersString = last10DigitsArray?.join(", ");
    console.log(numbersString);

    setloading(true);
    const apiResponse = await fetch(`${base_url}/user/number_info_bulk`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": sessionStorage.getItem("token"),
      },
      body: JSON.stringify({ mobile_numbers: numbersString }),
    });
    const numDetails = await apiResponse.json();
    console.log('-------------------number details lisr----------------------- ', numDetails)

    if (apiResponse.ok)
    {
      setNumberDetailsList(numDetails.data[0].data);
      if (numDetails.data[0].message === "Success")
      {
        toast.success(numDetails.data[0].message);
      }
      else
      {
        toast.error(numDetails.data[0].message);
      }
      setloading(false);
    } else
    {
      console.error("dlffdf", numDetails.data[0].message);
      // toast.error(apiResponse?.message)
      setloading(false);
    }
  };
  const textareaRef = useRef(null);
  const resetNumber = () =>
  {
    setNumbers([""]);
    if (textareaRef.current)
    {
      textareaRef.current.value = "";
    }
    console.log(numbers);
  };

  const handleUpiSearch = async (index) =>
  {
    console.log("clicked index", index);

    try
    {
      const extractedNumber = numbers[index]?.slice(-10);
      setloading(true);

      // Fetch UPI details
      const upiResponse = await fetch(`${base_url}/user/upi_details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({ mobileNumber: extractedNumber }),
      });

      if (upiResponse.ok)
      {
        const upiDetailsData = await upiResponse.json();
        // Assuming the UPI details are in the `data.data.upiDetailsList` of the response
        const upiDetails = upiDetailsData?.data?.upiDetailsList;
        console.log("++++++++++++++upidetails=============   ", upiDetails);

        numberDetailsList[index] = {
          ...numberDetailsList[index],
          upiDetails: upiDetails,
        };

        console.log("!!!!!!!!!!!number details list after upi Details___________", numberDetailsList)

        toast.success("Successfully fetched UPI details");
      } else
      {
        toast.error("Failed to fetch UPI details");
        throw new Error("Failed to fetch UPI details");
      }
    } catch (error)
    {
      toast.error(error.message);
      console.error(error.message);
    } finally
    {
      setloading(false);
    }
  };

  const fetchBankAddress = async (upiDetail, upiIndex, index) =>
  {
    console.log(
      numberDetailsList[index]?.upiDetails[upiIndex]?.bankIfsc
    );
    const ifsc =
      numberDetailsList[index]?.upiDetails[upiIndex]?.bankIfsc;
    try
    {
      setloading(true);
      const response = await fetch(`https://ifsc.razorpay.com/${ifsc}`);
      const data = await response.json();
      console.log("API Response: ", data);

      const fullAddress = `${data.BRANCH}, ${data.CENTRE}, ${data.DISTRICT}, ${data.STATE}, ${data.ADDRESS}, ${data.CONTACT}, ${data.CITY}, ${data.MICR}, ${data.BANK}, ${data.BANKCODE}, ${data.IFSC}`;
      console.log("full address ", fullAddress);

      console.log(
        "ifsc index",
        numberDetailsList[index].upiDetails[upiIndex]
      );
      numberDetailsList[index].upiDetails[upiIndex] = {
        ...numberDetailsList[index].upiDetails[upiIndex],
        bankDetails: data,
      };
      setloading(false);
    } catch (error)
    {
      setloading(false);
      console.error("Error fetching bank address:", error);
      toast.error("Failed to fetch bank address");
    }
  };

  return (
    <div
      className="res3 row p-2 justify-content-center  "
      style={{
        background:
          "linear-gradient(to top left, #161633 5%, transparent 53%), linear-gradient(to top right, #161633 5%, transparent 53%), linear-gradient(to bottom left, #161633 5%, transparent 53%), linear-gradient(to bottom right, #161633 5%, transparent 53%), radial-gradient(circle, #5F67A6, #2d3085)",
      }}>
      {/* ---------------------------------------------------------Loading--------------------------------------------------------- */}
      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}>
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">loading...</div>
        </div>
      )}

      <div className=" mb-0 mx-auto  ">
        <div class=" col-lg-12 col-xl-8 col-md-12 col-sm-8 mx-auto">
          <div
            class=" col-lg-8 col-md-12 col-sm-12 mx-auto  "
            style={{
              border: "1px solid white",
              borderRadius: "20px",
              backgroundColor: "#0F1535",
            }}>
            <div className="search-header  text-white text-center pt-2 mb-4 mt-3 mx-auto ">
              <h2>Multi-Number Search</h2>
            </div>

            {/*-------------------------------------- input search box --------------------------------------  */}
            <div
              class="mx-auto p-3 bg-white"
              style={{ width: "80%", borderRadius: "10px" }}>
              <p class="text-black" style={{ fontSize: "22px" }}>
                <strong>Phone Numbers</strong>
              </p>

              <style>
                {`.PhoneInputInput{
                      color:black !important;
                      background-color:white
                        }`}
              </style>

              <textarea
                onChange={handleChangeNumber}
                ref={textareaRef}
                placeholder=" Please put comma separated number here...
                              Ex:- 9768XXXXXX,1400XXXXXX,1400XXXXXX"
                style={{
                  color: "black",
                  backgroundColor: "white",
                  width: "100%",
                  height: "200px",
                  fontSize: "17px",
                  fontWeight: "500",
                }}
              />
              {/* {showNumbers && (
                <div class=" border p-3 mt-3 flex flex-wrap" style={{ height: '150px', overflowY: 'scroll', borderRadius: '10px' }}>
                  {numbers.map((number, index) => (
                    <p key={index}>Phone No.  {index + 1} : {number}</p>
                  ))}
                </div>
              )} */}
            </div>
            <div className="d-flex  mb-4 ">
              {/* <button className="btn btn-secondary me-2 ms-auto " onClick={numberArrange}
                style={{ borderRadius: '15px', maxWidth: "180px", }}
              >
                Arrange
              </button> */}
              <button
                className="btn btn-secondary me-2 ms-auto "
                onClick={resetNumber}
                style={{ borderRadius: "15px", maxWidth: "180px" }}>
                Reset
              </button>
              <button
                className="btn btn-primary  mx-auto "
                onClick={searchNumbers}
                style={{ borderRadius: "15px", maxWidth: "180px" }}
                disabled={errorState}>
                Search Number
              </button>
            </div>
          </div>
        </div>

        {/* ------------------------Display multiple search results------------------------ */}
        <div class="" style={{ width: "auto" }}>
          <div class=" row">
            {numberDetailsList &&
              numberDetailsList.map((detail, index) =>
              {
                const { value } = detail; // Destructure the value object from detail
                return (
                  <div
                    className="modal-content mx-auto mt-5 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                    style={{
                      background:
                        "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 1) 100%)",
                      boxShadow: "2px 2px 6px 0 rgba(0, 0, 0, 0.15)",
                      border: "3px solid rgba(0, 88, 191, 1)",
                      borderRadius: "12px",
                      maxWidth: "650px",
                      maxHeight: "900px",
                    }}
                    key={index} // Add a unique key for each element in the map
                  >
                    <div className="modal-body text-center">
                      <div className="CDR-row px-3 pb-4 p-3 pt-2 ">
                        <div
                          className="table-responsive "
                          style={{ maxHeight: "700px" }}>
                          <div className="d-flex flex-column align-items-start text-white p-2 ">
                            <strong className="p-2 fs-3 ">{detail?.name}</strong>

                            <div className="w-100 d-flex align-items-center justify-content-between ">
                              <div className="d-flex flex-column align-items-start justify-content-center p-2">
                                <div className="d-flex align-items-center gap-2  p-2">
                                  <p
                                    className="mb-0 mr-2"
                                    style={{
                                      color: "rgba(206, 206, 206, 1)",
                                      fontSize: "15px",
                                    }}>
                                    Mobile No. :{" "}
                                  </p>{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    {detail?.phones?.[0]?.e164Format || "N/A"}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center gap-2  p-2">
                                  <p
                                    className="mb-0 mr-2"
                                    style={{
                                      color: "rgba(206, 206, 206, 1)",
                                      fontSize: "15px",
                                    }}>
                                    Company. :{" "}
                                  </p>{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    {detail?.phones?.[0]?.carrier || "N/A"}
                                  </span>
                                </div>
                              </div>
                              <div className="d-flex flex-column align-items-start justify-content-center ">
                                <div className="d-flex align-items-center gap-2  p-2">
                                  <p
                                    className="mb-0 mr-2"
                                    style={{
                                      color: "rgba(206, 206, 206, 1)",
                                      fontSize: "15px",
                                    }}>
                                    Number Type :{" "}
                                  </p>{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    {detail?.phones?.[0]?.numberType || "N/A"}
                                  </span>
                                </div>
                                <div className="d-flex align-items-center gap-2  p-2">
                                  <p
                                    className="mb-0 mr-2"
                                    style={{
                                      color: "rgba(206, 206, 206, 1)",
                                      fontSize: "15px",
                                    }}>
                                    Country Code :{" "}
                                  </p>{" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    {detail?.addresses?.[0]?.countryCode ||
                                      "N/A"}
                                  </span>
                                </div>
                              </div>
                            </div>

                            {/* Display addresses */}
                            {detail?.addresses &&
                              detail?.addresses?.map((address, addressIndex) => (
                                <div
                                  key={addressIndex}
                                  className="d-flex flex-column w-100 ">
                                  <hr />
                                  <strong
                                    className="p-2"
                                    style={{ color: "rgba(206, 206, 206, 1)" }}>
                                    {" "}
                                    Address:{" "}
                                  </strong>
                                  <div className="w-100 d-flex align-items-center justify-content-between">
                                    <div className="d-flex flex-column align-items-start justify-content-center p-2">
                                      <div className="d-flex align-items-center gap-2  p-2">
                                        <p
                                          className="mb-0 mr-2"
                                          style={{
                                            color: "rgba(206, 206, 206, 1)",
                                            fontSize: "15px",
                                          }}>
                                          City :{" "}
                                        </p>{" "}
                                        <span style={{ fontWeight: "bold" }}>
                                          {" "}
                                          <span>{address?.city || "N/A"}</span>
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center gap-2  p-2">
                                        <p
                                          className="mb-0 mr-2"
                                          style={{
                                            color: "rgba(206, 206, 206, 1)",
                                            fontSize: "15px",
                                          }}>
                                          Country :{" "}
                                        </p>{" "}
                                        <span>
                                          {" "}
                                          <span style={{ fontWeight: "bold" }}>
                                            {address?.countryCode || "N/A"}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="d-flex flex-column align-items-start justify-content-center ">
                                      <div className="d-flex align-items-center gap-2  p-2">
                                        <p
                                          className="mb-0 mr-2"
                                          style={{
                                            color: "rgba(206, 206, 206, 1)",
                                            fontSize: "15px",
                                          }}>
                                          Time zone :{" "}
                                        </p>{" "}
                                        <span>
                                          {" "}
                                          <span style={{ fontWeight: "bold" }}>
                                            {address?.timeZone || "N/A"}
                                          </span>
                                        </span>
                                      </div>
                                      <div className="d-flex align-items-center gap-2  p-2">
                                        <p
                                          className="mb-0 mr-2"
                                          style={{
                                            color: "rgba(206, 206, 206, 1)",
                                            fontSize: "15px",
                                          }}>
                                          Access :
                                        </p>
                                        <span>
                                          <span style={{ fontWeight: "bold" }}>
                                            {detail?.access || "N/A"}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}

                            {/* Display internet addresses */}
                            {detail?.internetAddresses &&
                              detail?.internetAddresses?.map(
                                (internetAddress, intIndex) => (
                                  <div key={intIndex} style={{ width: "100%" }}>
                                    <hr />
                                    <strong
                                      className="p-2  mx-auto"
                                      style={{
                                        color: "rgba(206, 206, 206, 1)",
                                      }}>
                                      {" "}
                                      Contact Info {intIndex + 1} :
                                    </strong>

                                    <div className="d-flex align-items-center justify-content-between">
                                      <div
                                        className="d-flex flex-column align-items-start justify-content-center p-2"
                                        style={{ width: "100%" }}>
                                        <div
                                          className="d-flex  flex-row align-items-center gap-2  p-2"
                                          style={{ width: "100%" }}>
                                          <div className="d-flex flex-row">
                                            <p
                                              className="mb-0 mr-2"
                                              style={{
                                                color: "rgba(206, 206, 206, 1)",
                                                fontSize: "15px",
                                              }}>
                                              ID :{" "}
                                            </p>{" "}
                                            <p
                                              style={{
                                                fontWeight: "bold",
                                                display: "flex",
                                                flexDirection: "row",
                                              }}>
                                              {" "}
                                              <p>
                                                {internetAddress?.id || "N/A"}{" "}
                                              </p>{" "}
                                            </p>
                                          </div>
                                        </div>
                                        <div
                                          className="d-flex align-items-center gap-2  p-2"
                                          style={{ marginTop: "-2.25rem" }}>
                                          <p
                                            className="mb-0 mr-2"
                                            style={{
                                              color: "rgba(206, 206, 206, 1)",
                                              fontSize: "15px",
                                            }}>
                                            Service :{" "}
                                          </p>{" "}
                                          <span>
                                            {" "}
                                            <span>
                                              <span
                                                style={{ fontWeight: "bold" }}>
                                                {internetAddress?.service ||
                                                  "N/A"}
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}

                            <button
                              onClick={() =>
                              {
                                handleUpiSearch(index);
                              }}
                              className="btn btn-primary mx-auto "
                              style={{ borderRadius: "10px", width: "100%" }}>
                              UPI Search
                            </button>
                            <div class=" row  mx-auto gap-2 ">
                              <style>
                                {`
                  .upibox{
                      border:3px solid rgba(0, 88, 191, 1) !important;
                  }
                `}
                              </style>

                              {detail?.upiDetails?.length > 0 ? (
                                detail?.upiDetails?.map(
                                  (upiDetail, upiIndex) =>
                                  {
                                    console.log("upi index.........", upiIndex);
                                    return (
                                      <div
                                        class="mt-5 text-start border upibox p-3"
                                        style={{ borderRadius: "10px" }}
                                        key={index}>
                                        <p>Name: {upiDetail?.name}</p>
                                        <p>UPI ID: {upiDetail?.upiId}</p>
                                        <p>PSP Name: {upiDetail?.pspName}</p>
                                        <p>
                                          Bank IFSC: {upiDetail?.bankIfsc}
                                          <span
                                            onClick={() =>
                                              fetchBankAddress(
                                                upiDetail,
                                                upiIndex,
                                                index
                                              )
                                            }
                                            style={{
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              color: "blue",
                                            }}>
                                            <FaInfoCircle /> Get Details
                                          </span>
                                        </p>
                                        {numberDetailsList[index].upiDetails[upiIndex]
                                          ?.bankDetails && (
                                            <p>
                                              Bank Address:{" "}
                                              {`
                                        ${numberDetailsList[index]?.upiDetails[upiIndex]?.bankDetails?.BRANCH}, 
                                        ${numberDetailsList[index]?.upiDetails[upiIndex]?.bankDetails?.CENTRE},
                                         ${numberDetailsList[index]?.upiDetails[upiIndex]?.bankDetails?.DISTRICT}, 
                                        ${numberDetailsList[index]?.upiDetails[upiIndex]?.bankDetails?.STATE},
                                         ${numberDetailsList[index]?.upiDetails[upiIndex]?.bankDetails?.ADDRESS},
                                         ${numberDetailsList[index]?.upiDetails[upiIndex]?.bankDetails?.CONTACT},
                                         ${numberDetailsList[index]?.upiDetails[upiIndex]?.bankDetails?.CITY},
                                         ${numberDetailsList[index]?.upiDetails[upiIndex]?.bankDetails?.MICR}, 
                                        ${numberDetailsList[index]?.upiDetails[upiIndex]?.bankDetails?.BANK}, 
                                        ${numberDetailsList[index]?.upiDetails[upiIndex]?.bankDetails?.BANKCODE}, 
                                        ${numberDetailsList[index]?.upiDetails[upiIndex]?.bankDetails?.IFSC}`}
                                            </p>
                                          )}

                                        <p>Code: {upiDetail.code}</p>
                                        <p>Payee Type: {upiDetail.payeeType}</p>
                                      </div>
                                    );
                                  }
                                )
                              ) : (
                                <div
                                  class="mt-3 text-start  "
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: "bold",
                                  }}>
                                  {" "}
                                  Click to find UPI details{" "}
                                </div>
                              )}
                            </div>
                            {/* <div class="mt-3 text-start ">Click to find UPI details</div> :  */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiNumSearch;
