import React, { useState, useEffect } from "react";

function DynamicCounter({ startTime }) {
  const [counter, setCounter] = useState({
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const startDate = new Date(startTime);

    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const timeDiff = currentTime - startDate;

      const years = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 365));
      const months = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24 * 365)) / (1000 * 60 * 60 * 24 * 30)
      );
      const days = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
      );
      const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      setCounter({ years, months, days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [startTime]);

  return (
    <div>
      {counter.years > 0 && (
        <span>
          {counter.years} Year{counter.years > 1 ? "s" : ""}{" "}
        </span>
      )}
      {(counter.months > 0 || (counter.days > 30 && counter.months === 0)) && (
        <span>
          {counter.months} Month{counter.months > 1 ? "s" : ""}{" "}
        </span>
      )}
      {counter.days > 30 && (
        <span>
          {counter.days} Day{counter.days > 1 ? "s" : ""}{" "}
        </span>
      )}
      <span>
        {counter.hours} Hour{counter.hours > 1 ? "s" : ""}{" "}
      </span>
      <span>
        {counter.minutes} Minute{counter.minutes > 1 ? "s" : ""}{" "}
      </span>
      <span>
        {counter.seconds} Second{counter.seconds > 1 ? "s" : ""}{" "}
      </span>
    </div>
  );
}

export default DynamicCounter;
