import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import ProfileHeader from "./ProfileHeader";
import Swal from "sweetalert2";
const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const Protected = ({ Component, pagename }) =>
{
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() =>
  {
    const fetchData = async () =>
    {
      const token = sessionStorage.getItem("token");
      if (token)
      {
        try
        {
          const response = await fetch(`${base_url}/user/info`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": sessionStorage.getItem("token"),
            },
          });
          const data = await response.json();
          if (data.success === false)
          {
            navigate("/signin");
            return;
          }
        } catch (error)
        {
          throw error;
        }

        const tokenData = JSON.parse(atob(token.split(".")[1]));
        console.log(tokenData);
        const expirationTime = tokenData.exp * 1000;
        console.log("token expiration time", expirationTime);
        if (expirationTime < Date.now())
        {
          await Swal.fire({
            title: "Your session has been expired, Please Login Again",
            width: 600,
            padding: "1em",
            color: "#fff",
            fontSize: "14px !important",
            background:
              "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 1) 100%)",
            backdrop: `
                            linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)
                            url("/images/nyan-cat.gif")
                            left top
                            no-repeat
                        `,
          });
          sessionStorage.removeItem("token");
          navigate("/signin");
        } else
        {
          setLoading(false);
        }
      } else
      {
        setLoading(false);
        navigate("/signin");
      }
    };

    fetchData();
  }, [navigate]);

  const allowedPagenameValues = [
    "Dashboard",
    "Profile",
    "Activity",
    "Case Management/Add case",
  ];

  return (
    <>
      {loading ? (
        <div className="text-white">Loading...</div>
      ) : (
        <>
          <Sidebar pagename={pagename} />
          {allowedPagenameValues.includes(pagename) ? (
            <ProfileHeader pagename={pagename} />
          ) : null}
          <Component />
        </>
      )}
    </>
  );
};

export default Protected;
