import React, { useState } from "react";

const IPDRAnalysis = () => {
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [parsedData, setParsedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setFile(droppedFile);
      processCSVData(droppedFile);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      processCSVData(selectedFile);
    }
  };

  const formatSize = (size) => {
    const units = ["B", "KB", "MB", "GB"];
    let index = 0;
    while (size >= 1024 && index < units.length - 1) {
      size /= 1024;
      index++;
    }
    return size.toFixed(2) + " " + units[index];
  };

  const processCSVData = (csvFile) => {
    setLoading(true);
    const fileReader = new FileReader();

    fileReader.onload = async (e) => {
      try {
        const csvData = e.target.result;

        // Parse CSV data
        const df = parseCSV(csvData);

        // Extract headers and data
        const headers = df[0];
        const data = df.slice(1).map((row) => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = row[index];
          });
          return obj;
        });

        // Log parsed objects
        console.log("Parsed Objects:", data);

        // Get unique IP addresses from the parsed data
        const uniqueIps_public = [
          ...new Set(data.map((row) => row["Public IP Address"])),
        ];
        const uniqueIps_destination = [
          ...new Set(data.map((row) => row["Destination IP Address"])),
        ];

        const ipDetailsPromises_public = uniqueIps_public.map(async (ip) => {
          try {
            const ipDetailsResponse = await fetch(
              `http://ip-api.com/json/${ip}`
            );
            const ipDetails = await ipDetailsResponse.json();
            return { ip, ...ipDetails };
          } catch (e) {
            console.error(`Error fetching details for IP ${ip}:`, e);
            return {
              query: ip,
              country: "N/A",
              regionName: "N/A",
              city: "N/A",
            };
          }
        });

        const ipDetailsPromises_destination = uniqueIps_destination.map(
          async (ip) => {
            try {
              const ipDetailsResponse = await fetch(
                `http://ip-api.com/json/${ip}`
              );
              const ipDetails = await ipDetailsResponse.json();
              return { ip, ...ipDetails };
            } catch (e) {
              console.error(`Error fetching details for IP ${ip}:`, e);
              return {
                query: ip,
                isp: "N/A",
              };
            }
          }
        );

        // Wait for all IP details requests to complete
        const ipDetailsData_public = await Promise.all(
          ipDetailsPromises_public
        );
        const ipDetailsData_destination = await Promise.all(
          ipDetailsPromises_destination
        );

        // Log IP details data
        console.log("IP Details (Public):", ipDetailsData_public);
        console.log("IP Details (Destination):", ipDetailsData_destination);

        // Merge API data with parsed CSV data
        const mergedData = data.map((row) => {
          const publicIPDetails = ipDetailsData_public.find(
            (ipData) => ipData.ip === row["Public IP Address"]
          );
          const destinationIPDetails = ipDetailsData_destination.find(
            (ipData) => ipData.ip === row["Destination IP Address"]
          );
          return {
            ...row,
            PublicIPCountry: publicIPDetails?.country || "N/A",
            PublicIPRegion: publicIPDetails?.regionName || "N/A",
            PublicIPCity: publicIPDetails?.city || "N/A",
            DestinationIPISP: destinationIPDetails?.isp || "N/A",
          };
        });

        console.log("Merged Data:", mergedData);

        setParsedData(mergedData);
      } catch (e) {
        console.error("An error occurred:", e);
        setError("An error occurred while processing the CSV data.");
        setParsedData([]);
      } finally {
        setLoading(false);
      }
    };

    fileReader.readAsText(csvFile);
  };

  // Helper function to parse CSV data into an array of arrays
  const parseCSV = (csvData) => {
    const lines = csvData.split("\n");
    return lines.map((line) => line.split(","));
  };

  return (
    <div className="res3 row p-3">
      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">loading...</div>
        </div>
      )}
      {parsedData.length === 0 && (
        <div
          className="p-4"
          style={{
            background:
              "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)",
            borderRadius: "20px",
          }}
        >
          <div
            className={`drag-area ${dragging ? "active" : ""} rounded-3`}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <div className="mb-3">
              <label htmlFor="fileInput" className="custom-circle-upload">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                  className="circle-icon"
                >
                  <circle
                    cx="50"
                    cy="50"
                    r="40"
                    stroke="rgba(142, 165, 201, 0.15)"
                    strokeWidth="3"
                    fill="transparent"
                  />
                </svg>
                <i
                  className="fa-solid fa-file-export fs-4"
                  style={{
                    position: "relative",
                    right: "58px",
                    top: "6px",
                  }}
                ></i>
              </label>
              <h6 style={{ color: "rgba(255, 255, 255, 1)" }}>
                Drag & Drop a File Here
              </h6>
              <input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
            {file && (
              <div
                style={{
                  color: "rgba(140, 140, 140, 0.58)",
                  fontSize: "14px",
                }}
                className="mb-2"
              >
                <strong>{file.name}</strong> ({formatSize(file.size)})
              </div>
            )}
          </div>
        </div>
      )}
      <div
        className="mx-2 mt-4"
        style={{ borderRadius: "12px", backgroundColor: "rgb(9 9 87)" }}
      >
        <h2 className="text-white my-3 w-full">IPDR Details:</h2>
        <div
          className="table-responsive p-4"
          style={{
            maxHeight: "700px",
            background:
              "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)",
          }}
        >
          {error && <p className="text-danger">{error}</p>}
          {parsedData.length > 0 ? (
            <table
              className="table mt-2 table-bordered border-secondary align-middle"
              style={{ maxHeight: "500px" }}
            >
              <thead className="table_cdr fs-5 sticky-top table-header-sticky">
                <tr>
                  <th scope="col">S.No</th>
                  {Object.keys(parsedData[0]).map((key) => (
                    <th key={key} scope="col">
                      {key}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody
                className="text-white"
                style={{ fontSize: "12px", background: "#080D2F" }}
              >
                {parsedData.map((row, index) => (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <td className="py-3">{index + 1}</td>
                    {Object.values(row).map((value, i) => (
                      <td key={i} className="py-3">
                        {value || "N/A"}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-white">
              {loading ? (
                ""
              ) : (
                <div className="alert alert-danger mt-4 w-full" role="alert">
                  Please Upload the File to get IPDR Details
                </div>
              )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default IPDRAnalysis;
