import React from "react";
import Welcome from "./Welcome";
import Profileinfo from "./Profileinfo";
import ProfileSetting from "./ProfileSetting";
import "./dashboard.css";
import CaseInfo from "./CaseInfo";
import Chart1 from "./Chart1";

function Dashboard() {
  return (
    <div className="container mt-2 res">
      <div className="row mt-3">
        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-3 mb-2">
          <Welcome />
        </div>
        <div className="col-sm-12 col-md-12 col-lg-8 col-xl-6 mb-1">
          <CaseInfo />
        </div>
        <div className="col-sm-6 col-md-12 col-lg-12 col-xl-3  mb-1">
          <Profileinfo />
        </div>
        <div className="col-sm-6 col-md-12 col-lg-6 col-xl-3 mb-1">
          {/* <ProfileSetting /> */}
        </div>
        <div className="col-md-12 col-lg-12 col-xl-12 mb-1">
          <Chart1 />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
