import React from "react";

const AiAnalysis = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "80vh",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "50px",
        fontWeight: "bold",
        color: "orange",
      }}>
      Coming Soon.....
    </div>
  );
};
export default AiAnalysis;
