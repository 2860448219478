import React from "react";
import { useNavigate } from "react-router-dom";
import img1 from "../img/1.jpg";
const CardContent = (props) => {
  const navigate = useNavigate();

  const goToCaseDetails = (data) => {
    const _id = data._id
    navigate(`/CaseManagement/caseDetails/${_id}`);
  };


  return (
    <>
      <div
        className="card sticky-top sticky-case"
        style={{
          borderRadius: "18px",
          background: "rgba(11, 15, 42, 1)",
          height: "content-fit",
          minHeight: "100vh",
          width: "auto",
        }}>
        <div className="card-body p-4">
          <span
            className="card-text"
            style={{
              fontSize: "10px",
              fontWeight: "600",
              color: "rgba(140, 140, 140, 1)",
            }}>
            case#{props.data.caseNumber}
          </span>
          <img
            className="card-img-top mt-1"
            style={{ width: "100%", borderRadius: "15px" }}
            src={img1}
            alt="Card"
          />
          <h5
            className="card-title mt-4"
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "rgba(255, 255, 255, 1)",
            }}>
            {props.data.title}
          </h5>
          <p
            className="card-text"
            style={{
              fontSize: "12px",
              fontWeight: "500",
              color: "rgba(140, 140, 140, 1)",
            }}>
            {props.data.description}
          </p>
          <p
            className="card-text text-white"
            style={{ fontSize: "14px", fontWeight: "700" }}>
            Info
          </p>
          <p
            className="card-text"
            style={{
              fontSize: "12px",
              fontWeight: "500",
              color: "rgba(140, 140, 140, 1)",
            }}>
            Date:  <span className="ms-2 text-white">
              {props.data.reportDate
                ? new Date(props.data.reportDate).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
                : "NA"}
            </span>{" "}
          </p>
          <p
            className="card-text"
            style={{
              fontSize: "12px",
              fontWeight: "500",
              color: "rgba(140, 140, 140, 1)",
            }}>
            Size:{" "}
            <span className="ms-2 text-white">
              {props.data.size ? props.data.size : "NA"}
            </span>{" "}
          </p>
          <div
            type="submit"
            onClick={() => { goToCaseDetails(props.data) }}
            style={{ maxWidth: "fit-content", fontSize: "13px" }}
            className="btn btn-primary  mt-3 float-end ms-auto rounded-3">
            View Details
          </div>
        </div>
      </div>
    </>
  );
};

export default CardContent;
