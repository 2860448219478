import { createSlice } from '@reduxjs/toolkit';

const mapDataSlice = createSlice({
    name: 'mapData',
    initialState: null,
    reducers: {
        setMapData: (state, action) => {
            return action.payload;
        },
    },
});

export const { setMapData } = mapDataSlice.actions;

export default mapDataSlice .reducer;
