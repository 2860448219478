import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const FaceMatchSearch = () => {
  const [imageFirst, setImageFirst] = useState("");
  const [imageSecond, setImageSecond] = useState("");
  const [faceMatchData, setFaceMatchData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const imageFirst = location?.state?.imageFirst;
    setImageFirst(imageFirst);
  }, [location]);

  const searchFaceByUrl = async () => {
    if (!imageFirst) {
      toast.error("Please enter an image URL first!");
      return;
    }

    setLoading(true);
    setSearchPerformed(true);

    try {
      const response = await fetch(`${base_url}/user/get_face_match`, {
        method: "POST",
        headers: {
          "x-auth-token": sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ faceString: `${imageFirst},${imageSecond}` }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data?.data?.result) {
          setFaceMatchData(data?.data);
        } else {
          setFaceMatchData(data?.data?.data);
        }

        console.log("data.data", data?.data);
        toast.success("Successfully fetched results");
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Failed to fetch results");
      }
    } catch (error) {
      toast.error("Failed to fetch results");
      console.error("Error during search:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetSearch = () => {
    setImageFirst("");
    setImageSecond("");
    setFaceMatchData(null);
    setLoading(false);
    setSearchPerformed(false);
  };
  console.log("faceMatchData", faceMatchData);

  return (
    <>
      <ToastContainer />

      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 99999999,
          }}
        >
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">Loading...</div>
        </div>
      )}

      <div className="res3 row p-2 justify-content-center text-white">
        <div className="mb-0 mx-auto">
          <div className="mx-auto col-xl-6 mb-3">
            <div
              className="col-lg-8 col-md-12 col-sm-12 mx-auto col-xl-8"
              style={{
                border: "1px solid white",
                borderRadius: "20px",
                backgroundColor: "#0F1535",
              }}
            >
              <div className="search-header text-white text-center py-2 mt-3">
                <div className="input-group mb-3 align-items-center d-flex flex-column">
                  <h2 className="fw-bold">Face Match Search</h2>
                  {!imageFirst && (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter image first"
                      value={imageFirst}
                      onChange={(e) => setImageFirst(e.target.value)}
                      style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        maxWidth: "80%",
                        borderRadius: "5px",
                        border: "2px solid #ddd",
                        padding: "10px",
                        width: "90%",
                      }}
                    />
                  )}
                  {imageFirst && (
                    <img
                      src={imageFirst}
                      alt="Face to match"
                      style={{ width: "60%" }}
                    />
                  )}
                  {!imageSecond && (
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter image Second"
                      value={imageSecond}
                      onChange={(e) => setImageSecond(e.target.value)}
                      style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        maxWidth: "80%",
                        borderRadius: "5px",
                        border: "2px solid #ddd",
                        padding: "10px",
                        width: "90%",
                      }}
                    />
                  )}
                  {imageSecond && (
                    <img
                      src={imageSecond}
                      alt="Face to match"
                      style={{ width: "60%" }}
                    />
                  )}
                  <button
                    onClick={searchFaceByUrl}
                    className="btn btn-primary"
                    style={{
                      maxWidth: "200px",
                      borderRadius: "15px",
                      fontSize: "13px",
                      marginTop: "10px",
                    }}
                  >
                    Face Match
                  </button>
                  {(imageFirst || imageSecond) && (
                    <button
                      onClick={resetSearch}
                      className="btn btn-primary"
                      style={{
                        maxWidth: "200px",
                        borderRadius: "15px",
                        fontSize: "13px",
                        marginTop: "10px",
                      }}
                    >
                      Reset
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-wrap">
            {searchPerformed && !loading && faceMatchData && (
              <div className="col-lg-8 mx-auto">
                <div
                  className="card"
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "15px",
                    padding: "15px",
                    marginBottom: "20px",
                    backgroundColor: "#0F1535",
                    color: "#fff",
                  }}
                >
                  <h3>Face Match Results</h3>
                  {/* <div className="d-flex justify-content-between">
                    <div className="image-container">
                      <img
                        src={faceMatchData?.data?.essentials?.firstImage}
                        alt="First_Image"
                        style={{ width: "100%", borderRadius: "15px" }}
                      />
                      <img
                        src={faceMatchData?.data?.essentials?.secondImage}
                        alt="Second_Image"
                        style={{
                          width: "100%",
                          borderRadius: "15px",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                  </div> */}
                  <div className="match-info mt-3">
                    {/* <p>Threshold: {faceMatchData.data.essentials.threshold}</p> */}
                    {/* <p>
                      Align Horizontally:{" "}
                      {faceMatchData.data.essentials.alignHorizontally}
                    </p> */}
                    {/* <p>Reorient: {faceMatchData.data.essentials.reorient}</p> */}
                    <p>
                      Match Percentage: {faceMatchData?.result?.matchPercentage}
                    </p>
                    <p>
                      Verified: {faceMatchData?.result?.verified ? "Yes" : "No"}
                    </p>
                    <p>Message: {faceMatchData?.result?.message}</p>
                  </div>
                  {/* <div className="other-info mt-3">
                    <p>Target Image URL: {faceMatchData.targetImage}</p>
                    <p>
                      Created At:{" "}
                      {new Date(faceMatchData.createdAt).toLocaleString()}
                    </p>
                    <p>
                      Updated At:{" "}
                      {new Date(faceMatchData.updatedAt).toLocaleString()}
                    </p>
                    <p>Patron ID: {faceMatchData.data.patronId}</p>
                  </div> */}
                </div>
              </div>
            )}

            {searchPerformed && !loading && !faceMatchData && (
              <div
                className="text-center text-danger mt-3 mx-auto"
                style={{ fontSize: "35px", fontWeight: "bold" }}
              >
                <p>No results found.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FaceMatchSearch;
