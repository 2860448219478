import { createSlice } from "@reduxjs/toolkit";

const completeDataSlice = createSlice({
  name: "completeData",
  initialState: [],
  reducers: {
    setCompleteData: (state, action) => {
      return [...action.payload];
    },
    filterDataByDateAndTime: (state, action) => {
      const { fromDate, fromTime, toDate, toTime } = action.payload;

      if (!fromDate && !fromTime && !toDate && !toTime) {
        return state;
      }
      const fromParts = fromDate.split("/").reverse().join("-");
      const toParts = toDate.split("/").reverse().join("-");
      return state.filter((item) => {
        const itemDateParts = item.callDate.split("/").reverse().join("-");
        const itemTime = new Date(item.callTime);

        const fromDateTime = new Date(`${fromParts}T${fromTime}`);
        const toDateTime = new Date(`${toParts}T${toTime}`);
        const dateInRange =
          itemDateParts >= fromParts && itemDateParts <= toParts;
        const timeInRange = itemTime >= fromDateTime && itemTime <= toDateTime;

        return dateInRange && timeInRange;
      });
    },
  },
});

export const { setCompleteData, filterDataByDateAndTime } =
  completeDataSlice.actions;
export default completeDataSlice.reducer;
