import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import dataReducer from "./dataSlice";
import commonDataReducer from "./commonDataSlice";
import locationDataReducer from "./locationDataSlice";
import smsDataReducer from "./smsDataSlice";
import durationSortedDataReducer from "./durationSlice";
import completeDataReducer from "./completeDataSlice";
import mapDataReducer from "./mapDataSlice";
import targetNoDataReducer from "./targetNoDataSlice";
import filteredDataReducer from "./FilteredSlice";
import filteredMapDataReducer from "./filteredMapDataSlice";
import imeiDataReducer from "./imeiDataSlice";
import multiCompleteDataReducer from "./multiCompleteDataSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    data: dataReducer,
    commonData: commonDataReducer,
    locationData: locationDataReducer,
    smsData: smsDataReducer,
    durationSortedData: durationSortedDataReducer,
    completeData: completeDataReducer,
    mapData: mapDataReducer,
    targetNoData: targetNoDataReducer,
    filteredData: filteredDataReducer,
    filteredMapData: filteredMapDataReducer,
    imeiData: imeiDataReducer,
    multiCompleteDataRedux: multiCompleteDataReducer,
  },
});

export default store;
