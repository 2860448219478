import React, { useEffect, useRef, useState } from "react";
import img1 from "../img/1.jpg";
import CaseUpdate from "./CaseUpdate";
import UpdateHistory from "./UpdateHistory";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setTargetNoData } from "../../store/targetNoDataSlice";
import toast from "react-hot-toast";

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const DetailedCase = ({ _id }) => {
  const [loadingApiData, setLoadingApiData] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationRef = useRef(null);
  console.log("case id", _id);
  const handleApiCallAndRedirect = async (targetNumber) => {
    try {
      setLoadingApiData(true);
      const encodedTargetNumber = encodeURIComponent(targetNumber);
      const encodedCaseNumber = encodeURIComponent(data.caseNumber);

      const apiCallUrl = `${base_url}/data_analysis_from_db?target_number=${encodedTargetNumber}&case_number=${encodedCaseNumber}`;

      const apiResponse = await fetch(apiCallUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": sessionStorage.getItem("token"),
        },
      });

      if (!apiResponse.ok) {
        throw new Error("API call failed");
      }
      const jsonData = await apiResponse.json();
      console.log(jsonData);
      dispatch(setTargetNoData(jsonData.table_data));
      navigate("/CDR-analysis/analysis");
    } catch (error) {
      toast.error("Record not found");
      console.error("API call and redirect failed:", error);
    } finally {
      setLoadingApiData(false);
    }
  };

  const apiUrl = `${base_url}/user/list_case?_id=${_id}`;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const editIconClick = () => {
    locationRef.current.click();
  };

  const fetchData = async () => {
    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": sessionStorage.getItem("token"),
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      console.log(jsonData);
      setData(jsonData.data[0]);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [_id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <div className="text-white fw-bold" style={{ fontSize: "13px" }}>
        Error: {error.message}
      </div>
    );
  }
  return (
    <>
      <div className="container res3 update-case">
        {/* -------------------update modal------------------- */}
        <button
          type="button"
          ref={locationRef}
          className="btn btn-primary d-none"
          data-bs-toggle="modal"
          data-bs-target="#update-case-modal"></button>
        <div
          className="modal fade"
          id="update-case-modal"
          style={{ zIndex: "9999999" }}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div className="modal-dialog modal-lg modal-dialog-scrollable">
            <div
              className="modal-content case-update-row"
              style={{ borderRadius: "15px" }}>
              <CaseUpdate data={data} _id={_id} fetchData={fetchData} />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body p-4 pt-1">
            <span className="card-text">case#{data.caseNumber}</span>
            <img className="card-img-top mt-1" src={img1} alt="Card" />
            <h5 className="card-title pt-4 fancy-title">{data.title}</h5>
            <p className="card-text">
              {data.description ? data.description : "NA"}
            </p>
            <div className="pt-2 mb-0">
              <i
                className="fa-solid fa-pen-to-square float-end me-2 text-white"
                onClick={editIconClick}></i>
            </div>
            <span
              className="card-text text-white"
              style={{ fontSize: "15px", fontWeight: "700" }}>
              Info
            </span>
            <hr className="mt-1" />
            <div className="row">
              <div className="col-sm-6 pb-3">
                <p className="card-text">
                  Date:{" "}
                  <span className="ms-2 text-white">
                    {data.reportDate
                      ? new Date(data.reportDate).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                      : "NA"}
                  </span>{" "}
                </p>

                <p className="card-text">
                  Size:{" "}
                  <span className="ms-2 text-white">
                    {data.size ? data.size : "NA"}
                  </span>{" "}
                </p>

                <p className="card-text">
                  Charge Sheet:{" "}
                  <span className="ms-2 text-white">
                    {data.chargeSheet ? data.chargeSheet : "NA"}
                  </span>{" "}
                </p>

                <p className="card-text">
                  Verdict:{" "}
                  <span className="ms-2 text-white">
                    {data.verdict ? data.verdict : "NA"}
                  </span>{" "}
                </p>

                <p className="card-text">
                  Enum:{" "}
                  <span className="ms-2 text-white">
                    {data.enum ? data.enum : "NA"}
                  </span>{" "}
                </p>

                <p className="card-text">
                  Assigned Officer:{" "}
                  <span className="ms-2 text-white">
                    {data.assignedOfficer.firstName}{" "}
                    {data.assignedOfficer.lastName}
                  </span>{" "}
                </p>

                <p className="card-text">
                  Police Station Address:{" "}
                  <span className="ms-2 text-white">
                    <span className="me-1">
                      {data.ps_address.pin_code
                        ? data.ps_address.pin_code
                        : "NA"}
                    </span>

                    <span className="me-1">
                      {data.ps_address.state ? data.ps_address.state : "NA"}
                    </span>
                    <span className="me-1">
                      {data.ps_address.dist ? data.ps_address.dist : "NA"}
                    </span>
                    <span className="me-1">
                      {data.ps_address.address ? data.ps_address.address : "NA"}
                    </span>
                  </span>{" "}
                </p>
              </div>
              <div className="col-sm-6 pb-3">
                <p className="card-text">
                  Victim:{" "}
                  <span className="ms-2 text-white">
                    {data.victim ? data.victim : "NA"}
                  </span>{" "}
                </p>
                <p className="card-text">
                  Accused:{" "}
                  <span className="ms-2 text-white">
                    {data.accused ? data.accused.join(", ") : "NA"}
                  </span>{" "}
                </p>

                <p className="card-text">
                  Status:{" "}
                  <span className="ms-2 text-white">
                    {data.status ? data.status : "NA"}
                  </span>{" "}
                </p>
                <p className="card-text">
                  Type:{" "}
                  <span className="ms-2 text-white">
                    {data.type ? data.type : "NA"}
                  </span>{" "}
                </p>

                <p className="card-text">
                  Default:{" "}
                  <span className="ms-2 text-white">
                    {data.default ? data.default : "NA"}
                  </span>{" "}
                </p>
                <p className="card-text">
                  Ps No.:{" "}
                  <span className="ms-2 text-white">
                    {data.ps_number ? data.ps_number : "NA"}
                  </span>{" "}
                </p>

                <p className="card-text">
                  Crime Section:{" "}
                  <span className="ms-2 text-white">
                    {data.crimeSections ? data.crimeSections.join(", ") : "NA"}
                  </span>{" "}
                </p>
              </div>
              <hr />

              <div className="col-sm-6">
                <div className="pb-4 mt-2">
                  <table className="table mt-2 table-bordered border-secondary align-middle">
                    <thead className="table_cdr sticky-top table-header-sticky">
                      <tr>
                        <th scope="col">Shared with officers</th>
                      </tr>
                    </thead>
                    {data && (
                      <tbody
                        className="text-white"
                        style={{ fontSize: "12px", background: "#080D2F" }}>
                        {data.sharedWithOfficer.length > 0 ? (
                          data.sharedWithOfficer.map((item, index) => (
                            <tr key={index}>
                              <th className="py-3" scope="row">
                                {item.firstName} {item.lastName}
                              </th>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="1" className="py-3">
                              Not shared yet
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="pb-4 mt-2">
                  <table className="table mt-2 table-bordered border-secondary align-middle">
                    <thead className="table_cdr sticky-top table-header-sticky">
                      <tr>
                        <th scope="col">CDR Target Number</th>
                      </tr>
                    </thead>
                    {data && (
                      <tbody
                        className="text-white center-content"
                        style={{ fontSize: "12px", background: "#080D2F" }}>
                        {data.cdr_target_number.length > 0 ? (
                          data.cdr_target_number.map((item, index) => (
                            <tr
                              key={index}
                              className="hover-cdr_target_number"
                              style={{ cursor: "pointer" }}>
                              <td
                                className="py-3"
                                onClick={() => handleApiCallAndRedirect(item)}>
                                {item}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="1" className="py-3">
                              Not found yet
                            </td>
                          </tr>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
            {/* ---------------------------Case Diary component--------------------------- */}
            <UpdateHistory case_id={_id} initialEntries={data.updateHistory} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailedCase;
