import React, { useEffect, useState } from "react";
import
{
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import contact from "./img/contact.jpg";
import { utils, writeFile } from "xlsx";
import "../CSS/sidebar.css";
import { useSelector } from "react-redux";

function Sidebar({ pagename })
{
  const [searchCase, setSearchCase] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const commonData = useSelector((state) => state.commonData);
  const formatDate = (dateString) =>
  {
    const date = new Date(dateString).toDateString();
    return date;
  };
  const formatTime = (timeString) =>
  {
    const date = new Date(timeString).toTimeString();
    return date;
  };
  const data = useSelector((state) => state.data);
  const locationData = useSelector((state) => state.locationData);
  const smsData = useSelector((state) => state.smsData);
  const completeData = useSelector((state) => state.completeData);
  // console.log(locationData);
  // console.log("data based on call duration ", data);

  // Function to handle search input change
  const handleSearchChange = (event) =>
  {
    setSearchCase(event.target.value);
    // You can also add logic here to handle the search functionality
  };

  const generateMultiTabXLSX_2 = () =>
  {
    const workbook = utils.book_new();
    let isFirstIteration = true;

    commonData.forEach((commonItem, commonIndex) =>
    {
      commonItem.targetNumber.forEach((target, targetIndex) =>
      {
        const data = target.callDetails.map((detail) => ({
          "Common Number": commonItem.commanNumber || "NA",
          "Target Number": target.number || "NA",
          "B_party No.": detail.calledPhoneNumber || "NA",
          "Date&Time": formatDate(detail.callDateTime) || "NA",
          "Call Type": detail.typeOfCall || "NA",
          Duration: detail.duration || "NA",
          Location: detail.towerAddress || "Not available",
        }));

        if (isFirstIteration)
        {
          const worksheet = utils.json_to_sheet(data);

          // Append a unique identifier to the worksheet name
          const worksheetName = `${target.number}_${targetIndex}`;

          utils.book_append_sheet(workbook, worksheet, worksheetName);
        } else
        {
          const existingWorksheet =
            workbook.Sheets[workbook.SheetNames[targetIndex]];
          const existingData = utils.sheet_to_json(existingWorksheet, {
            header: 1,
          });

          const newData = existingData.concat([
            ["", "", "", "", "", "", ""],
            ...data.map(Object.values),
          ]);

          const newWorksheet = utils.aoa_to_sheet(newData);
          workbook.Sheets[workbook.SheetNames[targetIndex]] = newWorksheet;
        }
      });

      isFirstIteration = false;
    });

    // Generate the Excel file
    writeFile(workbook, "data.xlsx");
  };

  // Define bold style

  const generateMultiTabXLSX = () =>
  {
    console.log("todays date is ");
    // Define the static content to add at the top of the worksheet
    const staticContentTop = [
      ["Prosoft e-Solutions"],
      ["Prosoft House"],
      ["Kaktives Road, Belgaum-590001"],
      [], // empty row
      ["Analysis Name : QA_GENERAL CASE_14-Jul-2023_105227"],
      ["From : 01-06-2023", "To : 12-07-2023"],
      ["Created By : Admin", `Created Date : ${new Date()}`],
      [], // empty row to separate from dynamic data
    ];

    // Add the static content to the top of ws1
    let ws1 = utils.aoa_to_sheet(staticContentTop);

    // Then append the dynamic data from `completeData` starting from row 9 (since static content is 8 rows)
    utils.sheet_add_json(
      ws1,
      completeData.map((item, index) => ({
        "B-Party No.": item.calledPhoneNumber || "NA",
        Date: formatDate(item.callDateTime) || "NA",
        Time: formatTime(item.callDateTime) || "NA",
        Duration: item.duration || "NA",
        "Call Type": item.typeOfCall || "NA",
        IMEI: item.imei || "NA",
        IMSI: item.imsi || "NA",
        "Last CGI Lat/Long":
          item.latitude && item.Longitude_A
            ? `${item.latitude}/${item.Longitude_A}`
            : "NA",
      })),
      { origin: "A9" }
    );

    // Add the extra data at the end of ws1
    // Calculate the next empty row after your data in ws1
    const nextEmptyRow = utils.decode_range(ws1["!ref"]).e.r + 3; // +3 to move to the next empty row

    const extraData = [
      ["Report List:"],
      ["1. Call-Frequency"],
      ["2. Details"],
      [], // Empty row
      [
        "DISCLAIMER:",
        "The analysis is based on the CDRs furnished    by the Service Providers.",
      ],
      [],
      ["Any errors in the CDRs will lead to errors in results."],
    ];

    // Add the extra data to the end of ws1
    utils.sheet_add_aoa(ws1, extraData, { origin: { r: nextEmptyRow, c: 0 } });

    const boldStyle = {
      font: { bold: true },
    };

    // Apply bold style to the first row
    const range = utils.decode_range(ws1["!ref"]); // get the range of the worksheet
    for (let C = range.s.c; C <= range.e.c; ++C)
    {
      // loop over all columns
      const address = utils.encode_col(C) + "1"; // construct A1 notation references
      if (!ws1[address]) continue; // if cell doesn't exist, skip it
      ws1[address].s = boldStyle; // apply the bold style
    }

    const ws2 = utils.json_to_sheet(
      data.map((item, index) => ({
        "B-Party No.": item.b_party_number || "NA",
        "Total Calls": item.total_calls || "NA",
        "In Calls": item.call_type_in || "NA",
        "Out Calls": item.call_type_out || "NA",
        "Last Call": formatDate(item.last_call_date) || "NA",
        "First Call": formatDate(item.first_call_date) || "NA",
      }))
    );

    const ws3 = utils.json_to_sheet(
      locationData.map((item, index) =>
      {
        // Initialize an object to store the row data
        let rowData = {
          Longitude: item.location.longitude || "NA",
          Latitude: item.location.latitude || "NA",
          "Incoming Calls": item.call_type_in
            ? formatDate(item.call_type_in)
            : "NA",
          "Outgoing Calls": item.call_type_out
            ? formatDate(item.call_type_out)
            : "NA",
          "Total Calls": item.total_number_of_call || "NA",
        };

        // Check if the details field exists and is an array
        if (Array.isArray(item.details))
        {
          item.details.forEach((detail, detailIndex) =>
          {
            // For each detail, add its fields to the row data with an index
            rowData[`Call Date ${detailIndex + 1}`] = detail.callDate || "NA";
            rowData[`Call Time ${detailIndex + 1}`] = detail.callTime || "NA";
            rowData[`Call Type ${detailIndex + 1}`] = detail.callType || "NA";
            rowData[`Called Phone Number ${detailIndex + 1}`] =
              detail.calledPhoneNumber || "NA";
          });
        }

        return rowData;
      })
    );

    const ws4 = utils.json_to_sheet(
      smsData.map((item, index) => ({
        "B-Party No.": item.b_party_number,
        "Total SMS": item.sms,
        "First SMS": formatDate(item.first_call_date),
        "Last SMS": formatDate(item.last_call_date),
      }))
    );

    const ws5 = utils.json_to_sheet(
      data.map((item, index) => ({
        "B-Party No.": item.b_party_number,
        "Total Calls": item.total_calls || "NA",
        "Total Duration (sec)": item.call_duration || "NA",
        "Incoming Calls": item.call_type_in || "NA",
        "Outgoing Calls": item.call_type_out || "NA",
        "First Call": formatDate(item.first_call_date) || "NA",
        "Last Call": formatDate(item.last_call_date) || "NA",
      }))
    );

    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws1, "Complete Data");
    utils.book_append_sheet(wb, ws2, "Call Data");
    utils.book_append_sheet(wb, ws3, "Location Data");
    utils.book_append_sheet(wb, ws4, "SMS Data");
    utils.book_append_sheet(wb, ws5, "Duration Data");
    writeFile(wb, "combined_data.xlsx");
  };

  const { _id } = useParams();
  const [isActiveCDRAnalysis, setIsActiveCDRAnalysis] = useState(false);
  const [isActiveCaseManagement, setIsActiveCaseManagement] = useState(false);
  const activeCDRAnalysisRoutes = [
    "/CDR-analysis/analysis",
    "/CDR-analysis/geo-analysis",
    "/CDR-analysis/visualization",
    "/CDR-analysis/multiple-analysis",
  ];

  const [isActiveSearch, setIsActiveSearch] = useState(false);
  const activeSearchRoutes = [
    "/Search/single-search",
    "/Search/multiple-search",
  ];
  const activeCaseManagementRoutes = [
    "/CaseManagemet/cases",
    "/CaseManagemet/addCase",
    `/CaseManagement/caseDetails/${_id}`,
  ];

  useEffect(() =>
  {
    const isCurrentLocationActiveCDRAnalysis = activeCDRAnalysisRoutes.some(
      (route) => location.pathname.startsWith(route)
    );
    setIsActiveCDRAnalysis(isCurrentLocationActiveCDRAnalysis);

    const isCurrentLocationActiveSearch = activeSearchRoutes.some((route) =>
      location.pathname.startsWith(route)
    );
    setIsActiveSearch(isCurrentLocationActiveSearch);

    const isCurrentLocationActiveCaseManagement =
      activeCaseManagementRoutes.some((route) =>
        location.pathname.startsWith(route)
      );
    setIsActiveCaseManagement(isCurrentLocationActiveCaseManagement);
  }, [location.pathname]);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () =>
  {
    setSidebarOpen(!sidebarOpen);
  };
  const handleLogout = () =>
  {
    const token = sessionStorage.getItem("token");
    console.log("Token in sessionStorage:", token);
    sessionStorage.removeItem("token");
    navigate("/signin");
  };
  const navStyle = {
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF",
    padding: "10px",
    textDecoration: "none",
    borderRadius: "5px",
    marginBottom: "5px",
    cursor: "pointer",
  };
  const navStyle2 = {
    width: "25px",
    height: "25px",
    background: "#1E2133",
    display: "flex",
    borderRadius: "8px",
    justifyContent: "center",
    alignItems: "center",
  };

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const toggleSubMenu = () =>
  {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const [isSearchMenuOpen, setIsSearchMenuOpen] = useState(false);

  const toggleSearchMenu = () =>
  {
    setIsSearchMenuOpen(!isSearchMenuOpen);
  };

  return (
    <>
      <div className={`sidebar ${sidebarOpen ? "active" : ""}`}>
        <div
          className="d-flex sticky-top overflow-auto float-start flex-column flex-shrink-0 p-3 text-white bg-dark"
          style={{
            height: "100vh",
            background:
              "linear-gradient(180deg, #0f0b1d, rgba(15, 11, 29, 0.5))",
            width: "100%",
          }}
        >
          <div className="fs-5 mb-2 text-center fw-bold me-2">C.A.T.C.H</div>

          <div className="d-flex mb-3 border-bottom flex-row text-white text-decoration-none">
            <div className="sidebar-toggle ms-auto" onClick={toggleSidebar}>
              <i className="fas fa-times"></i>
            </div>
          </div>
          <ul className="nav nav-pills fs-6 flex-column mb-auto">
            <li className="nav-item">
              <NavLink to="/" className="custom-link" style={navStyle}>
                <div style={navStyle2} className="me-3" />
                Dashboard
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/profile" className="custom-link" style={navStyle}>
                <div style={navStyle2} className="me-3 himu" />
                Profile
              </NavLink>
            </li>
            <li
              className={`nav-item ${isActiveCaseManagement ? "active_1" : ""}`}
            >
              <NavLink
                to="/CaseManagemet/cases"
                className="custom-link"
                style={navStyle}
              >
                <div style={navStyle2} className="me-3 himu" />
                Case Management
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/reminder"
                className="custom-link position-relative"
                style={navStyle}
              >
                <div style={navStyle2} className="me-3" />
                Reminder
              </NavLink>
            </li>

            <li className={`nav-item ${isActiveCDRAnalysis ? "active_1" : ""}`}>
              <div
                className="custom-link"
                style={navStyle}
                onClick={toggleSubMenu}
              >
                <div style={navStyle2} className="me-3" />
                CDR Analysis <i class="fa-solid fa-caret-down ms-2"></i>
              </div>

              <ul
                className={`sub-list ${isSubMenuOpen ? "sub-list-active" : ""}`}
              >
                <Link className="Link-style" to="/CDR-analysis/analysis">
                  <li className="Li">
                    <div className="custom-sub-link">Single CDR Analysis</div>
                  </li>
                </Link>
                <Link
                  className="Link-style"
                  to="/CDR-analysis/multiple-analysis"
                >
                  <li className="Li">
                    <div className="custom-sub-link">Multiple CDR analysis</div>
                  </li>
                </Link>
                <Link className="Link-style" to="/CDR-analysis/ipdr-analysis">
                  <li className="Li">
                    <div className="custom-sub-link">IPDR Analysis</div>
                  </li>
                </Link>
                <Link className="Link-style" to="/CDR-analysis/geo-analysis">
                  <li className="Li">
                    <div className="custom-sub-link">Geo Analysis</div>
                  </li>
                </Link>
                <Link className="Link-style" to="/CDR-analysis/visualization">
                  <li className="Li">
                    <div className="custom-sub-link">Visualization</div>
                  </li>
                </Link>
              </ul>
            </li>

            {/* ----------------------------SEARCH ANALYSIS------------------------ */}
            {/* <li className={`nav-item ${isActiveSearch ? "active_1" : ""}`}>
              <div
                className="custom-link"
                style={navStyle}
                onClick={toggleSearchMenu}
              >
                <div style={navStyle2} className="me-3" />
                Search<i class="fa-solid fa-caret-down ms-2"></i>
              </div>
              {isSearchMenuOpen && (
                <ul
                  className={`sub-list ${isSearchMenuOpen ? "sub-list-active" : ""
                    }`}
                >
                  <Link className="Link-style" to="/Search/single-search">
                    <li className="Li">
                      <div className="custom-sub-link">
                        Single Number Search
                      </div>
                    </li>
                  </Link>
                  <Link className="Link-style" to="/Search/multiple-search">
                    <li className="Li">
                      <div className="custom-sub-link">Multi Number Search</div>
                    </li>
                  </Link>
                  <Link className="Link-style" to="/Search/VehicleSearch">
                    <li className="Li">
                      <div className="custom-sub-link">Vehicle RC Search</div>
                    </li>
                  </Link>
                  <Link
                    className="Link-style"
                    to="/Search/ReverseVehicleSearch"
                  >
                    <li className="Li">
                      <div className="custom-sub-link">Reverse RC Search</div>
                    </li>
                  </Link>
                  <Link className="Link-style" to="/Search/UpiSearch">
                    <li className="Li">
                      <div className="custom-sub-link">UPI Search</div>
                    </li>
                  </Link>
                  <Link className="Link-style" to="/Search/PanCardSearch">
                    <li className="Li">
                      <div className="custom-sub-link">Pan Card Search</div>
                    </li>
                  </Link>
                  <Link className="Link-style" to="/Search/VPNProxySearch">
                    <li className="Li">
                      <div className="custom-sub-link">VPN Proxy Search</div>
                    </li>
                  </Link>
                  <Link className="Link-style" to="/Search/IpGeolocationSearch">
                    <li className="Li">
                      <div className="custom-sub-link">
                        Ip Geolocation Search
                      </div>
                    </li>
                  </Link>
                  <Link className="Link-style" to="/Search/OSINTSearch">
                    <li className="Li">
                      <div className="custom-sub-link">OSINT Search</div>
                    </li>
                  </Link>
                </ul>
              )}
            </li> */}

            <li className="nav-item">
              <NavLink to="/activity" className="custom-link" style={navStyle}>
                <div style={navStyle2} className="me-3" />
                Activity
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/ai-analysis"
                className="custom-link"
                style={navStyle}
              >
                <div style={navStyle2} className="me-3" />
                A.I Analysis
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/help" className="custom-link" style={navStyle}>
                <div style={navStyle2} className="me-3" />
                Help
              </NavLink>
            </li>
            <li className="nav-item">
              <div
                style={navStyle}
                className="custom-link"
                onClick={handleLogout}
              >
                <div style={navStyle2} className="me-3" />
                Logout
              </div>
            </li>
          </ul>
          <div className="container mt-5">
            <div className="row">
              <div
                className="col-12"
                style={{
                  width: "100%",
                  height: "200px",
                  background: `url(${contact})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  marginBottom: "10px",
                  display: "flex",
                  borderRadius: "20px",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    color: "white",
                    fontSize: "18px",
                    marginBottom: "10px",
                  }}
                >
                  Need help! Please contact us
                </p>
                <a
                  href="https://wa.me/919304345393?text=Hello,%20I%20want%20to%20chat!"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    padding: "10px 20px",
                    fontSize: "16px",
                    backgroundColor: "#0075FF",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    textDecoration: "none",
                  }}
                >
                  +91 9304345393
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pagename === "Geo Analysis" ? (
        <div className="d-flex navbar-cdr flex-wrap custom-floating-content p-2 text-white mb-2 align-items-center">
          <Link
            to="/CDR-analysis/geo-analysis"
            className="p-2 rounded-circle text-white"
            style={{ background: "black", width: "40px", textAlign: "center" }}
          >
            <i class="fa-solid fa-arrow-left"></i>
          </Link>
          <div
            className="sidebar-toggle bg-secondary rounded-3 me-2"
            onClick={toggleSidebar}
          >
            <i className="fas fa-bars p-2 py-1"></i>
          </div>
        </div>
      ) : null}
      {pagename !== "Geo Analysis" && (
        <div className="d-flex navbar-cdr flex-wrap p-3 text-white mb-2 align-items-center">
          <div className="sidebar-toggle me-2" onClick={toggleSidebar}>
            <i className="fas fa-bars"></i>
          </div>
          <div className="me-auto fw-bold" style={{ fontSize: "100%" }}>
            {pagename}
            <div
              className="mt-2"
              style={{ fontSize: "13px", fontWeight: "500" }}
            >
              CDR enabled Advanced Tracking of Crime using High end Technology
            </div>
          </div>
          {pagename === "Case Management" && (
            // <div className="d-flex align-items-center">
            //   <input
            //     type="text"
            //     placeholder="Search cases..."
            //     value={searchCase}
            //     onChange={handleSearchChange}
            //     style={{
            //       marginRight: "10px",
            //       padding: "5px",
            //       borderRadius: "5px",
            //       border: "1px solid #ccc",
            //     }}
            //   />
            <Link
              to="/CaseManagemet/addCase"
              className="btn border border-light addcase-button text-white"
              style={{ fontSize: "70%" }}
            >
              Add case <i className="fa-solid fa-circle-plus ms-2"></i>
            </Link>
            // </div>
          )}

          {pagename === "Multiple Analysis" && (
            <>
              {commonData && (
                <div className="mt-2 me-1">
                  <div
                    onClick={generateMultiTabXLSX_2}
                    className="btn addcase-button"
                    style={{
                      fontSize: "80%",
                      background: "#8EA5C9",
                      color: "black",
                      fontWeight: "700",
                    }}
                  >
                    <i class="fa-solid fa-circle-down"></i>
                  </div>
                </div>
              )}

              <div
                className="btn addcase-button"
                style={{
                  fontSize: "80%",
                  marginTop: "6px",
                  background: "#8EA5C9",
                  color: "black",
                  fontWeight: "700",
                }}
                onClick={() => window.location.reload()}
              >
                Reset <i class="fa-solid fa-arrows-rotate ms-1"></i>
              </div>
            </>
          )}
          {pagename === "CDR Analysis" && (
            <>
              {data && (
                <div className="mt-2 me-1">
                  <div
                    onClick={generateMultiTabXLSX}
                    className="btn addcase-button"
                    style={{
                      fontSize: "80%",
                      background: "#8EA5C9",
                      color: "black",
                      fontWeight: "700",
                    }}
                  >
                    <i class="fa-solid fa-circle-down"></i>
                  </div>
                </div>
              )}

              <div
                className="btn addcase-button"
                style={{
                  fontSize: "80%",
                  marginTop: "6px",
                  background: "#8EA5C9",
                  color: "black",
                  fontWeight: "700",
                }}
                onClick={() => window.location.reload()}
              >
                Reset <i class="fa-solid fa-arrows-rotate ms-1"></i>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default Sidebar;
