import { createSlice } from "@reduxjs/toolkit";

const imeiDataSlice = createSlice({
  name: "imeiData",
  initialState: [],
  reducers: {
    setimeiData: (state, action) => {
      return action.payload;
    },
  },
});

export const { setimeiData } = imeiDataSlice.actions;

export default imeiDataSlice.reducer;
