import { createSlice } from '@reduxjs/toolkit';

const filteredMapDataSlice = createSlice({
    name: 'filteredMapData',
    initialState: [],
    reducers: {
        setFilteredMapData: (state, action) => {
            return action.payload;
        },
    },
});

export const { setFilteredMapData } = filteredMapDataSlice.actions;

export default filteredMapDataSlice.reducer;
