import React, { useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import { FaInfoCircle } from "react-icons/fa";

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const PanCardSearch = () =>
{
  const [panDetails, setPanDetails] = useState({});
  const [chassisNumber, setChassisNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleChangeChassisNumber = (event) =>
  {
    setChassisNumber(event.target.value);
  };

  const searchChassis = async () =>
  {
    setLoading(true);
    try
    {
      const apiResponse = await fetch(
        `${base_url}/user/get_person_details_by_pancard`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": sessionStorage.getItem("token"),
          },
          body: JSON.stringify({
            panNumber: chassisNumber,
          }),
        }
      );
      const details = await apiResponse.json();
      if (apiResponse.ok)
      {
        toast.success("Successfully fetched PAN Details");
        console.log("details", details);
        if (details.data.PAN)
        {
          setPanDetails(details?.data);
        } else
        {
          setPanDetails(details?.data?.data);
        }
        setOpenModal(true);
      } else
      {
        toast.error(details?.message);
      }
    } catch (error)
    {
      toast.error("Failed to fetch PAN Details");
      console.error("Error:", error);
    } finally
    {
      setLoading(false);
    }
  };

  const closeModal = () =>
  {
    setOpenModal(false);
  };
  console.log("pancard", panDetails);

  return (
    <div className="res3 row p-2">
      <ToastContainer />
      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">Loading...</div>
        </div>
      )}

      <div className="col-md-8 mx-auto">
        <div
          className="col-lg-8 col-md-12 col-sm-8 mx-auto border"
          style={{ borderRadius: "19px", backgroundColor: "#0F1535" }}
        >
          <div className="search-header text-white text-center py-2 mb-4 mt-3">
            <h2>Pan Card Search</h2>
          </div>

          <div
            className="input-group mb-3 align-items-center mx-auto"
            style={{ width: "80%" }}
          >
            <input
              className="form-control"
              type="text"
              placeholder="Pan Card Number"
              value={chassisNumber}
              onChange={handleChangeChassisNumber}
            />
          </div>

          <div className="d-flex justify-content-between flex-row-reverse mb-4">
            <button
              className="btn btn-primary mx-auto"
              onClick={searchChassis}
              style={{
                maxWidth: "200px",
                borderRadius: "15px",
                fontSize: "13px",
              }}
            >
              Search Number
            </button>
          </div>
        </div>
      </div>

      {openModal && (
        <div className="modal-backdrop">
          <div
            className="modal-content mx-auto mt-5"
            style={{
              backgroundColor: "rgba(0, 88, 191, 0.15)",
              boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.15)",
              border: "3px solid rgba(0, 88, 191, 1)",
              borderRadius: "12px",
              maxWidth: "650px",
            }}
          >
            <div className="modal-header text-center">
              <h4 className="text-white">PAN Details</h4>
              <IoCloseCircleOutline
                size={25}
                className="text-white"
                onClick={closeModal}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="modal-body text-white p-4">
              <div className="d-flex flex-column align-items-start p-2">
                <div className="d-flex align-items-center p-2">
                  <p
                    className="mb-0"
                    style={{
                      color: "rgba(206, 206, 206, 1)",
                      fontSize: "15px",
                      marginRight: "10px",
                    }}
                  >
                    PAN Number:
                  </p>
                  <span style={{ fontWeight: "bold" }}>
                    {panDetails.PAN || "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center p-2">
                  <p
                    className="mb-0"
                    style={{
                      color: "rgba(206, 206, 206, 1)",
                      fontSize: "15px",
                      marginRight: "10px",
                    }}
                  >
                    Name:
                  </p>
                  <span style={{ fontWeight: "bold" }}>
                    {panDetails?.NAME || "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center p-2">
                  <p
                    className="mb-0"
                    style={{
                      color: "rgba(206, 206, 206, 1)",
                      fontSize: "15px",
                      marginRight: "10px",
                    }}
                  >
                    Aadhaar Number:
                  </p>
                  <span style={{ fontWeight: "bold" }}>
                    {panDetails?.AADHAR_NUM || "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center p-2">
                  <p
                    className="mb-0"
                    style={{
                      color: "rgba(206, 206, 206, 1)",
                      fontSize: "15px",
                      marginRight: "10px",
                    }}
                  >
                    Aadhaar Linked:
                  </p>
                  <span style={{ fontWeight: "bold" }}>
                    {panDetails?.AADHAR_LINKED ? "Yes" : "No"}
                  </span>
                </div>
                <div className="d-flex align-items-center p-2">
                  <p
                    className="mb-0"
                    style={{
                      color: "rgba(206, 206, 206, 1)",
                      fontSize: "15px",
                      marginRight: "10px",
                    }}
                  >
                    Date of Birth:
                  </p>
                  <span style={{ fontWeight: "bold" }}>
                    {panDetails?.DOB || "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center p-2">
                  <p
                    className="mb-0"
                    style={{
                      color: "rgba(206, 206, 206, 1)",
                      fontSize: "15px",
                      marginRight: "10px",
                    }}
                  >
                    Gender:
                  </p>
                  <span style={{ fontWeight: "bold" }}>
                    {panDetails?.GENDER || "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center p-2">
                  <p
                    className="mb-0"
                    style={{
                      color: "rgba(206, 206, 206, 1)",
                      fontSize: "15px",
                      marginRight: "10px",
                    }}
                  >
                    Address:
                  </p>
                  <span style={{ fontWeight: "bold" }}>
                    {`${panDetails?.ADDRESS_1 || ""} ${panDetails?.ADDRESS_2 || ""
                      } ${panDetails?.ADDRESS_3 || ""}` || "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center p-2">
                  <p
                    className="mb-0"
                    style={{
                      color: "rgba(206, 206, 206, 1)",
                      fontSize: "15px",
                      marginRight: "10px",
                    }}
                  >
                    City:
                  </p>
                  <span style={{ fontWeight: "bold" }}>
                    {panDetails?.CITY || "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center p-2">
                  <p
                    className="mb-0"
                    style={{
                      color: "rgba(206, 206, 206, 1)",
                      fontSize: "15px",
                      marginRight: "10px",
                    }}
                  >
                    State:
                  </p>
                  <span style={{ fontWeight: "bold" }}>
                    {panDetails?.STATE || "N/A"}
                  </span>
                </div>
                <div className="d-flex align-items-center p-2">
                  <p
                    className="mb-0"
                    style={{
                      color: "rgba(206, 206, 206, 1)",
                      fontSize: "15px",
                      marginRight: "10px",
                    }}
                  >
                    Pincode:
                  </p>
                  <span style={{ fontWeight: "bold" }}>
                    {panDetails?.PINCODE || "N/A"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PanCardSearch;
