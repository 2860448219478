import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Profileinfo = () => {
  const [ip, setIp] = useState("");
  const userAgent = navigator.userAgent;
  let browserName = "Unknown";
  let country = "INDIA";

  console.log(userAgent);

  if (userAgent.indexOf("Firefox") !== -1) {
    browserName = "Mozilla Firefox";
  } else if (userAgent.indexOf("Chrome") !== -1) {
    browserName = "Google Chrome";
  } else if (userAgent.indexOf("Safari") !== -1) {
    browserName = "Apple Safari";
  } else if (userAgent.indexOf("Edge") !== -1) {
    browserName = "Microsoft Edge";
  } else if (
    userAgent.indexOf("Opera") !== -1 ||
    userAgent.indexOf("OPR") !== -1
  ) {
    browserName = "Opera";
  } else if (
    userAgent.indexOf("Trident") !== -1 ||
    userAgent.indexOf("MSIE") !== -1
  ) {
    browserName = "Internet Explorer";
  }

  //  const ipAddress=window.location.hostname;
  useEffect(() => {
    async function fetchData() {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      // console.log(data.ip)
      setIp(data.ip);
      localStorage.setItem("IPAddress", data.ip);
    }
    fetchData();
  }, []);

  const { data: user } = useSelector((state) => state.user);
  return (
    <div
      class="d-flex flex-column mb-3 text-white info"
      style={{
        background:
          "linear-gradient(180deg, #080D2F 0%, rgba(8, 13, 47, 0.35) 100%)",
        borderRadius: "25px",
      }}
    >
      <div class="pt-3 ps-3 pb-2 fw-bold">Profile Information</div>
      <div class="p-3 pt-2" style={{ fontSize: "11px", color: "#8C8C8C" }}>
        {user.bio}
      </div>
      <div class="p-3 pb-0" style={{ color: "#8C8C8C", fontSize: "11px" }}>
        Full Name:
        <span className="text-white ms-2" style={{ wordWrap: "break-word" }}>
          {user.firstName} {user.lastName}
        </span>
      </div>
      <div class="p-3 pb-0" style={{ color: "#8C8C8C", fontSize: "11px" }}>
        Mobile:
        <span className="text-white ms-2" style={{ wordWrap: "break-word" }}>
          {user.phone}
        </span>
      </div>
      <div class="p-3 pb-0" style={{ color: "#8C8C8C", fontSize: "11px" }}>
        Email:
        <span className="text-white ms-2" style={{ wordWrap: "break-word" }}>
          {user.email}
        </span>
      </div>
      <div class="p-3 pb-0" style={{ color: "#8C8C8C", fontSize: "11px" }}>
        Username:
        <span className="text-white ms-2" style={{ wordWrap: "break-word" }}>
          {user.username}
        </span>
      </div>
      <div className="p-3 pb-0" style={{ color: "#8C8C8C", fontSize: "11px" }}>
        {ip ? (
          <>
            Current IP:
            <span
              className="ms-2"
              style={{ wordWrap: "break-word", color: "#06A581" }}
            >
              {ip}
            </span>
          </>
        ) : (
          "Loading..."
        )}
      </div>
      <div class="p-3 pb-0" style={{ color: "#8C8C8C", fontSize: "11px" }}>
        Current Browser Name:
        <span className="text-white ms-2" style={{ wordWrap: "break-word" }}>
          {browserName}
        </span>
      </div>
      <div className="p-3 pb-4" style={{ color: "#8C8C8C", fontSize: "11px" }}>
        Location:
        {country ? (
          <span className="text-white ms-2" style={{ wordWrap: "break-word" }}>
            {country}
          </span>
        ) : (
          <span className="text-white ms-2">Loading...</span>
        )}
      </div>
    </div>
  );
};

export default Profileinfo;
