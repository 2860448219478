import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
});

export const profileUpdateSchema = Yup.object({
    firstName: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, 'First Name must contain only alphabetic characters')
        .min(3, 'First Name must be at least 3 characters')
        .max(20, 'First Name must not exceed 20 characters')
        .required('First Name is required'),
    lastName: Yup.string()
        .matches(/^[a-zA-Z\s]*$/, 'Last Name must contain only alphabetic characters')
        .min(3, 'Last Name must be at least 3 characters')
        .max(20, 'Last Name must not exceed 20 characters')
        .required('Last Name is required'),
    bio: Yup.string()
        .max(100, 'Bio cannot exceed 200 characters'),
    gender: Yup.string(),
    role: Yup.string(),
    // username: Yup.string(),
    phone: Yup.string()
        .matches(/^[6789]\d{9}$/, 'Invalid phone number')
})
export const passwordUpdateSchema = Yup.object({
    current_password: Yup.string().required("Please Enter Current Password"),
    new_password: Yup.string()
        .required("Please Enter New Password")
        .min(6, 'Password must be at least 6 characters')
        .test(
            'has-special-character',
            'Password must contain at least one special character (@$!%*?&)',
            (value) => {
                return /^(?=.*[@$!%*?&])/.test(value);
            }
        )
        .test(
            'has-number',
            'Password must contain at least one number',
            (value) => {
                return /\d/.test(value);
            }
        ),
    confirm_new_password: Yup.string()
        .required("Please Enter Confirm Password")
        .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.new_password === value;
        }),
});

export const addCaseSchema = Yup.object({
    title: Yup.string().min(5, 'Case Title must be at least 5 characters').max(200, 'Case Title must be at most 200 characters').required('Case Title is required'),
    victim: Yup.string()
        .matches(/^[A-Za-z\s]+$/, 'Victim name must contain only letters')
        .min(3, 'Victim name must be at least 3 characters'),
    description: Yup.string().required('Description is required'),
    reportDate: Yup.date()
        .required('Report date is required')
        .typeError('Report date must be a valid date')
        .min(new Date('1990-01-01'), 'Report date must be greater than or equal to 1990-01-01')
        .max(new Date(), 'Report date must be less than the current date'),
    // ps_number: Yup.string()
    // .required('Police Station Number is required')
    // .matches(/^[A-Za-z0-9\s]+$/, 'Police Station Number must be alphanumeric'),
    caseNumber: Yup.string()
        .required('Case Number is required')
        .matches(/^[A-Za-z0-9\s]+$/, 'Case Number must be alphanumeric'),
    crimeSections: Yup.array().of(Yup.string()),
    accused: Yup.array().of(
        Yup.string()
            .matches(/^[A-Za-z\s]+$/, "Accused Name must contain only alphabetic characters and spaces")
            .min(3, "Accused Name must be at least 3 characters long")
            .max(100, "Accused Name must not exceed 100 characters")
    ),
})

export const TFASchema = Yup.object({
    TWOfaToken: Yup.string()
        .required('Verification code is required')
        .min(6, 'Verification code must be at least 6 characters')
})

export const updateCaseSchema = Yup.object({
    title: Yup.string()
        .min(5, 'Case Title must be at least 5 characters')
        .max(200, 'Case Title must be at most 200 characters'),
    chargeSheet: Yup.string(),
    description: Yup.string(),
    verdict: Yup.string(),
    status: Yup.string(),
    crimeSections: Yup.array().of(Yup.string()),
    accused: Yup.array().of(
        Yup.string()
            .matches(/^[A-Za-z\s]+$/, "Accused Name must contain only alphabetic characters and spaces")
            .min(3, "Accused Name must be at least 3 characters long")
            .max(100, "Accused Name must not exceed 100 characters")
    ),

    // 'updateDesc': Yup.string()
    // .required('Update History Description is required')
    // .max(500, 'Update History Description must be at most 500 characters')

});

