import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./case.css";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import { addCaseSchema } from "../../schemas";
const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const AddCase = () => {
  const navigate = useNavigate();
  const [pincode, setPincode] = useState("");
  const [district, setDistrict] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [sharedOfficer, setSharedOfficer] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [officerID, setOfficerID] = useState("");

  useEffect(() => {
    const fetchData = async (pincode) => {
      if (pincode.length === 6) {
        try {
          const response = await fetch(
            `https://api.postalpincode.in/pincode/${pincode}`
          );
          const data = await response.json();
          if (data[0]?.Status === "Success") {
            const { PostOffice } = data[0];
            const district = PostOffice[0]?.District;
            const state = PostOffice[0]?.State;
            console.log(state, district);
            setState(state);
            setDistrict(district);
          }
        } catch (error) {
          console.error("Failed to fetch state and city:", error);
        }
      }
    };
    const debouncedFetch = setTimeout(() => {
      fetchData(pincode);
    }, 500);

    return () => {
      clearTimeout(debouncedFetch);
    };
  }, [pincode]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedSearch(sharedOfficer);
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [sharedOfficer]);

  const handlePincodeChange = (e) => {
    const { name, value } = e.target;

    if (name === "pincode") {
      const inputValue = value.replace(/[^0-9]/g, "");
      setPincode(inputValue.length > 6 ? inputValue.slice(0, 6) : inputValue);
    } else if (name === "state") {
      setState(value);
    } else if (name === "district") {
      setDistrict(value);
    }
  };

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await fetch(
          `${base_url}/user/get_all_user?firstName=${debouncedSearch}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": sessionStorage.getItem("token"),
            },
          }
        );
        const json = await response.json();
        if (json.status === 200) {
          setSearchResults(json);
        }
      } catch (error) {
        console.error("Failed to fetch search results:", error);
      }
    };

    if (debouncedSearch) {
      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearch]);

  const handleSharedOfficerSelect = (
    selectedOfficer1,
    selectedOfficer2,
    id
  ) => {
    const sharedOfficer = `${selectedOfficer1} ${selectedOfficer2}`;
    console.log(id);
    setSharedOfficer(sharedOfficer);
    setOfficerID(id);
    setSearchResults([]);
  };

  const initialValues = {
    title: "",
    victim: "",
    description: "",
    reportDate: "",
    ps_address: {
      address: "",
      dist: "",
      state: "",
      pin_code: "",
    },
    caseNumber: "",
    crimeSections: ["", ""],
    accused: ["", ""],
    sharedWithOfficer: [""],
    cdr_target_number: ["", ""],
  };
  // console.log("credentials == ", state, pincode, district)
  const { values, handleBlur, handleChange, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: addCaseSchema,
      onSubmit: async (values) => {
        console.log("values ", values);
        setLoading(true);
        const cleanedValues = {
          ...values,
          crimeSections: values.crimeSections.filter(
            (section) => section.trim() !== ""
          ),
          accused: values.accused.filter(
            (accusedName) => accusedName.trim() !== ""
          ),
          cdr_target_number: values.cdr_target_number.filter(
            (targetNumber) => targetNumber.trim() !== ""
          ),
          sharedWithOfficer: officerID ? [officerID] : [],
          ps_address: {
            address: address,
            dist: district,
            pin_code: pincode,
            state: state,
          },
          IPAddress: localStorage.getItem("IPAddress"),
        };
        console.log("final data ", cleanedValues);
        try {
          const response = await fetch(`${base_url}/user/add_case`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-auth-token": sessionStorage.getItem("token"),
            },
            body: JSON.stringify(cleanedValues),
          });
          const data = await response.json();
          if (data.status === 200) {
            toast.success("Case Added Successfully");
            navigate("/CaseManagemet/cases");
          }
        } catch (error) {
          toast.error("Failed To Add, Try Again");
        } finally {
          setLoading(false);
        }
      },
    });

  const addCrimeSection = () => {
    const updatedCrimeSections = [...values.crimeSections, ""];
    handleChange({
      target: { name: "crimeSections", value: updatedCrimeSections },
    });
  };

  const removeCrimeSection = (index) => {
    const updatedCrimeSections = [...values.crimeSections];
    updatedCrimeSections.splice(index, 1);
    handleChange({
      target: { name: "crimeSections", value: updatedCrimeSections },
    });
  };

  const handleChangeCrimeSection = (e, index) => {
    const { value } = e.target;
    const updatedCrimeSections = [...values.crimeSections];
    updatedCrimeSections[index] = value;
    handleChange({
      target: { name: "crimeSections", value: updatedCrimeSections },
    });
  };

  const addAccusedName = () => {
    const updatedAccusedNames = [...values.accused, ""];
    handleChange({ target: { name: "accused", value: updatedAccusedNames } });
  };

  const removeAccusedName = (index) => {
    const updatedAccusedNames = [...values.accused];
    updatedAccusedNames.splice(index, 1);
    handleChange({ target: { name: "accused", value: updatedAccusedNames } });
  };

  const handleChangeAccusedName = (e, index) => {
    const { value } = e.target;
    const updatedAccusedNames = [...values.accused];
    updatedAccusedNames[index] = value;
    handleChange({ target: { name: "accused", value: updatedAccusedNames } });
  };

  const applyErrorStyles = (fieldName) => {
    if (errors[fieldName] && touched[fieldName]) {
      return "error-input";
    }
    return "";
  };
  return (
    <>
      <div className="container add-case">
        <div className="row p-3 pt-0 mt-4">
          <div
            style={{
              background:
                "linear-gradient(123.35deg, #080D2F 3.3%, rgba(17, 27, 90, 0.2) 100%)",
              borderRadius: "25px",
            }}
          >
            <form
              className="row g-3 p-4 mt-3 profileForm"
              onSubmit={handleSubmit}
            >
              <div className="col-sm-6 mb-2">
                <label htmlFor="title" className="form-label mb-0">
                  Case Title
                </label>
                <input
                  className={`${applyErrorStyles("title")}`}
                  type="text"
                  id="title"
                  placeholder="Case title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span
                  className="text-danger"
                  style={{ fontSize: "12px", fontWeight: "900" }}
                >
                  {errors.title && touched.title ? errors.title : null}
                </span>
              </div>
              <div className="col-sm-6 mb-2">
                <label htmlFor="caseNumber" className="form-label mb-0">
                  Case Number
                </label>
                <input
                  className={`${applyErrorStyles("caseNumber")}`}
                  type="text"
                  id="caseNumber"
                  placeholder="Case number"
                  name="caseNumber"
                  value={values.caseNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span
                  className="text-danger"
                  style={{ fontSize: "12px", fontWeight: "900" }}
                >
                  {errors.caseNumber && touched.caseNumber
                    ? errors.caseNumber
                    : null}
                </span>
              </div>
              <div className="col-sm-6 mb-2">
                <label htmlFor="victim" className="form-label mb-0">
                  Victim Name
                </label>
                <input
                  className={`${applyErrorStyles("victim")}`}
                  type="text"
                  id="victim"
                  placeholder="Victim Name"
                  name="victim"
                  value={values.victim}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <span
                  className="text-danger"
                  style={{ fontSize: "12px", fontWeight: "900" }}
                >
                  {errors.victim && touched.victim ? errors.victim : null}
                </span>
              </div>
              <div className="col-sm-6 mb-2">
                <label htmlFor="reportDate" className="form-label mb-0">
                  Report Date
                </label>
                <input
                  className={`${applyErrorStyles("reportDate")}`}
                  type="date"
                  id="reportDate"
                  placeholder="Report Date"
                  name="reportDate"
                  value={values.reportDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  min="1990-01-01"
                />
                <span
                  className="text-danger"
                  style={{ fontSize: "12px", fontWeight: "900" }}
                >
                  {errors.reportDate && touched.reportDate
                    ? errors.reportDate
                    : null}
                </span>
              </div>
              <div className="col-sm-12 mb-2">
                <label htmlFor="description" className="form-label mb-1">
                  Description
                </label>
                <textarea
                  className={`${applyErrorStyles("description")}`}
                  id="description"
                  placeholder="Description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows="4"
                />
                <span
                  className="text-danger"
                  style={{ fontSize: "12px", fontWeight: "900" }}
                >
                  {errors.description && touched.description
                    ? errors.description
                    : null}
                </span>
              </div>
              <div className="col-sm-6 mb-2">
                <label htmlFor="pincode" className="form-label mb-0">
                  Pin Code
                </label>
                <input
                  type="text"
                  id="pincode"
                  placeholder="Pin Code"
                  name="pincode"
                  value={pincode}
                  onChange={handlePincodeChange}
                  required // This makes the field mandatory
                  pattern="\d*" // This pattern ensures only digits can be entered (modify as per your requirements)
                  maxLength={6} // Adjust the length as per your requirements
                />
              </div>
              <div className="col-sm-6 mb-2">
                <label htmlFor="district" className="form-label mb-0">
                  District
                </label>
                <input
                  type="text"
                  id="district"
                  placeholder="District"
                  name="district"
                  value={district}
                  onChange={handlePincodeChange}
                />
              </div>
              <div className="col-sm-6 mb-2">
                <label htmlFor="state" className="form-label mb-0">
                  State
                </label>
                <input
                  type="text"
                  id="state"
                  placeholder="State"
                  name="state"
                  value={state}
                  onChange={handlePincodeChange}
                />
              </div>
              <div className="col-sm-6 mb-2">
                <label htmlFor="address" className="form-label mb-0">
                  Police Station Address
                </label>
                <input
                  type="text"
                  id="address"
                  placeholder="Police Station Address"
                  name="address"
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                />
              </div>

              <div className="col-sm-12 mb-2 input-container">
                <label htmlFor="sharedWithOfficer" className="form-label mb-0">
                  Share with other Officers
                </label>
                <div className="input-wrapper">
                  <input
                    className={`${applyErrorStyles("sharedWithOfficer")}`}
                    type="text"
                    id="sharedWithOfficer"
                    placeholder="Search for shared officer"
                    value={sharedOfficer}
                    onChange={(e) => {
                      errors.sharedWithOfficer = "";
                      setOfficerID("");
                      setSharedOfficer(e.target.value);
                    }}
                    autoComplete="off"
                  />
                </div>
                <div className="search-results text-white">
                  {searchResults.totalRecord > 0
                    ? searchResults.data.map((officer, index) => (
                        <div
                          key={index}
                          className="search-result px-2"
                          onClick={() =>
                            handleSharedOfficerSelect(
                              officer.firstName,
                              officer.lastName,
                              officer._id
                            )
                          }
                        >
                          {officer.firstName} {officer.lastName} (
                          {officer.username})
                        </div>
                      ))
                    : null}
                </div>
                <span
                  className="text-danger"
                  style={{ fontSize: "12px", fontWeight: "900" }}
                >
                  {errors.sharedWithOfficer && touched.sharedWithOfficer
                    ? errors.sharedWithOfficer
                    : null}
                </span>
              </div>

              <div className="col-sm-6 mb-0">
                <div className="p-2 ps-0 pb-0 pt-0 text-white">
                  Accused Names
                </div>
                {values.accused.map((accusedName, index) => (
                  <div
                    key={index}
                    className="input-group py-2"
                    style={{ position: "relative" }}
                  >
                    <input
                      className="mb-0 mt-0"
                      type="text"
                      id={`accusedName_${index}`}
                      placeholder={`Accused Name ${index + 1}`}
                      value={accusedName}
                      onChange={(e) => handleChangeAccusedName(e, index)}
                      onBlur={handleBlur}
                    />

                    <span
                      key={index}
                      className="text-danger ms-0"
                      style={{ fontSize: "12px", fontWeight: "900" }}
                    >
                      {errors.accused && errors.accused[index]
                        ? errors.accused[index]
                        : null}
                    </span>
                    <div
                      style={{
                        position: "absolute",
                        top: "25%",
                        right: "8px",
                      }}
                    >
                      <i
                        className="fa-solid fa-xmark text-white"
                        style={{
                          cursor: "pointer",
                          fontWeight: "800",
                        }}
                        onClick={() => removeAccusedName(index)}
                      ></i>
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    width: "fit-content",
                    fontWeight: "800",
                    fontSize: "13px",
                  }}
                  className="btn text-white float-end border-2 border-secondary mt-0"
                  onClick={addAccusedName}
                >
                  Add More +
                </div>
              </div>

              <div className="col-sm-6">
                <div className="p-2 ps-0 pb-0 pt-0 text-white">
                  Crime Sections
                </div>
                {values.crimeSections.map((crimeSection, index) => (
                  <div
                    key={index}
                    className="input-group py-2"
                    style={{ position: "relative" }}
                  >
                    <input
                      className="mt-0 mb-0"
                      type="text"
                      id={`crimeSection_${index}`}
                      placeholder={`Crime Section ${index + 1}`}
                      value={crimeSection}
                      onChange={(e) => handleChangeCrimeSection(e, index)}
                      onBlur={handleBlur}
                    />
                    <span
                      key={index}
                      className="text-danger ms-0"
                      style={{ fontSize: "12px", fontWeight: "900" }}
                    >
                      {errors.crimeSections &&
                      errors.crimeSections[index] &&
                      touched.crimeSections &&
                      touched.crimeSections[index]
                        ? errors.crimeSections[index]
                        : null}
                    </span>
                    <div
                      style={{
                        position: "absolute",
                        top: "25%",
                        right: "8px",
                      }}
                    >
                      <i
                        className="fa-solid fa-xmark text-white"
                        style={{
                          cursor: "pointer",
                          fontWeight: "800",
                        }}
                        onClick={() => removeCrimeSection(index)}
                      ></i>
                    </div>
                  </div>
                ))}
                <div
                  style={{
                    width: "fit-content",
                    fontWeight: "800",
                    fontSize: "13px",
                  }}
                  className="btn text-white float-end border-2 border-secondary mt-0"
                  onClick={addCrimeSection}
                >
                  Add More +
                </div>
              </div>

              {loading ? (
                <button
                  className="btn btn-primary mt-5 position-relative start-50 translate-middle"
                  type="submit"
                  disabled
                >
                  <span
                    className="spinner-grow me-2 spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Adding...
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-primary mt-5 position-relative start-50 translate-middle "
                >
                  Submit
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCase;
