import React, { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import
{
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
} from "react-scroll";

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const SingleNumSearch = () =>
{
  const [number, setNumber] = useState("");
  const [error, setError] = useState("");
  const [loading, setloading] = useState(false);
  const [numberDetails, setNumberDetails] = useState(null);
  const [foundupi, setfoundupi] = useState(true);

  const handleChangeNumber = (event) =>
  {
    const value = event.target.value;
    if (value === "" || (value.match(/^\d+$/) && value.length <= 10))
    {
      setNumber(value);
      setError("");
    }
  };

  const searchNumber = async () =>
  {
    setfoundupi(true);
    setNumberDetails(null);
    setupiDetails(null);
    try
    {
      console.log("number to send==", number?.slice(-10));
      const extractednumber = number?.slice(-10);
      setloading(true);
      const apiResponse = await fetch(`${base_url}/user/number_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({ number: extractednumber }),
      });
      const numDetails = await apiResponse?.json();
      console.log("---------------apiresponse------------", numDetails?.data[0]);
      if (numDetails.success)
      {
        setNumberDetails(numDetails?.data[0]);
        console.log(numDetails?.data);
        toast.success("Successfully fetched Number details");
        setloading(false);
      } else
      {
        setloading(false);
        toast.error(numDetails?.message);
        console.error(numDetails?.message);
      }
    } catch (error)
    {
      setloading(false);
      toast.error("Failed to fetch Number details");
      console.error("Failed to fetch Number details");
    } finally
    {
      setloading(false);
    }
  };

  const navigate = useNavigate();

  const handleImageSearch = () =>
  {
    console.log("image url is ", numberDetails?.image);
    navigate("/Search/ImageUrlSearch", {
      state: { imageUrl: numberDetails.image },
    });
  };

  const [upiDetails, setupiDetails] = useState();

  const handleUpiSearch = async () =>
  {
    try
    {
      console.log("number to send==", number?.slice(-10));
      const extractednumber = number?.slice(-10);
      setloading(true);
      const apiResponse = await fetch(`${base_url}/user/upi_details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": sessionStorage.getItem("token"),
        },
        body: JSON.stringify({ mobileNumber: extractednumber }),
      });

      if (apiResponse.ok)
      {
        const numDetails = await apiResponse?.json();

        console.log("output from api ", numDetails?.data?.data?.upiDetailsList);
        if (numDetails?.data?.upiDetailsList)
        {
          setupiDetails(numDetails?.data?.upiDetailsList);
        } else
        {
          setupiDetails(numDetails?.data?.data?.upiDetailsList);
        }

        // setupiDetails(numDetails?.data?.data?.upiDetailsList);
        toast.success("successfully fetched UPI details");
        setloading(false);
      } else
      {
        setfoundupi(false);
        setloading(false);
        toast.error("failed to fetch UPI details");
        console.error("failed to fetch UPI details");
      }
    } catch (error)
    {
      setloading(false);
      setfoundupi(false);
      toast.error("failed to fetch UPI details");
      console.error("failed to fetch UPI details");
    } finally
    {
      setloading(false);
    }
  };

  const [bankAddresses, setBankAddresses] = useState({});

  const fetchBankAddress = async (upiDetail, index) =>
  {
    try
    {
      setloading(true);
      const response = await fetch(
        `https://ifsc.razorpay.com/${upiDetail.bankIfsc}`
      );
      const data = await response.json();
      console.log("API Response: ", data);

      const fullAddress = `${data.BRANCH}, ${data.CENTRE}, ${data.DISTRICT}, ${data.STATE}, ${data.ADDRESS}, ${data.CONTACT}, ${data.CITY}, ${data.MICR}, ${data.BANK}, ${data.BANKCODE}, ${data.IFSC}`;

      setBankAddresses((prev) => ({
        ...prev,
        [index]: fullAddress, // Accessing the correct field from the API response
      }));
      setloading(false);
    } catch (error)
    {
      setloading(false);
      console.error("Error fetching bank address:", error);
      toast.error("Failed to fetch bank address");
    }
  };

  console.log("upidetails", upiDetails);

  return (
    <div className="res3 row p-2">
      {/* ---------------------------------------------------------Loading--------------------------------------------------------- */}
      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div className="spinner-border text-light" role="status">
            <div className="visually-hidden">Loading...</div>
          </div>
          <div className="text-white">loading...</div>
        </div>
      )}
      <div className="    mx-auto ">
        {/* --------------------number search component-------------------- */}
        <div class=" mx-auto col-xl-6">
          <div
            class=" col-lg-8 col-md-12 col-sm-12 mx-auto col-xl-8 "
            style={{
              border: "1px solid white",
              borderRadius: "20px",
              backgroundColor: "#0F1535",
            }}
          >
            <div className="search-header  text-white text-center py-2  mt-3">
              <h2>Number Search</h2>
            </div>

            {/*-------------------------------------- input search box --------------------------------------  */}

            <div className="input-group mb-3  align-items-center ">
              <div class="mx-5" style={{ width: "100%" }}>
                <div
                  class="p-3 "
                  style={{ borderRadius: "10px", backgroundColor: "white" }}
                >
                  <p class="text-black ">Phone Number</p>
                  <style>
                    {`.PhoneInputInput{
                      color:black !important;
                      background-color:white
                        }`}
                  </style>
                  <PhoneInput
                    class="text-black numberinput"
                    style={{ color: "black" }}
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="IN"
                    value={number}
                    onChange={setNumber}
                  />

                  {number && !isValidPhoneNumber(number) && (
                    <p className="text-danger">Invalid phone number</p>
                  )}
                </div>
              </div>
            </div>
            {error && <p className="text-danger">{error}</p>}

            <div
              className="d-flex justify-content-between flex-row-reverse mb-4 gap-4  "
              style={{ marginTop: "" }}
            >
              <button
                disabled={number && !isValidPhoneNumber(number)}
                className="btn btn-primary mx-auto "
                style={{
                  maxWidth: "200px",
                  borderRadius: "15px",
                  fontSize: "13px",
                }}
                onClick={searchNumber}
              >
                SEARCH NUMBER
              </button>
            </div>
          </div>
        </div>

        {/* ---------------------------Render number details here--------------------------- */}

        {numberDetails && (
          <div
            className="modal-content mx-auto mt-5"
            style={{
              backgroundColor: "rgba(0, 88, 191, 0.15)",
              boxShadow: "2px 2px 6px 0 rgba(0, 0, 0, 0.15)",
              border: "3px solid rgba(0, 88, 191, 1)",
              borderRadius: "12px",
              maxWidth: "650px",
            }}
          >
            <div
              className="modal-body text-center "
              style={{
                backgroundColor: "rgba(0, 88, 191, 0.15)",
                boxShadow: "2px 2px 6px 0 rgba(0, 0, 0, 0.15)",
              }}
            >
              <div className="CDR-row px-3 pb-4 p-3 pt-2 ">
                <div
                  className="table-responsive "
                  style={{ maxHeight: "900px" }}
                >
                  <div className="d-flex flex-column align-items-start text-white p-2 ">
                    <div className="w-100 d-flex align-center flex-column justify-content-center ">
                      <div class=" mx-auto">
                        {numberDetails.image ? (
                          <div class="">
                            <img
                              className=""
                              src={numberDetails.image}
                              alt={numberDetails.name}
                              style={{
                                marginRight: "auto",
                                marginLeft: "auto",
                                width: "160px",
                                height: "160px",
                                borderRadius: "50%",
                                objectFit: "cover",
                                border: "13px solid rgba(0, 88, 191, 1)",
                              }}
                            />
                            <button
                              onClick={handleImageSearch}
                              className="btn btn-primary "
                            >
                              Search Image
                            </button>
                          </div>
                        ) : (
                          <div
                            class=""
                            style={{
                              width: "120px",
                              height: "120px",
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "#ddd",
                              color: "#333",
                              border: "12px solid rgba(0, 88, 191, 1)",
                            }}
                          >
                            No image available
                          </div>
                        )}
                      </div>
                      <strong className="p-2 fs-3 ">
                        {numberDetails?.name}
                      </strong>
                    </div>
                    <div className="w-100 d-flex align-items-center justify-content-between ">
                      <div className="d-flex flex-column align-items-start justify-content-center p-2">
                        <div className="d-flex align-items-center gap-2  p-2">
                          <p
                            className="mb-0 mr-2"
                            style={{
                              color: "rgba(206, 206, 206, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Mobile No. :{" "}
                          </p>{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {numberDetails?.phones?.[0]?.e164Format || "N/A"}
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-2  p-2">
                          <p
                            className="mb-0 mr-2"
                            style={{
                              color: "rgba(206, 206, 206, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Company. :{" "}
                          </p>{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {numberDetails?.phones?.[0]?.carrier || "N/A"}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex flex-column align-items-start justify-content-center ">
                        <div className="d-flex align-items-center gap-2  p-2">
                          <p
                            className="mb-0 mr-2"
                            style={{
                              color: "rgba(206, 206, 206, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Number Type :{" "}
                          </p>{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {numberDetails?.phones?.[0]?.numberType || "N/A"}
                          </span>
                        </div>
                        <div className="d-flex align-items-center gap-2  p-2">
                          <p
                            className="mb-0 mr-2"
                            style={{
                              color: "rgba(206, 206, 206, 1)",
                              fontSize: "15px",
                            }}
                          >
                            Country Code :{" "}
                          </p>{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            {numberDetails?.addresses?.[0]?.countryCode ||
                              "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>

                    {numberDetails.addresses &&
                      numberDetails.addresses.map((address, index) => (
                        <div key={index} className="d-flex flex-column w-100 ">
                          <hr />
                          <strong
                            className="p-2"
                            style={{ color: "rgba(206, 206, 206, 1)" }}
                          >
                            {" "}
                            Address:{" "}
                          </strong>
                          <div className="w-100 d-flex align-items-center justify-content-between">
                            <div className="d-flex flex-column align-items-start justify-content-center p-2">
                              <div className="d-flex align-items-center gap-2  p-2">
                                <p
                                  className="mb-0 mr-2"
                                  style={{
                                    color: "rgba(206, 206, 206, 1)",
                                    fontSize: "15px",
                                  }}
                                >
                                  City :{" "}
                                </p>{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  <span>{address?.city || "N/A"}</span>
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-2  p-2">
                                <p
                                  className="mb-0 mr-2"
                                  style={{
                                    color: "rgba(206, 206, 206, 1)",
                                    fontSize: "15px",
                                  }}
                                >
                                  Country :{" "}
                                </p>{" "}
                                <span>
                                  {" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {address?.countryCode || "N/A"}
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="d-flex flex-column align-items-start justify-content-center ">
                              <div className="d-flex align-items-center gap-2  p-2">
                                <p
                                  className="mb-0 mr-2"
                                  style={{
                                    color: "rgba(206, 206, 206, 1)",
                                    fontSize: "15px",
                                  }}
                                >
                                  Time zone :{" "}
                                </p>{" "}
                                <span>
                                  {" "}
                                  <span style={{ fontWeight: "bold" }}>
                                    {address?.timeZone || "N/A"}
                                  </span>
                                </span>
                              </div>
                              <div className="d-flex align-items-center gap-2  p-2">
                                <p
                                  className="mb-0 mr-2"
                                  style={{
                                    color: "rgba(206, 206, 206, 1)",
                                    fontSize: "15px",
                                  }}
                                >
                                  Access :
                                </p>
                                <span>
                                  <span style={{ fontWeight: "bold" }}>
                                    {numberDetails?.access || "N/A"}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    {numberDetails.internetAddresses &&
                      numberDetails.internetAddresses.map((address, index) => (
                        <div key={index} style={{ width: "100%" }}>
                          <hr />
                          <strong
                            className="p-2  mx-auto"
                            style={{ color: "rgba(206, 206, 206, 1)" }}
                          >
                            {" "}
                            Contact Info {index + 1} :
                          </strong>

                          <div className=" d-flex align-items-center justify-content-between">
                            <div
                              className="d-flex flex-column align-items-start justify-content-center p-2"
                              style={{ width: "100%" }}
                            >
                              <div
                                className="d-flex  flex-row align-items-center gap-2  p-2"
                                style={{ width: "100%" }}
                              >
                                <div class="d-flex flex-row">
                                  <p
                                    className="mb-0 mr-2"
                                    style={{
                                      color: "rgba(206, 206, 206, 1)",
                                      fontSize: "15px",
                                    }}
                                  >
                                    ID :{" "}
                                  </p>{" "}
                                  <p
                                    style={{
                                      fontWeight: "bold",
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    {" "}
                                    <p>{address?.id || "N/A"} </p>{" "}
                                  </p>
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center gap-2  p-2"
                                style={{ marginTop: "-2.25rem" }}
                              >
                                <p
                                  className="mb-0 mr-2"
                                  style={{
                                    color: "rgba(206, 206, 206, 1)",
                                    fontSize: "15px",
                                  }}
                                >
                                  Service :{" "}
                                </p>{" "}
                                <span>
                                  {" "}
                                  <span>
                                    <span style={{ fontWeight: "bold" }}>
                                      {address?.service || "N/A"}
                                    </span>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                    <Link
                      style={{ borderRadius: "10px", width: "60%" }}
                      class=" mx-auto "
                      activeClass="active"
                      to="section1"
                      spy={true}
                      smooth={true}
                      duration={100}
                    >
                      <button
                        onClick={handleUpiSearch}
                        className="btn btn-primary mx-auto "
                        style={{ borderRadius: "10px", width: "100%" }}
                      >
                        UPI Search
                      </button>
                    </Link>
                    <div class=" row  mx-auto gap-2 ">
                      <style>
                        {`
                        .upibox{
                            border:3px solid rgba(0, 88, 191, 1) !important;
                        }
                        `}
                      </style>
                      {upiDetails?.length > 0 ? (
                        upiDetails.map((upiDetail, index) =>
                        {
                          console.log(index);
                          return (
                            <div
                              id={`section${index}`}
                              class="mt-5 text-start border upibox p-3"
                              style={{ borderRadius: "10px" }}
                              key={index}
                            >
                              <p>Name: {upiDetail.name}</p>
                              <p>UPI ID: {upiDetail.upiId}</p>
                              <p>PSP Name: {upiDetail.pspName}</p>
                              <p>
                                Bank IFSC: {upiDetail.bankIfsc}
                                <span
                                  onClick={() =>
                                    fetchBankAddress(upiDetail, index)
                                  }
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "10px",
                                    color: "blue",
                                  }}
                                >
                                  <FaInfoCircle /> Get Details
                                </span>
                              </p>
                              {/* Display bank address */}
                              {bankAddresses[index] && (
                                <p>Bank Address: {bankAddresses[index]}</p>
                              )}

                              <p>Code: {upiDetail.code}</p>
                              <p>Payee Type: {upiDetail.payeeType}</p>
                            </div>
                          );
                        })
                      ) : foundupi ? (
                        <div class="mt-3 text-start ">
                          Click to find UPI details
                        </div>
                      ) : (
                        <div
                          class="mt-3 text-start text-danger "
                          style={{ fontSize: "20px", fontWeight: "bold" }}
                        >
                          UPI data not found{" "}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleNumSearch;
