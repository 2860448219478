import React from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  ResponsiveContainer,
} from "recharts";

import { useSelector } from "react-redux";
function VisChart6() {
  const tableData = useSelector((state) => state.data);
  const extractedData = tableData.map((item) => ({
    b_party_number: item.b_party_number,
    total_calls: item.total_calls,
    call_type_in: item.call_type_in,
    call_type_out: item.call_type_out,
  }));

  extractedData.sort((a, b) => b.total_calls - a.total_calls);
  const topFiveTotalCalls = extractedData.slice(0, 5);

  return (
    <div
      className="text-white align-items-center"
      style={{
        background:
          "linear-gradient(103.46deg, #080D2F 1.69%, rgba(8, 13, 47, 0.35) 99.32%)",
        borderRadius: "25px",
        textTransform: "capitalize",
      }}>
       <ResponsiveContainer width="100%" height={400}>
      <BarChart data={topFiveTotalCalls}>
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis dataKey="b_party_number" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="call_type_in" fill="#8884d8" barSize={30} />
        <Bar dataKey="call_type_out" fill="#82ca9d" barSize={30} />
      </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default VisChart6;
