import React, { useState, useRef, useEffect } from "react";
import ImageSearchResult from "./ImageSearchResult";
import "./ImageSearch.css";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//  style toast container

const base_url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_BASE_URL_DEVELOPMENT
    : process.env.REACT_APP_BASE_URL_PRODUCTION;

const ImageSearch = () => {
  const location = useLocation();
  const imageUri = location?.state?.imageUrl;

  const [imageSearchData, setImageSearchData] = useState([]);
  const [file, setFile] = useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageInput, setImageInput] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);

  const fileInputRef = useRef(null);

  const handleCustomFileInputClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setImageInput(true);

    if (selectedFile) {
      const fileUrl = URL.createObjectURL(selectedFile);
      setImagePreviewUrl(fileUrl);
    }
  };

  const uploadFile = async () => {
    if (!file) {
      // toast.error('failed to fetch UPI details')
      toast.error("Please select a file or enter an image URL first!");
      return;
    }

    setLoading(true);
    setSearchPerformed(true);
    const formData = new FormData();
    if (file) {
      formData.append("image", file);
    }

    try {
      let response;
      if (file) {
        response = await fetch(`${base_url}/user/image/search`, {
          method: "POST",
          headers: {
            "x-auth-token": sessionStorage.getItem("token"),
          },
          body: formData,
        });
      }

      if (response.ok) {
        const data = await response.json();
        setImageSearchData(data?.data?.data?.data || []);
        toast.success("Successfully fetched Results")
      } else {
        toast.error("Error fetching Results!");
        console.error("Upload failed:", response.statusText);
      }
    } catch (error) {
      toast.error("Error fetching Results!");
      console.error("Error during upload:", error);
    } finally {
      setLoading(false);
    }
  };

  const resetImage = () => {
    setImagePreviewUrl("");
    setImageInput(false);
    setImageSearchData([]);
    setFile(null);
    setSearchPerformed(false);
  };

  useEffect(() => {
    console.log("File uploaded successfully result:", imageSearchData);
  }, [imageSearchData]);

  return (
    <>
      <ToastContainer />
      {loading && (
        <div
          className="loading-backdrop"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}>
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <span className="text-white">Loading...</span>
        </div>
      )}

      <div className="res3 row p-2 justify-content-center text-white ">
        <div className="mb-0 mx-auto ">
          <div className="mx-auto col-xl-6 mb-3">
            <div
              className="col-lg-8 col-md-12 col-sm-12 mx-auto col-xl-8"
              style={{
                border: "1px solid white",
                borderRadius: "20px",
                backgroundColor: "#0F1535",
              }}>
              <div className="search-header text-white text-center py-2 mt-3">
                <div className="input-group mb-3 align-items-center d-flex flex-column">
                  <div className="search-header text-white text-center py-2 mt-1 mb-3 mx-auto fw-bold">
                    <h2 class="fw-bold " style={{ color: "" }}>
                      Image Search
                    </h2>
                  </div>
                  {(imagePreviewUrl && (
                    <img
                      src={imagePreviewUrl}
                      alt="Preview"
                      style={{
                        maxWidth: "80%",
                        marginTop: "20px",
                        borderRadius: "10px",
                      }}
                    />
                  ))}

                  {!imageInput && (
                    <>
                      <input
                        type="file"
                        ref={fileInputRef}
                        placeholder="Upload Image"
                        onChange={handleFileChange}
                      />
                      <div
                        className="d-flex justify-content-center align-items-center py-auto"
                        onClick={handleCustomFileInputClick}
                        style={{
                          border: "2px dashed white",
                          width: "200px",
                          height: "200px",
                          borderRadius: "10px",
                        }}>
                        <i
                          style={{
                            border: "2px solid white",
                            borderRadius: "100px",
                          }}
                          className="p-4 fa-solid fa-file-export fs-1"></i>
                      </div>
                    </>
                  )}
                  <button
                    onClick={uploadFile}
                    className="btn btn-primary mx-auto  "
                    style={{
                      marginTop: "35px",
                      marginBottom: "20px",
                      maxWidth: "200px",
                      borderRadius: "15px",
                      fontSize: "13px",
                    }}>
                    Search
                  </button>
                  {imageInput && (
                    <button
                      onClick={resetImage}
                      className="btn btn-primary mx-auto"
                      style={{
                        maxWidth: "200px",
                        borderRadius: "15px",
                        fontSize: "13px",
                      }}>
                      Reset
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            {searchPerformed &&
              !loading &&
              (imageSearchData.length > 0
                ? imageSearchData.map((item, index) => (
                  <div class=" col-lg-5 mx-auto"                  >
                    <ImageSearchResult data={item} key={index} />
                  </div>
                ))
                : searchPerformed &&
                !loading && (
                  <div
                    className="text-center text-danger mt-3 mx-auto"
                    style={{ fontSize: "35px", fontWeight: "bold" }}>
                    <p>No results have been found.</p>
                  </div>
                ))}

          </div>
        </div>
      </div>
    </>
  );
};

export default ImageSearch;
